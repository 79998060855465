import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
    CellProps,
    Column,
    useFlexLayout,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable
} from 'react-table';
import {
    Button,
    Grid,
    IconButton,
    Link,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiNumeric, mdiCellphone, mdiCellphoneSound, mdiAlarm } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchShipments } from '../../redux/shipments';
import { ApiStatus, SortDirection } from '../../helpers/enums';
import { formatCityRegionPostalCountry } from '../../helpers/addressUtils';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { formattedPhoneNumber } from '../../helpers/phoneUtils';
import { ShipmentListData } from '../../interfaces/services/shipment';
import ListPagination from '../paginations/listPagination';
import AssignTrackingDialog from '../dialogs/assignTrackingDialog';
import AddReferenceNumbersDialog from '../dialogs/addReferenceNumbersDialog';
import EditTimesDialog from '../dialogs/editTimesDialog';

const classesPrefix = 'shipmentListTable';

const classes = {
    tableContainer: `${classesPrefix}-tableContainer`,
    actionIconButton: `${classesPrefix}-actionIconButton`,
    tableRow: `${classesPrefix}-tableRow`,
    tableHead: `${classesPrefix}-tableHead`,
    tableHeader: `${classesPrefix}-tableHeader`,
    resizer: `${classesPrefix}-resizer`,
    tableCell: `${classesPrefix}-tableCell`,
    clickableCell: `${classesPrefix}-clickableCell`
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => {
    return {
        [`&.${classes.tableContainer}`]: {
            maxHeight: 'calc(100vh - 156px)',
            marginTop: '8px',
            border: `1px solid ${theme.palette.divider}`
        },
        [`& .${classes.actionIconButton}`]: {
            padding: 0,
            margin: '0 4px'
        },
        [`& .${classes.tableRow}`]: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover
            }
        },
        [`& .${classes.tableHead}`]: {
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.background.default,
            zIndex: 2
        },
        [`& .${classes.tableHeader}`]: {
            height: '100%'
        },
        [`& .${classes.resizer}`]: {
            display: 'inline-block',
            width: '10px',
            height: '50%',
            position: 'absolute',
            right: '0',
            top: '0',
            zIndex: '1',
            transform: 'translateY(50%)',
            borderRight: `1px solid ${theme.palette.divider}`
        },
        [`& .${classes.tableCell}`]: {
            fontSize: '13px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        [`& .${classes.clickableCell}`]: {
            padding: 0,
            minWidth: '8px',
            textTransform: 'none',
            fontSize: '13px',
            fontWeight: 600,
            '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.tableContainer}`]: {
                maxHeight: 'calc(100vh - 202px)'
            }
        }
    };
});

const ShipmentListTable = (): JSX.Element => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { freightHaulerConfigurationId } = useParams<{ freightHaulerConfigurationId: string; }>();

    const [isReferenceDialogOpen, setIsReferenceDialogOpen] = useState(false);
    const [isEditTimesDialogOpen, setIsEditTimesDialogOpen] = useState<{ isOpen: boolean; stopSequence: number; }>({ isOpen: false, stopSequence: 0 });
    const [isTrackingDialogOpen, setIsTrackingDialogOpen] = useState(false);
    const [activeRow, setActiveRow] = useState<ShipmentListData | null>(null);

    const shipperListStatus = useTypedSelector((state) => { return state.shipperListData.shipperListStatus; });
    const selectedShipper = useTypedSelector((state) => { return state.shipperListData.selectedShipper; });

    const shipmentKeyStatus = useTypedSelector((state) => { return state.shipmentListData.shipmentKeyStatus; });
    const shipmentListStatus = useTypedSelector((state) => { return state.shipmentListData.shipmentListStatus; });
    const shipmentList = useTypedSelector((state) => { return state.shipmentListData.shipmentList; });
    const totalRecords = useTypedSelector((state) => { return state.shipmentListData.totalRecords; });
    const skip = useTypedSelector((state) => { return state.shipmentListData.skip; });
    const take = useTypedSelector((state) => { return state.shipmentListData.take; });
    const searchTerm = useTypedSelector((state) => { return state.shipmentListData.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.shipmentListData.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.shipmentListData.sortDirection; });
    const filters = useTypedSelector((state) => { return state.shipmentListData.filters; });

    const isFetchingShipmentList = shipmentListStatus === ApiStatus.Idle || shipmentListStatus === ApiStatus.Loading
        || shipmentKeyStatus === ApiStatus.Idle || shipmentKeyStatus === ApiStatus.Loading
        || shipperListStatus === ApiStatus.Idle || shipperListStatus === ApiStatus.Loading;

    const handleMobileTrackingDialogOpen = (shipment: ShipmentListData): void => {
        setActiveRow(shipment);
        setIsTrackingDialogOpen(true);
    };

    const handleReferenceDialogOpen = (shipment: ShipmentListData): void => {
        setActiveRow(shipment);
        setIsReferenceDialogOpen(true);
    };

    const handleEditTimesDialogOpen = (shipment: ShipmentListData, isOnDestinationStop: boolean): void => {
        setActiveRow(shipment);
        const stopSequence = isOnDestinationStop ? shipment.numberOfStops - 1 : 0;
        setIsEditTimesDialogOpen({ isOpen: true, stopSequence });
    };

    const columns = useMemo((): Column<ShipmentListData>[] => {
        const renderActions = (rowInfo: React.PropsWithChildren<CellProps<ShipmentListData, string>>): JSX.Element => {
            return (
                <Fragment>
                    <Tooltip title='Add Reference Numbers'>
                        <IconButton
                            className={classes.actionIconButton}
                            onClick={(): void => {
                                handleReferenceDialogOpen(rowInfo.row.original);
                            }}
                        >
                            <Icon path={mdiNumeric} size={1} color={theme.palette.text.primary} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Edit Times'>
                        <IconButton
                            className={classes.actionIconButton}
                            onClick={(): void => {
                                handleEditTimesDialogOpen(rowInfo.row.original, false);
                            }}
                        >
                            <Icon path={mdiAlarm} size={1} color={theme.palette.text.primary} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Assign Tracking'>
                        <IconButton
                            className={classes.actionIconButton}
                            onClick={(): void => {
                                handleMobileTrackingDialogOpen(rowInfo.row.original);
                            }}
                        >
                            <Icon path={mdiCellphone} size={1} color={theme.palette.text.primary} />
                        </IconButton>
                    </Tooltip>
                </Fragment>
            );
        };

        return [
            {
                id: 'actions',
                Header: 'Actions',
                disableResizing: true,
                width: 128,
                Cell: renderActions
            },
            {
                Header: 'Shipper Ref #',
                accessor: 'freightProviderReferenceNumber',
                Cell: (props): JSX.Element => {
                    return (
                        <Link component={RouterLink} to={`/FullDetailsPage/${freightHaulerConfigurationId}/${props.row.original.shipmentUniqueName}`}>
                            {props.value}
                        </Link>
                    );
                }
            },
            {
                Header: 'Status',
                accessor: 'shipmentStatus',
                width: 120,
                Cell: (props): JSX.Element => {
                    return (
                        <Fragment>
                            {addSpaceBeforeUppercaseCharacter(props.value)}
                        </Fragment>
                    );
                }
            },
            {
                Header: 'Origin',
                accessor: 'originCity',
                width: 120,
                Cell: (props): JSX.Element => {
                    const { originCity, originState } = props.row.original;
                    return (
                        <Fragment>
                            {formatCityRegionPostalCountry({ city: originCity, region: originState })}
                        </Fragment>
                    );
                }
            },
            {
                Header: 'Origin Appointment Start Date',
                id: 'originAppointmentStartDateTime',
                accessor: 'originAppointmentStartDateTime',
                Cell: (props): JSX.Element => {
                    return (
                        <Button
                            className={classes.clickableCell}
                            onClick={(): void => {
                                handleEditTimesDialogOpen(props.row.original, false);
                            }}
                        >
                            {zonedDateTimeToDisplay(props.value) || '--'}
                        </Button>
                    );
                }
            },
            {
                Header: 'Origin Appointment End Date',
                id: 'originAppointmentEndDateTime',
                accessor: 'originAppointmentEndDateTime',
                Cell: (props): JSX.Element => {
                    return (
                        <Button
                            className={classes.clickableCell}
                            onClick={(): void => {
                                handleEditTimesDialogOpen(props.row.original, false);
                            }}
                        >
                            {zonedDateTimeToDisplay(props.value) || '--'}
                        </Button>
                    );
                }
            },
            {
                Header: 'Destination',
                accessor: 'destinationCity',
                width: 120,
                Cell: (props): JSX.Element => {
                    const { destinationCity, destinationState } = props.row.original;
                    return (
                        <Fragment>
                            {formatCityRegionPostalCountry({ city: destinationCity, region: destinationState })}
                        </Fragment>
                    );
                }
            },
            {
                Header: 'Destination Appointment Start Date',
                id: 'destinationAppointmentStartDateTime',
                accessor: 'destinationAppointmentStartDateTime',
                Cell: (props): JSX.Element => {
                    return (
                        <Button
                            className={classes.clickableCell}
                            onClick={(): void => {
                                handleEditTimesDialogOpen(props.row.original, true);
                            }}
                        >
                            {zonedDateTimeToDisplay(props.value) || '--'}
                        </Button>
                    );
                }
            },
            {
                Header: 'Destination Appointment End Date',
                id: 'destinationAppointmentEndDateTime',
                accessor: 'destinationAppointmentEndDateTime',
                Cell: (props): JSX.Element => {
                    return (
                        <Button
                            className={classes.clickableCell}
                            onClick={(): void => {
                                handleEditTimesDialogOpen(props.row.original, true);
                            }}
                        >
                            {zonedDateTimeToDisplay(props.value) || '--'}
                        </Button>
                    );
                }
            },
            {
                Header: 'Mobile Tracking',
                accessor: 'mobileTrackingPhoneNumber',
                Cell: (props): JSX.Element => {
                    return (
                        <span className='phoneNumberSpan clickableCell'>
                            <span className='phoneNumberIconSpan'>
                                {props.row.original.mobileTrackingStatus.toLowerCase() === 'mobile' &&
                                    <Tooltip title='Tracking Accepted' placement='bottom'>
                                        <Icon path={mdiCellphoneSound} size={1} />
                                    </Tooltip>
                                }
                            </span>
                            <Button
                                className={classes.clickableCell}
                                onClick={(): void => {
                                    handleMobileTrackingDialogOpen(props.row.original);
                                }}
                            >
                                {formattedPhoneNumber(props.value)}
                            </Button>
                        </span>
                    );
                }
            },
            {
                Header: 'Tractor #',
                accessor: 'tractorReferenceNumber',
                Cell: (props): JSX.Element => {
                    return (
                        <Button
                            className={classes.clickableCell}
                            onClick={(): void => {
                                handleReferenceDialogOpen(props.row.original);
                            }}
                        >
                            {props.value || '--'}
                        </Button>
                    );
                }
            },
            {
                Header: 'Trailer #',
                accessor: 'trailerReferenceNumber',
                Cell: (props): JSX.Element => {
                    return (
                        <Button
                            className={classes.clickableCell}
                            onClick={(): void => {
                                handleReferenceDialogOpen(props.row.original);
                            }}
                        >
                            {props.value || '--'}
                        </Button>
                    );
                }
            },
            {
                Header: 'Total Stops',
                accessor: 'numberOfStops',
                width: 80
            },
            {
                Header: 'Carrier Name',
                accessor: 'carrierName'
            }
        ];
    }, [theme.palette.text.primary, freightHaulerConfigurationId]);

    const defaultColumn = React.useMemo(() => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: {
            sortBy
        }
    } = useTable<ShipmentListData>(
        {
            columns,
            data: shipmentList,
            manualPagination: true, // Enables pagination functionality, but does not automatically perform row pagination.
            autoResetPage: false, // When true, the pageIndex state will automatically reset if manualPagination is false and the data changes.
            manualSortBy: true, // Enables sorting detection functionality, but does not automatically perform row sorting.
            autoResetSortBy: false, // When true, the sortBy state will automatically reset if the data changes.
            disableMultiSort: true, // Disables multi-sorting for the entire table.
            disableSortRemove: true, // If true, the un-sorted state will not be available to columns once they have been sorted.
            defaultColumn,
            initialState: {
                pageSize: take, // Determines the amount of rows on any given page.
                sortBy: [{ id: sortColumn, desc: sortDirection === SortDirection.Descending }] // An array of sorting objects.
            }
        },
        useFlexLayout, // A plugin hook that adds support for headers and cells to be rendered as inline-block divs (or other non-table elements) with width being used as the flex-basis and flex-grow.
        useResizeColumns, // Hook that adds support for resizing headers and cells when using non-table elements for layout
        useSortBy, // The hook that implements row sorting
        usePagination // The hook that implements row pagination.
    );

    useEffect((): void => {
        if (shipperListStatus !== ApiStatus.Idle && shipperListStatus !== ApiStatus.Loading && selectedShipper) {
            const newSortColumn = sortBy[0].id as keyof ShipmentListData;
            const newSortDirection = sortBy[0].desc ? SortDirection.Descending : SortDirection.Ascending;
            dispatch(fetchShipments({
                filters,
                sortColumn: newSortColumn,
                sortDirection: newSortDirection
            }));
        }
    }, [dispatch, shipperListStatus, selectedShipper, filters, sortBy]);

    const renderTableBody = (): JSX.Element | JSX.Element[] => {
        if (isFetchingShipmentList) {
            return Array.from(new Array(3)).map((item, index): JSX.Element => {
                return (
                    <Fragment key={index}>
                        {
                            headerGroups.map((headerGroup) => {
                                return (
                                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column) => {
                                                return (
                                                    <TableCell {...column.getHeaderProps()}><Skeleton variant='text' width={column.width} /></TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                );
                            })
                        }
                    </Fragment>
                );
            });
        }

        if (shipmentList.length === 0) {
            return (
                <TableRow>
                    <TableCell align='center' colSpan={14} data-qa='shipmentList-noResults'>No Shipments Found</TableCell>
                </TableRow>
            );
        }

        return rows.map((row): JSX.Element => {
            // Prepare the row for display
            prepareRow(row);

            return (
                <TableRow
                    {...row.getRowProps()}
                    classes={{ root: classes.tableRow }}
                    data-qa={row.original.freightProviderReferenceNumber}
                >
                    {
                        row.cells.map((cell) => {
                            return (
                                <TableCell
                                    {...cell.getCellProps()}
                                    className={classes.tableCell}
                                    data-qa={cell.column.id}
                                >
                                    {cell.render('Cell')}
                                </TableCell>
                            );
                        })
                    }
                </TableRow>
            );
        });
    };

    return (
        <Fragment>
            <StyledTableContainer className={classes.tableContainer}>
                <Table {...getTableProps()} size='small' aria-label='Shipment List' data-qa='shipmentList-table'>
                    <TableHead className={classes.tableHead}>
                        {
                            headerGroups.map((headerGroup) => {
                                return (
                                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column) => {
                                                return (
                                                    <TableCell {...column.getHeaderProps()}>
                                                        <Grid
                                                            {...column.canSort && column.getSortByToggleProps()}
                                                            className={classes.tableHeader}
                                                            container
                                                            alignItems='center'
                                                            spacing={1}
                                                        >
                                                            <Grid item xs={10}>
                                                                {column.render('Header')}
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                {
                                                                    column.canSort &&
                                                                    <TableSortLabel
                                                                        active={column.isSorted}
                                                                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                                    />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            column.canResize &&
                                                            <div
                                                                {...column.getResizerProps()}
                                                                className={classes.resizer}
                                                            />
                                                        }
                                                    </TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                );
                            })
                        }
                    </TableHead>
                    <TableBody {...getTableBodyProps()} data-qa='shipmentList-content'>
                        {renderTableBody()}
                    </TableBody>
                </Table>
            </StyledTableContainer>

            <ListPagination
                isFetchingData={isFetchingShipmentList}
                skip={skip}
                take={take}
                loadedRecordsCount={shipmentList.length}
                totalRecordsCount={totalRecords}
                showPaginationTotals={!isMobile}
                showFirstButton={!isMobile}
                showLastButton={!isMobile}
                handlePaginationChange={(newSkip: number): void => {
                    dispatch(fetchShipments({
                        skip: newSkip,
                        take,
                        searchTerm,
                        sortColumn,
                        sortDirection,
                        filters
                    }));
                }}
            />
            {
                isReferenceDialogOpen && activeRow &&
                <AddReferenceNumbersDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                    carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                    initialTractorId={activeRow.tractorReferenceNumber}
                    initialTrailerId={activeRow.trailerReferenceNumber}
                    isOpen={isReferenceDialogOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setIsReferenceDialogOpen(false);
                    }}
                />
            }
            {
                isEditTimesDialogOpen.isOpen && activeRow &&
                <EditTimesDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    initialStopSequence={isEditTimesDialogOpen.stopSequence}
                    carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                    carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                    isOpen={isEditTimesDialogOpen.isOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setIsEditTimesDialogOpen({ isOpen: false, stopSequence: 0 });
                    }}
                />
            }
            {
                isTrackingDialogOpen && activeRow &&
                <AssignTrackingDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    initialPhoneNumber={activeRow.mobileTrackingPhoneNumber}
                    carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                    carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                    isOpen={isTrackingDialogOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setIsTrackingDialogOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentListTable;
