import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { Shipper } from '../../interfaces/services/shipper';
import { updateSelectedShipper } from '../../redux/shipper';

const classesPrefix = 'appHeader';

const classes = {
    selectWrapper: `${classesPrefix}-selectWrapper`
};

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => {
    return {
        [`&.${classes.selectWrapper}`]: {
            maxWidth: '400px'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.selectWrapper}`]: {
                maxWidth: '100%'
            }
        }
    };
}) as typeof Autocomplete;

const ShipperSelect = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { freightHaulerConfigurationId } = useParams<{ freightHaulerConfigurationId: string; }>();

    const [selectedShipper, setSelectedShipper] = useState<Shipper | null>(null);

    const shipperListStatus = useTypedSelector((state) => { return state.shipperListData.shipperListStatus; });
    const shipperList = useTypedSelector((state) => { return state.shipperListData.shipperList; });

    // update Selected shipper anytime the freightHaulerConfigurationId changes
    useEffect((): void => {
        if (freightHaulerConfigurationId) {
            const selectedShipper = shipperList.find((shipper) => {
                return shipper.freightHaulerConfigurationID === freightHaulerConfigurationId;
            });

            if (selectedShipper) {
                setSelectedShipper(selectedShipper);
                dispatch(updateSelectedShipper(selectedShipper));
            } else {
                toast.error('Unable to identify selected shipper.');
            }
        }
    }, [dispatch, freightHaulerConfigurationId, shipperList]);

    const sortedSelectedOpts = shipperList.sort((a, b): number => {
        const nameA = a.shipperName.toUpperCase();
        const nameB = b.shipperName.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    });

    return (
        <StyledAutocomplete
            className={classes.selectWrapper}
            size='small'
            disabled={shipperListStatus === ApiStatus.Idle || shipperListStatus === ApiStatus.Loading}
            fullWidth
            disableClearable={shipperList.length > 0}
            options={sortedSelectedOpts}
            getOptionLabel={(option): string => { return option.shipperName; }}
            isOptionEqualToValue={(option): boolean => {
                return option.freightHaulerConfigurationID === selectedShipper?.freightHaulerConfigurationID;
            }}
            onChange={(event, newShipper): void => {
                if (newShipper) {
                    //update the url
                    history.push(`/ShipmentList/${newShipper.freightHaulerConfigurationID}`);
                }
            }}
            value={selectedShipper}
            renderInput={(params): JSX.Element => {
                return (
                    <TextField
                        {...params}
                        placeholder='Shipper'
                        fullWidth
                        margin='none'
                        variant='outlined'
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            'data-qa': 'shipper-input'
                        }}
                    />
                );
            }}
            data-qa='shipper-select'
        />
    );
};

export default ShipperSelect;
