import React, { Fragment } from 'react';
import { Drawer, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const classesPrefix = 'sideSheet';

const classes = {
    drawer: `${classesPrefix}-drawer`,
    drawerPaper: `${classesPrefix}-drawerPaper`,
    sideSheetContainer: `${classesPrefix}-sideSheetContainer`
};

const StyledDrawer = styled(Drawer)(({ theme }) => {
    return {
        [`&.${classes.drawer}`]: {
            width: '320px',
            position: 'absolute',
            bottom: 0,
            right: 0,
            height: 'calc(100vh - 48px)',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            overflowY: 'auto',
            transition: 'width 400ms ease-in-out, opacity 1.5s ease-in-out'
        },
        [`& .${classes.drawerPaper}`]: {
            width: '320px',
            position: 'absolute'
        },
        [`& .${classes.sideSheetContainer}`]: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto'
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.drawerPaper}`]: {
                width: '250px'
            }
        }
    };
});

const SideSheet = ({
    open,
    closeSideSheet,
    children
}: {
    /** Indicator for if the side sheet is open. */
    open: boolean;
    /** Function to close the side sheet. */
    closeSideSheet: () => void;
    /** Children to render in the side sheet. */
    children?: React.ReactNode;
}): JSX.Element => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const renderSideSheetBody = (): JSX.Element => {
        return (
            <section className={classes.sideSheetContainer} data-qa='sidesheet-content'>
                {children}
            </section>
        );
    };

    if (open === false) {
        return <Fragment />;
    }

    if (isMobile) {
        return (
            <StyledDrawer
                classes={{ paper: classes.drawerPaper }}
                anchor='right'
                variant='temporary'
                open={open}
                onClose={(): void => { closeSideSheet(); }}
                data-qa='sidesheet-container-mobile'
            >
                {renderSideSheetBody()}
            </StyledDrawer>
        );
    }

    return (
        <StyledDrawer
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor='right'
            variant='persistent'
            open={open}
            onClose={(): void => { closeSideSheet(); }}
            data-qa='sidesheet-container'
        >
            {renderSideSheetBody()}
        </StyledDrawer>
    );
};

export default SideSheet;
