import { toast } from 'react-toastify';

import { AppThunk } from '..';
import {
    AllowedFilterPropertyName,
    ApiStatus,
    SortDirection
} from '../../helpers/enums';
import ApiService from '../../services/apiService';
import { GenericApiResponse } from '../../interfaces/services';
import { Filter, ShipmentListData } from '../../interfaces/services/shipment';
import {
    SHIPMENTS_API_KEY_REQUEST,
    SHIPMENTS_API_KEY_SUCCESS,
    SHIPMENTS_API_KEY_FAILURE,
    RequestShipmentsApiKeyAction,
    ReceiveShipmentsApiKeyAction,
    RequestShipmentsApiKeyFailureAction
} from '../shipper';
import {
    UPDATE_TRACTOR_TRAILER_SUCCESS,
    UPDATE_MOBILE_TRACKING_SUCCESS,
    UPLOAD_DOCUMENT_SUCCESS,
    UPDATE_STOP_TIMES_SUCCESS,
    ReceiveTractorTrailerUpdateAction,
    ReceiveMobileTrackingUpdateAction,
    ReceiveUploadDocumentAction,
    ReceiveStopTimesUpdateAction
} from '../dialogUpdates';

const SHIPMENT_LIST_REQUEST = 'SHIPMENT_LIST_REQUEST';
const SHIPMENT_LIST_SUCCESS = 'SHIPMENT_LIST_SUCCESS';
const SHIPMENT_LIST_FAILURE = 'SHIPMENT_LIST_FAILURE';
const SHIPMENT_LIST_UPDATE_FILTERS = 'SHIPMENT_LIST_UPDATE_FILTERS';

interface ShipmentRequestPayload {
    activeShipments: boolean;
    skip: number;
    take: number;
    searchTerm: string;
    sortColumn: keyof ShipmentListData;
    sortDirection: SortDirection;
    filters: Filter[];
}

interface ShipmentPayload extends GenericApiResponse<ShipmentListData> {
    additionalRecords: boolean;
    totalPossibleRecords: number;
    skip: number;
    take: number;
    sortColumn: keyof ShipmentListData;
    sortDirection: SortDirection;
    searchTerm: string;
}

interface Shipments {
    apiKey: string;
    shipmentListStatus: ApiStatus;
    shipmentList: ShipmentListData[];
    totalRecords: number;
    additionalRecords: boolean;
    skip: number;
    take: number;
    sortDirection: SortDirection;
    sortColumn: keyof ShipmentListData;
    searchTerm: string;
    shipmentKeyStatus: ApiStatus;
    filters: Filter[];
}

interface RequestShipmentsAction {
    type: typeof SHIPMENT_LIST_REQUEST;
}

interface ReceiveShipmentsAction {
    type: typeof SHIPMENT_LIST_SUCCESS;
    payload: ShipmentPayload;
}

interface RequestShipmentsFailedAction {
    type: typeof SHIPMENT_LIST_FAILURE;
}

interface UpdateFiltersAction {
    type: typeof SHIPMENT_LIST_UPDATE_FILTERS;
    payload: {
        filters: Filter[];
    };
}

type ShipmentsActionTypes =
    RequestShipmentsAction | ReceiveShipmentsAction | RequestShipmentsFailedAction |
    ReceiveTractorTrailerUpdateAction | ReceiveMobileTrackingUpdateAction | ReceiveUploadDocumentAction | ReceiveStopTimesUpdateAction |
    RequestShipmentsApiKeyAction | ReceiveShipmentsApiKeyAction | RequestShipmentsApiKeyFailureAction | UpdateFiltersAction;

const requestShipments = (): RequestShipmentsAction => {
    return {
        type: SHIPMENT_LIST_REQUEST
    };
};

const receiveShipments = (json: ShipmentPayload): ReceiveShipmentsAction => {
    return {
        type: SHIPMENT_LIST_SUCCESS,
        payload: json
    };
};

const requestShipmentsFailed = (): RequestShipmentsFailedAction => {
    return {
        type: SHIPMENT_LIST_FAILURE
    };
};

export const updateFilters = (filters: Filter[]): UpdateFiltersAction => {
    return {
        type: SHIPMENT_LIST_UPDATE_FILTERS,
        payload: {
            filters
        }
    };
};

export const fetchShipments = ({
    skip = 0,
    take = 50,
    searchTerm = '',
    sortColumn = AllowedFilterPropertyName.OriginAppointmentStartDateTime,
    sortDirection = SortDirection.Ascending,
    filters = []
}: {
    skip?: number;
    take?: number;
    searchTerm?: string;
    sortColumn?: keyof ShipmentListData;
    sortDirection?: SortDirection;
    filters?: Filter[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const { appConfig, shipmentListData: { apiKey } } = getState();

        if (apiKey) {
            const requestBody: ShipmentRequestPayload = {
                activeShipments: true,
                skip,
                take,
                searchTerm,
                sortColumn,
                sortDirection,
                filters
            };

            try {
                dispatch(requestShipments());
                const json = await ApiService.post(`${appConfig.shipmentApi}/v2/shipments/headers`, requestBody, apiKey) as ShipmentPayload;
                dispatch(receiveShipments({
                    ...json,
                    searchTerm,
                    sortColumn,
                    sortDirection
                }));
            } catch (error) {
                dispatch(requestShipmentsFailed());
                if (error?.messages?.length > 0) {
                    error.messages.forEach((message: string): void => {
                        toast.error(message);
                    });
                } else if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                    error.response.data.messages.forEach((message: string): void => {
                        toast.error(message);
                    });
                } else {
                    toast.error('Error occurred while fetching shipment list.');
                }
            }
        } else {
            dispatch(requestShipmentsFailed());
            toast.error('A shipper must be selected.');
        }
    };
};

export const shipmentListDataReducer = (shipmentListData: Shipments = {
    apiKey: '',
    shipmentKeyStatus: ApiStatus.Idle,
    shipmentListStatus: ApiStatus.Idle,
    shipmentList: [],
    additionalRecords: false,
    totalRecords: 0,
    skip: 0,
    take: 50,
    sortDirection: SortDirection.Descending,
    sortColumn: 'originAppointmentStartDateTime',
    searchTerm: '',
    filters: []
}, action: ShipmentsActionTypes): Shipments => {
    switch (action.type) {
        case SHIPMENTS_API_KEY_REQUEST: {
            return {
                ...shipmentListData,
                shipmentKeyStatus: ApiStatus.Loading
            };
        }
        case SHIPMENTS_API_KEY_SUCCESS: {
            return {
                ...shipmentListData,
                apiKey: action.payload,
                shipmentKeyStatus: ApiStatus.Success
            };
        }
        case SHIPMENTS_API_KEY_FAILURE: {
            return {
                ...shipmentListData,
                apiKey: '',
                shipmentKeyStatus: ApiStatus.Failure
            };
        }
        case SHIPMENT_LIST_REQUEST: {
            return {
                ...shipmentListData,
                shipmentListStatus: ApiStatus.Loading,
                additionalRecords: false
            };
        }
        case SHIPMENT_LIST_SUCCESS: {
            return {
                ...shipmentListData,
                shipmentListStatus: ApiStatus.Success,
                shipmentList: action.payload.data,
                totalRecords: action.payload.totalPossibleRecords,
                additionalRecords: action.payload.additionalRecords,
                skip: action.payload.skip,
                sortDirection: action.payload.sortDirection,
                sortColumn: action.payload.sortColumn,
                searchTerm: action.payload.searchTerm
            };
        }
        case SHIPMENT_LIST_FAILURE: {
            return {
                ...shipmentListData,
                shipmentListStatus: ApiStatus.Failure,
                shipmentList: [],
                totalRecords: 0,
                additionalRecords: false
            };
        }
        case UPDATE_TRACTOR_TRAILER_SUCCESS: {
            return {
                ...shipmentListData,
                shipmentList: shipmentListData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                        return {
                            ...shipment,
                            ...action.payload.assets?.tractorReferenceNumber !== undefined && {
                                tractorReferenceNumber: action.payload.assets.tractorReferenceNumber
                            },
                            ...action.payload.assets?.trailerReferenceNumber !== undefined && {
                                trailerReferenceNumber: action.payload.assets.trailerReferenceNumber
                            }
                        };
                    }
                    return shipment;
                })
            };
        }
        case UPDATE_MOBILE_TRACKING_SUCCESS: {
            return {
                ...shipmentListData,
                shipmentList: shipmentListData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                        return {
                            ...shipment,
                            mobileTrackingPhoneNumber: action.payload.driverPhoneNumber
                        };
                    }
                    return shipment;
                })
            };
        }
        case UPLOAD_DOCUMENT_SUCCESS: {
            return {
                ...shipmentListData,
                shipmentList: shipmentListData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                        return {
                            ...shipment,
                            hasShipmentDocuments: true
                        };
                    }
                    return shipment;
                })
            };
        }
        case SHIPMENT_LIST_UPDATE_FILTERS: {
            return {
                ...shipmentListData,
                shipmentListStatus: ApiStatus.Idle,
                filters: action.payload.filters
            };
        }
        case UPDATE_STOP_TIMES_SUCCESS: {
            return {
                ...shipmentListData,
                shipmentList: shipmentListData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                        // Update origin dates
                        if (action.payload.stopSequence === 1) {
                            return {
                                ...shipment,
                                ...action.payload.appointmentDate && {
                                    originAppointmentStartDateTime: action.payload.appointmentDate
                                },
                                ...action.payload.appointmentEndDate && {
                                    originAppointmentEndDateTime: action.payload.appointmentEndDate
                                },
                                ...action.payload.estimatedDeliveryDate && {
                                    originStopETA: action.payload.estimatedDeliveryDate
                                },
                                // Update active stop ETA
                                ...action.payload.stopSequence === shipment.activeStopSequence && action.payload.estimatedDeliveryDate && {
                                    activeStopETA: action.payload.estimatedDeliveryDate
                                }
                            };
                        }
                        // Update destination dates
                        if (action.payload.stopSequence === shipment.numberOfStops) {
                            return {
                                ...shipment,
                                ...action.payload.appointmentDate && {
                                    destinationAppointmentStartDateTime: action.payload.appointmentDate
                                },
                                ...action.payload.appointmentEndDate && {
                                    destinationAppointmentEndDateTime: action.payload.appointmentEndDate
                                },
                                ...action.payload.estimatedDeliveryDate && {
                                    destinationStopETA: action.payload.estimatedDeliveryDate
                                },
                                // Update active stop ETA
                                ...action.payload.stopSequence === shipment.activeStopSequence && action.payload.estimatedDeliveryDate && {
                                    activeStopETA: action.payload.estimatedDeliveryDate
                                }
                            };
                        }
                        return shipment;
                    }
                    return shipment;
                })
            };
        }
        default:
            return shipmentListData;
    }
};

