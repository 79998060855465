import React, { useState } from 'react';
import {
    Grid,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import DocumentList from '../lists/documentList';
import AddDocumentDialog from '../dialogs/addDocumentDialog';

const classesPrefix = 'shipmentAttachmentsCard';

const classes = {
    detailsWrapper: `${classesPrefix}-detailsWrapper`
};

const StyledCard = styled(Card)(() => {
    return {
        [`& .${classes.detailsWrapper}`]: {
            paddingTop: '16px'
        }
    };
});

const ShipmentAttachmentsCard = (): JSX.Element => {
    const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState(false);

    const shipment = useTypedSelector((state) => { return state.shipmentDetailsData.shipment; });
    const selectedShipper = useTypedSelector((state) => { return state.shipperListData.selectedShipper; });

    return (
        <StyledCard>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' color='textSecondary'>Attachments</Typography>
                        <Typography variant='h6'>{shipment.documents.length === 1 ? '1 document' : `${shipment.documents.length} documents`}</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.detailsWrapper}>
                    <DocumentList />
                </Grid>
            </CardContent>
            <CardActions>
                <Button variant='outlined' color='primary' onClick={(): void => { setIsDocumentDialogOpen(true); }}>Attach Doc</Button>
                {
                    isDocumentDialogOpen &&
                    <AddDocumentDialog
                        shipmentUniqueName={shipment.shipmentUniqueName}
                        carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                        carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                        isOpen={isDocumentDialogOpen}
                        closeDialog={(): void => { setIsDocumentDialogOpen(false); }}
                    />
                }
            </CardActions>
        </StyledCard>
    );
};

export default ShipmentAttachmentsCard;
