import { toast } from 'react-toastify';

import { AppThunk } from '..';
import { ApiStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import { GenericApiResponse } from '../../interfaces/services';
import { Shipper } from '../../interfaces/services/shipper';
import { setPublicApiUri, setShipmentApiUri, setMasterDataApiUri } from '../appConfig';

const SHIPPER_LIST_REQUEST = 'SHIPPER_LIST_REQUEST';
const SHIPPER_LIST_SUCCESS = 'SHIPPER_LIST_SUCCESS';
const SHIPPER_LIST_FAILURE = 'SHIPPER_LIST_FAILURE';

export const SHIPMENTS_API_KEY_REQUEST = 'SHIPMENTS_API_KEY_REQUEST';
export const SHIPMENTS_API_KEY_SUCCESS = 'SHIPMENTS_API_KEY_SUCCESS';
export const SHIPMENTS_API_KEY_FAILURE = 'SHIPMENTS_API_KEY_FAILURE';

const SET_SELECTED_SHIPPER = 'SET_SELECTED_SHIPPER';

interface ShipperListData {
    shipperListStatus: ApiStatus;
    shipperList: Shipper[];
    selectedShipper: Shipper | null;
}

interface RequestShippersAction {
    type: typeof SHIPPER_LIST_REQUEST;
}

interface ReceiveShippersAction {
    type: typeof SHIPPER_LIST_SUCCESS;
    payload: Shipper[];
}

interface RequestShippersFailedAction {
    type: typeof SHIPPER_LIST_FAILURE;
}

export interface RequestShipmentsApiKeyAction {
    type: typeof SHIPMENTS_API_KEY_REQUEST;
}

export interface ReceiveShipmentsApiKeyAction {
    type: typeof SHIPMENTS_API_KEY_SUCCESS;
    payload: string;
}

export interface RequestShipmentsApiKeyFailureAction {
    type: typeof SHIPMENTS_API_KEY_FAILURE;
}

interface SetSelectedShipperAction {
    type: typeof SET_SELECTED_SHIPPER;
    payload: Shipper | null;
}

type ShipperActionTypes =
    RequestShippersAction | ReceiveShippersAction | RequestShippersFailedAction |
    RequestShipmentsApiKeyAction | ReceiveShipmentsApiKeyAction | RequestShipmentsApiKeyFailureAction |
    SetSelectedShipperAction;

const requestShippers = (): RequestShippersAction => {
    return {
        type: SHIPPER_LIST_REQUEST
    };
};

const receiveShippers = (json: Shipper[]): ReceiveShippersAction => {
    return {
        type: SHIPPER_LIST_SUCCESS,
        payload: json
    };
};

const requestShippersFailed = (): RequestShippersFailedAction => {
    return {
        type: SHIPPER_LIST_FAILURE
    };
};

export const fetchShippers = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShippers());
        const { userDetails, appConfig } = getState();

        const shipperListPromises = userDetails.businessUnit.map(async (businessUnitId: string): Promise<Shipper[]> => {
            try {
                const json = await ApiService.get(`${appConfig.dssUri}/v1/${businessUnitId}/shippers`, userDetails.token) as GenericApiResponse<Shipper[]>;
                if (json.data.length > 0) {
                    return json.data[0];
                }

                throw new Error(`Failed to retrieve shippers for this business unit: ${businessUnitId}.`);
            } catch (err) {
                toast.error(`Failed to retrieve shippers for this business unit: ${businessUnitId}.`);
                return [];
            }
        });

        await Promise.all(shipperListPromises).then((shipperList): void => {
            //merge all the return promises into a new array
            const mergedShipperLists = shipperList.flat();
            if (mergedShipperLists.length === 0) {
                dispatch(requestShippersFailed());
            } else {
                dispatch(receiveShippers(mergedShipperLists));
            }
        });
    };
};

const requestShipmentsKey = () => {
    return {
        type: SHIPMENTS_API_KEY_REQUEST
    };
};

const receiveShipmentsApiKey = (apiKey: string): ReceiveShipmentsApiKeyAction => {
    return {
        type: SHIPMENTS_API_KEY_SUCCESS,
        payload: apiKey
    };
};

const requestShipmentsKeyFailed = (): RequestShipmentsApiKeyFailureAction => {
    return {
        type: SHIPMENTS_API_KEY_FAILURE
    };
};

const setSelectedShipper = (selectedShipper: Shipper | null): SetSelectedShipperAction => {
    return {
        type: SET_SELECTED_SHIPPER,
        payload: selectedShipper
    };
};

export const updateSelectedShipper = (selectedShipper: Shipper | null): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const { userDetails, appConfig } = getState();

        let apiKey = '';
        if (selectedShipper) {
            dispatch(requestShipmentsKey());
            const { carrierPortalConnectionGuid, freightHaulerConfigurationID } = selectedShipper;
            try {
                const keyResponse = await ApiService.get(`${appConfig.dssUri}/v1/${carrierPortalConnectionGuid}/shippers/${freightHaulerConfigurationID}/key`, userDetails.token) as GenericApiResponse<{ apiKey: string; }>;
                const { data: keyData } = keyResponse;
                if (keyData.length > 0 && keyData[0].apiKey) {
                    apiKey = keyData[0].apiKey;
                    dispatch(receiveShipmentsApiKey(apiKey));
                } else {
                    dispatch(requestShipmentsKeyFailed());
                    toast.error('Failed to retrieve a key.');
                }
            } catch (err) {
                // Assume if the user cant get a key they are not athorized
                dispatch(requestShipmentsKeyFailed());
                toast.error('Failed to authorize user while retrieving a key.');
            }
        } else {
            // No shipper is selected, so clear out the api key
            dispatch(receiveShipmentsApiKey(apiKey));
        }


        let publicApiUri = '';
        let shipmentApiUri = '';
        let masterDataApiUri = '';
        if (selectedShipper && selectedShipper.links.length > 0) {
            //find the public api uri from the selected shipper
            const publicApiArray = selectedShipper.links.filter((link): boolean => {
                return link.linkType.toLowerCase() === 'publicapi';
            });
            //if publicApiArray has 1 entry and a value for link, set link as the public api uri
            if (publicApiArray.length === 1 && publicApiArray[0].link) {
                publicApiUri = publicApiArray[0].link;
            }
            //find the shipment api uri from the selected shipper
            const shipmentApiArray = selectedShipper.links.filter((link): boolean => {
                return link.linkType.toLowerCase() === 'shipmentapi';
            });
            //if shipmentApiArray has 1 entry and a value for link, set link value as the shipment api uri
            if (shipmentApiArray.length === 1 && shipmentApiArray[0].link) {
                shipmentApiUri = shipmentApiArray[0].link;
            }
            //find the master data api uri from the selected shipper
            const masterDataApiArray = selectedShipper.links.filter((link): boolean => {
                return link.linkType.toLowerCase() === 'masterdataapi';
            });
            //if masterDataApiArray has 1 entry and a value for link, set link value as the shipment api uri
            if (masterDataApiArray.length === 1 && masterDataApiArray[0].link) {
                masterDataApiUri = masterDataApiArray[0].link;
            }
        }

        // Using the api key and master data url from above, hydrate the filter cache for this shipper/organization
        if (masterDataApiUri && apiKey) {
            ApiService.post(`${masterDataApiUri}/api/v1/filters/CreateCacheEntry`, {}, apiKey);
        }

        dispatch(setPublicApiUri(publicApiUri));
        dispatch(setShipmentApiUri(shipmentApiUri));
        dispatch(setMasterDataApiUri(masterDataApiUri));

        dispatch(setSelectedShipper(selectedShipper));
    };
};

export const fetchShippersReducer = (shipperListData: ShipperListData = {
    shipperListStatus: ApiStatus.Idle,
    shipperList: [],
    selectedShipper: null
}, action: ShipperActionTypes): ShipperListData => {
    switch (action.type) {
        case SHIPPER_LIST_REQUEST: {
            return {
                ...shipperListData,
                shipperListStatus: ApiStatus.Loading
            };
        }
        case SHIPPER_LIST_SUCCESS: {
            return {
                ...shipperListData,
                shipperListStatus: ApiStatus.Success,
                shipperList: action.payload
            };
        }
        case SHIPPER_LIST_FAILURE: {
            return {
                ...shipperListData,
                shipperListStatus: ApiStatus.Failure,
                shipperList: []
            };
        }
        case SET_SELECTED_SHIPPER: {
            return {
                ...shipperListData,
                selectedShipper: action.payload
            };
        }
        default:
            return shipperListData;
    }
};
