import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    IconButton,
    TextField,
    InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiMagnify, mdiClose } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchShipments } from '../../redux/shipments';

const classesPrefix = 'shipmentSearch';

const classes = {
    searchForm: `${classesPrefix}-searchForm`
};

const StyledForm = styled('form')(({ theme }) => {
    return {
        [`&.${classes.searchForm}`]: {
            width: '100%',
            minWidth: '400px'
        },
        [theme.breakpoints.down('lg')]: {
            [`&.${classes.searchForm}`]: {
                minWidth: 'unset'
            }
        }
    };
});

const ShipmentSearch = (): JSX.Element => {
    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState('');

    const take = useTypedSelector((state) => { return state.shipmentListData.take; });
    const filters = useTypedSelector((state) => { return state.shipmentListData.filters; });

    const handleSearchTermSubmit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        dispatch(fetchShipments({
            skip: 0,
            take,
            searchTerm,
            filters
        }));
    };

    return (
        <StyledForm onSubmit={handleSearchTermSubmit} autoComplete='off' className={classes.searchForm}>
            <TextField
                id='shipment-list-search'
                type='text'
                placeholder='Search'
                variant='outlined'
                fullWidth
                size='small'
                value={searchTerm}
                onChange={(event): void => {
                    setSearchTerm(event.currentTarget.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            {
                                searchTerm !== '' &&
                                <IconButton
                                    aria-label='Clear search'
                                    onClick={(event): void => {
                                        event.preventDefault();
                                        setSearchTerm('');
                                        dispatch(fetchShipments({
                                            skip: 0,
                                            take,
                                            searchTerm: '',
                                            filters
                                        }));
                                    }}
                                >
                                    <Icon path={mdiClose} size={1} />
                                </IconButton>
                            }
                            <IconButton
                                aria-label='Submit search'
                                onClick={handleSearchTermSubmit}
                            >
                                <Icon path={mdiMagnify} size={1} />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </StyledForm>
    );

};

export default ShipmentSearch;
