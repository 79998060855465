import React, { useState, useEffect, Fragment } from 'react';
import { Paper, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
    mdiChevronUp,
    mdiChevronDown,
    mdiRadar,
    mdiWeatherLightningRainy,
    mdiRoadVariant,
    mdiTrafficLight,
    mdiDomain,
    mdiMapMarkerDistance,
    mdiMapLegend
} from '@mdi/js';

import MapStyleControlBar from './mapStyleControlBar';
import MapButton from '../buttons/mapButton';

const classesPrefix = 'mapControlBar';

const classes = {
    controls: `${classesPrefix}-controls`,
    controlsRight: `${classesPrefix}-controlsRight`,
    paperWrapper: `${classesPrefix}-paperWrapper`
};

const StyledPaper = styled(Paper)(({ theme }) => {
    return {
        [`&.${classes.controls}`]: {
            position: 'absolute',
            left: '5px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10
        },
        [`&.${classes.controlsRight}`]: {
            position: 'absolute',
            right: '9px',
            top: '150px',
            zIndex: 20
        },
        [`&.${classes.paperWrapper}`]: {
            display: 'inline-grid'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.controls}`]: {
                top: '5px',
                transform: 'none'
            }
        }
    };
});

const MapControlBar = ({
    isWeatherRadarActive,
    handleWeatherRadarClick,
    isWeatherAlertsDisabled,
    isWeatherAlertsActive,
    handleWeatherAlertsClick,
    isWeatherRoadSurfaceActive,
    handleWeatherRoadSurfaceClick,
    isTrafficActive,
    handleTrafficClick,
    is3dBuildingsLayerDisabled,
    is3dBuildingsActive,
    handle3dBuildingClick,
    isRouteLineActive,
    handleRouteLineClick,
    mapTileStyle,
    handleMapStyleMenuItemClick,
    isMapMenuLegendOpen,
    handleMapLegendClick
}: {
    /** Indicator for if the weather radar layer is active. */
    isWeatherRadarActive?: boolean;

    /** Function to handle the click of the weather radar button. */
    handleWeatherRadarClick?: () => void;

    /** Indicator for if the weather alerts button is disabled. */
    isWeatherAlertsDisabled?: boolean;

    /** Indicator for if the weather alerts layer is active. */
    isWeatherAlertsActive?: boolean;

    /** Function to handle the click of the weather alerts button. */
    handleWeatherAlertsClick?: () => void;

    /** Indicator for if the weather road surface layer is active. */
    isWeatherRoadSurfaceActive?: boolean;

    /** Function to handle the click of the weather road surface button. */
    handleWeatherRoadSurfaceClick?: () => void;

    /** Indicator for if the traffic layer is active. */
    isTrafficActive?: boolean;

    /** Function to handle the click of the traffic button. */
    handleTrafficClick?: () => void;

    /** Indicator for if the 3D buildings layer/button is disabled. */
    is3dBuildingsLayerDisabled?: boolean;

    /** Indicator for if the 3D buildings layer is active. */
    is3dBuildingsActive?: boolean;

    /** Function to handle the click of the 3D buildings button. */
    handle3dBuildingClick?: () => void;

    /** Indicator for if the route line layer is active. */
    isRouteLineActive?: boolean;

    /** Function to handle the click of the route line button. */
    handleRouteLineClick?: () => void;

    /** Current selected map tile style. */
    mapTileStyle?: string;

    /** Function to handle the change of the map style. */
    handleMapStyleMenuItemClick?: (mapStyle: string) => void;

    /** Indicator for if the map menu legend is open. */
    isMapMenuLegendOpen?: boolean;

    /** Function to handle the click of the map legend button. */
    handleMapLegendClick?: () => void;
}): JSX.Element => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isControlBarOpen, setIsControlBarOpen] = useState(false);

    useEffect((): void => {
        setIsControlBarOpen(!isMobile);
    }, [isMobile]);

    const toggleMapControlBarOnClick = (): void => {
        setIsControlBarOpen(!isControlBarOpen);
    };

    return (
        <Fragment>
            <StyledPaper elevation={2} className={`${classes.paperWrapper} ${classes.controls}`} data-qa='mapControlBar-container'>
                {
                    isMobile &&
                    <MapButton
                        title={isControlBarOpen ? 'Hide Map Controls' : 'Show Map Controls'}
                        isActive={false}
                        mdiIcon={isControlBarOpen ? mdiChevronUp : mdiChevronDown}
                        handleMapButtonClick={toggleMapControlBarOnClick}
                        data-qa='mapControlBar-action-toggle-mobile'
                    />
                }
                {
                    isControlBarOpen === true &&
                    <Fragment>
                        {
                            handleWeatherRadarClick &&
                            <MapButton
                                title='Weather Radar'
                                isActive={isWeatherRadarActive || false}
                                mdiIcon={mdiRadar}
                                handleMapButtonClick={handleWeatherRadarClick}
                                data-qa='weatherRadar-action-toggle'
                            />
                        }

                        {
                            handleWeatherAlertsClick &&
                            <MapButton
                                title='Weather Alerts'
                                isDisabled={isWeatherAlertsDisabled || false}
                                isActive={isWeatherAlertsActive || false}
                                mdiIcon={mdiWeatherLightningRainy}
                                handleMapButtonClick={handleWeatherAlertsClick}
                                data-qa='weatherAlerts-action-toggle'
                            />
                        }

                        {
                            handleWeatherRoadSurfaceClick &&
                            <MapButton
                                title='Road Conditions'
                                isActive={isWeatherRoadSurfaceActive || false}
                                mdiIcon={mdiRoadVariant}
                                handleMapButtonClick={handleWeatherRoadSurfaceClick}
                                data-qa='roadConditions-action-toggle'
                            />
                        }

                        {
                            handleTrafficClick &&
                            <MapButton
                                title='Traffic'
                                isActive={isTrafficActive || false}
                                mdiIcon={mdiTrafficLight}
                                handleMapButtonClick={handleTrafficClick}
                                data-qa='traffic-action-toggle'
                            />
                        }

                        {
                            handle3dBuildingClick &&
                            <MapButton
                                title='3D Buildings'
                                isDisabled={is3dBuildingsLayerDisabled || false}
                                isActive={is3dBuildingsActive || false}
                                mdiIcon={mdiDomain}
                                handleMapButtonClick={handle3dBuildingClick}
                                data-qa='3dbuildings-action-toggle'
                            />
                        }

                        {
                            handleMapStyleMenuItemClick &&
                            <MapStyleControlBar
                                mapTileStyle={mapTileStyle || ''}
                                handleMapStyleMenuItemClick={handleMapStyleMenuItemClick}
                            />
                        }

                        {
                            handleRouteLineClick &&
                            <MapButton
                                title='Route Line'
                                isActive={isRouteLineActive || false}
                                mdiIcon={mdiMapMarkerDistance}
                                handleMapButtonClick={handleRouteLineClick}
                                data-qa='routeLine-action-toggle'
                            />
                        }
                    </Fragment>
                }
            </StyledPaper>

            {
                handleMapLegendClick &&
                <StyledPaper elevation={2} className={`${classes.paperWrapper} ${classes.controlsRight}`}>
                    <MapButton
                        title='Map Legend'
                        isActive={isMapMenuLegendOpen || false}
                        mdiIcon={mdiMapLegend}
                        handleMapButtonClick={handleMapLegendClick}
                        data-qa='mapLegend-action-toggle'
                    />
                </StyledPaper>
            }
        </Fragment>
    );
};

export default MapControlBar;
