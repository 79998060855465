import React, { Fragment } from 'react';
import {
    Grid,
    Typography,
    IconButton,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import { toast } from 'react-toastify';

import { useTypedSelector } from '../../redux';
import { GenericApiResponse } from '../../interfaces/services';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import ApiService from '../../services/apiService';

const classesPrefix = 'documentList';

const classes = {
    documentGrid: `${classesPrefix}-documentGrid`,
    documentCaption: `${classesPrefix}-documentCaption`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.documentGrid}`]: {
            paddingLeft: '5px',
            marginBottom: '15px'
        },
        [`& .${classes.documentCaption}`]: {
            display: 'inline-flex',
            paddingRight: '15px'
        }
    };
});

const DocumentList = (): JSX.Element => {
    const shipment = useTypedSelector((state) => { return state.shipmentDetailsData.shipment; });
    const shipmentApi = useTypedSelector((state) => { return state.appConfig.shipmentApi; });
    const bearerToken = useTypedSelector((state) => { return state.shipmentListData.apiKey; });

    const handleDocumentDownload = async (documentID: number, documentTypeName: string): Promise<void> => {
        try {
            const json = await ApiService.get(`${shipmentApi}/v1/shipments/${shipment.shipmentUniqueName}/documents/${documentID}`, bearerToken) as GenericApiResponse<string>;
            const link = document.createElement('a');
            link.href = json.data[0];
            link.download = documentTypeName;
            // some browsers needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
        } catch (error) {
            toast.error('Document failed to download.');
        }
    };

    return (
        <StyledGrid container>
            {
                shipment.documents.map((document): React.ReactNode => {
                    return (
                        <Fragment key={document.documentLink}>
                            <Grid item xs={11} className={classes.documentGrid}>
                                <Typography variant='body1'>{document.documentTypeName}</Typography>
                                <Typography
                                    className={classes.documentCaption}
                                    noWrap
                                    variant='caption'
                                    color='textSecondary'
                                >
                                    {zonedDateTimeToDisplay(document.uploadDate)}
                                </Typography>
                                <Typography
                                    className={classes.documentCaption}
                                    noWrap
                                    variant='caption'
                                    color='textSecondary'
                                >
                                    {document.userName || 'Unknown User'}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {
                                    document.documentID !== null && shipmentApi &&
                                    <Tooltip title='Download Document' aria-label='Download document'>
                                        <IconButton color='primary' onClick={(): void => { handleDocumentDownload(document.documentID, document.documentTypeName); }}>
                                            <Icon path={mdiDownload} size={1} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                        </Fragment>
                    );
                })
            }
        </StyledGrid>
    );
};

export default DocumentList;
