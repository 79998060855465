import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Grid, Paper } from '@mui/material';

import { useTypedSelector } from '../../redux';
import { fetchCarriers } from '../../redux/carrier';
import { ApiStatus } from '../../helpers/enums';
import CarrierList from '../lists/carrierList';
import ProgressLoader from '../loaders/progressLoader';
import CarrierBusinessUnitList from '../lists/carrierBusinessUnitList';

const classesPrefix = 'userManagerPage';

const classes = {
    wrapper: `${classesPrefix}-wrapper`,
    leftPanel: `${classesPrefix}-leftPanel`,
    rightPanel: `${classesPrefix}-rightPanel`
};

const StyledPaper = styled(Paper)(() => {
    return {
        [`&.${classes.wrapper}`]: {
            margin: '24px'
        },
        [`& .${classes.leftPanel}`]: {
            height: 'calc(100vh - 100px)',
            overflow: 'auto'
        },
        [`& .${classes.rightPanel}`]: {
            height: 'calc(100vh - 100px)',
            overflow: 'auto'
        }
    };
});

const UserManagerPage = (): JSX.Element => {
    const dispatch = useDispatch();

    const carrierListStatus = useTypedSelector((state) => { return state.carrierListData.carrierListStatus; });
    const carrierList = useTypedSelector((state) => { return state.carrierListData.carrierList; });
    const userEntitlements = useTypedSelector((state) => { return state.userDetails.entitlements; });

    const [selectedCarrierId, setselectedCarrierId] = useState('');

    const isValidSupportUser = userEntitlements.some((entitlement: string): boolean => {
        return entitlement.toLowerCase() === 'support';
    });

    const selectedBusinessUnit = carrierList.find((carrierOrg) => {
        return carrierOrg.carrierOrganizationGuid === selectedCarrierId;
    });

    // When the page initially loads, make a call to the api to return the list of carriers.
    useEffect(() => {
        dispatch(fetchCarriers());
    }, [dispatch]);

    const handleLeftMenuClick = (selectedCarrierGuid: string): void => {
        setselectedCarrierId(selectedCarrierGuid);
    };

    if (carrierListStatus === ApiStatus.Idle || carrierListStatus === ApiStatus.Loading) {
        return (
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <ProgressLoader />
                </Grid>
            </Grid>
        );
    }

    if (!isValidSupportUser) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div>You must be a valid Support User to access this page.</div>
                </Grid>
            </Grid>
        );
    }

    return (
        <StyledPaper className={`${classes.wrapper} UserManagerPage--container--QA`}>
            <Grid container>
                <Grid item xs={6} lg={4} className={`${classes.leftPanel} leftPanel--container--QA`}>
                    <CarrierList
                        handleClick={handleLeftMenuClick}
                        selectedGuid={selectedCarrierId}
                    />
                </Grid>
                {
                    selectedBusinessUnit &&
                    <Grid item xs={6} lg={8} className={`${classes.rightPanel} rightPanel--container--QA`}>
                        <CarrierBusinessUnitList
                            carrierBusinessUnitList={selectedBusinessUnit.carrierBusinessUnit}
                        />
                    </Grid>
                }
            </Grid>
        </StyledPaper>
    );
};

export default UserManagerPage;
