import React from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import Icon from '@mdi/react';
import { mdiFilterVariant } from '@mdi/js';

import { useTypedSelector } from '../../redux';

const FilterButton = ({
    isFilterSideSheetOpen,
    toggleFilterSideSheet
}: {
    /** Indicator for if the filter side sheet is open. */
    isFilterSideSheetOpen: boolean;
    /** Function to toggle the filter side sheet. */
    toggleFilterSideSheet: () => void;
}): JSX.Element => {
    const filters = useTypedSelector((state) => { return state.shipmentListData.filters; });

    return (
        <Tooltip title='Filters'>
            <IconButton
                onClick={(): void => { toggleFilterSideSheet(); }}
                color={isFilterSideSheetOpen ? 'primary' : 'default'}
                data-qa='filters-button'
            >
                <Badge badgeContent={Object.keys(filters).length} color='primary'>
                    <Icon
                        path={mdiFilterVariant}
                        size={1}
                    />
                </Badge>
            </IconButton>
        </Tooltip>
    );
};

export default FilterButton;
