import { parsePhoneNumberFromString } from 'libphonenumber-js';

/**
 * This will take in a phone number string or null. It will attempt to determine the country calling code and return the formatted phone number. If it can not determine
 * the calling code it will return the orginal input if it is not a falsy value. Otherwise it will return '--'
 * @param inputPhoneNumber The phone number string or null.
 */
export const formattedPhoneNumber = (inputPhoneNumber: string | null): string => {
    if (inputPhoneNumber) {
        // Returns an instance of PhoneNumber class, or undefined if no phone number could be parsed:
        // for example, when the string contains no phone number, or the phone number starts with a non-existent country calling code
        const phoneNumber = parsePhoneNumberFromString(inputPhoneNumber);

        if (phoneNumber) {
            return phoneNumber.countryCallingCode === '1' ? phoneNumber.format('NATIONAL') : phoneNumber.format('INTERNATIONAL');
        }
        // if the phone number cant be parsed but we have something return what we were given
        return inputPhoneNumber;
    }
    // no phone number was passed in
    return '--';
};
