import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { mdiAccountPlus } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { updatePageReset } from '../../redux/dialogUpdates';
import { addUserToCarrierBusinessUnit } from '../../redux/carrier';
import CommonDialog from './commonDialog';

const AddCarrierBusinessUnitUserDialog = ({
    carrierBusinessUnitGuid,
    carrierBusinessUnitName,
    isOpen,
    closeDialog
}: {
    carrierBusinessUnitGuid: string;
    carrierBusinessUnitName: string;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    // If the API update is successful, trigger the dialog to reset and close
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            closeDialog();
        }
    }, [status, dispatch, closeDialog]);

    // Determine if form can be submitted
    useEffect(() => {
        let canSubmit = true;
        if (!firstName || !lastName || !phoneNumber || !emailAddress || !password) {
            canSubmit = false;
        }
        setCanSubmitForm(canSubmit);
    }, [firstName, lastName, phoneNumber, emailAddress, password]);

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        const userToSave = {
            userFirstName: firstName,
            userLastName: lastName,
            userEmailAddress: emailAddress,
            userPhoneNumber: phoneNumber,
            userPassword: password
        };

        // call the addUser function
        dispatch(addUserToCarrierBusinessUnit(carrierBusinessUnitGuid, userToSave));
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerIcon={mdiAccountPlus}
            headerText={`Add User to ${carrierBusinessUnitName}`}
            content={
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            id='first-name'
                            label='First Name'
                            value={firstName}
                            onChange={(event): void => {
                                setFirstName(event.currentTarget.value);
                            }}
                            margin='dense'
                            variant='outlined'
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='last-name'
                            label='Last Name'
                            value={lastName}
                            onChange={(event): void => {
                                setLastName(event.currentTarget.value);
                            }}
                            margin='dense'
                            variant='outlined'
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='email-address'
                            label='Email Address'
                            value={emailAddress}
                            onChange={(event): void => {
                                setEmailAddress(event.currentTarget.value);
                            }}
                            margin='dense'
                            variant='outlined'
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='phone-number'
                            label='Phone Number'
                            value={phoneNumber}
                            onChange={(event): void => {
                                setPhoneNumber(event.currentTarget.value);
                            }}
                            margin='dense'
                            variant='outlined'
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='user-password'
                            label='Password'
                            value={password}
                            onChange={(event): void => {
                                setPassword(event.currentTarget.value);
                            }}
                            margin='dense'
                            variant='outlined'
                            required
                        />
                    </Grid>
                    {
                        errorMessage &&
                        <Grid item xs={12}>
                            <Typography variant='caption' color='error'>{errorMessage}</Typography>
                        </Grid>
                    }
                </Grid>
            }
            actions={
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canSubmitForm}
                        onClick={handleSave}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Fragment>
            }
        />
    );
};

export default AddCarrierBusinessUnitUserDialog;
