import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import FilterButton from '../buttons/filterButton';
import SideSheet from '../sideSheets/sideSheet';
import Filters from '../sideSheets/filters';
import ShipperSelect from '../selects/shipperSelect';
import ShipmentSearch from '../searches/shipmentSearch';
import ShipmentListTable from '../tables/shipmentListTable';

const classesPrefix = 'shipmentList';

const classes = {
    pageWrapper: `${classesPrefix}-pageWrapper`,
    shipmentSelectWrapper: `${classesPrefix}-shipmentSelectWrapper`,
    shipmentSearchWrapper: `${classesPrefix}-shipmentSearchWrapper`
};

const StyledMain = styled(
    'main',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'open'; }
    }
)<{ open?: boolean; }>(({ open, theme }) => {
    return {
        [`&.${classes.pageWrapper}`]: {
            height: 'calc(100vh - 48px)',
            padding: '16px 32px 0 32px',
            flexGrow: 1,
            overflowY: 'auto',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            marginRight: 0,
            ...(open && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                }),
                paddingRight: '8px',
                marginRight: '320px'
            })
        },
        [`& .${classes.shipmentSelectWrapper}`]: {
            flexGrow: 1
        },
        [`& .${classes.shipmentSearchWrapper}`]: {
            maxWidth: '400px',
            marginLeft: '8px'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.pageWrapper}`]: {
                padding: '16px 8px 0 8px'
            },
            [`& .${classes.shipmentSelectWrapper}`]: {
                maxWidth: '100%'
            },
            [`& .${classes.shipmentSearchWrapper}`]: {
                marginLeft: 0,
                marginTop: '8px',
                maxWidth: '100%',
                flexGrow: 1
            }
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.shipmentSearchWrapper}`]: {
                maxWidth: '85%'
            }
        }
    };
});

const ShipmentList = (): JSX.Element => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isFilterSideSheetOpen, setIsFilterSideSheetOpen] = useState(false);

    const escKeyClose = useCallback((event: { keyCode: number; }) => {
        if (event.keyCode === 27) {
            setIsFilterSideSheetOpen(false);
        }
    }, []);

    useEffect(() => {
        // Event Listener, if the 'esc' key is pressed close the drawer
        document.addEventListener('keydown', escKeyClose, false);

        return () => {
            document.removeEventListener('keydown', escKeyClose, false);
        };
    }, [escKeyClose]);

    return (
        <Fragment>
            <StyledMain
                open={isFilterSideSheetOpen && !isMobile}
                className={classes.pageWrapper}
                data-qa='shipmentList-container'
            >
                <Grid container alignItems='center'>
                    <Grid item xs={12} md='auto' className={classes.shipmentSelectWrapper}>
                        <ShipperSelect />
                    </Grid>
                    <Grid item xs={11} md='auto' className={classes.shipmentSearchWrapper}>
                        <ShipmentSearch />
                    </Grid>
                    <Grid item xs='auto'>
                        <FilterButton
                            isFilterSideSheetOpen={isFilterSideSheetOpen}
                            toggleFilterSideSheet={(): void => { setIsFilterSideSheetOpen(!isFilterSideSheetOpen); }}
                        />
                    </Grid>
                </Grid>
                <ShipmentListTable />
            </StyledMain>
            <SideSheet
                open={isFilterSideSheetOpen}
                closeSideSheet={(): void => { setIsFilterSideSheetOpen(false); }}
            >
                <Filters />
            </SideSheet>
        </Fragment>
    );
};

export default ShipmentList;
