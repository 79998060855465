import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { useTypedSelector } from '../../redux';

const CarrierList = ({
    selectedGuid,
    handleClick
}: {
    selectedGuid: string;
    handleClick: (selectedCarrierGuid: string) => void;
}): JSX.Element => {
    const carrierList = useTypedSelector((state) => { return state.carrierListData.carrierList; });

    return (
        <List aria-labelledby='nested-list-subheader'>
            {
                carrierList.map((carriers): JSX.Element => {
                    return (
                        <ListItem
                            button
                            key={carriers.carrierOrganizationGuid}
                            selected={selectedGuid === carriers.carrierOrganizationGuid}
                            onClick={() => {
                                handleClick(carriers.carrierOrganizationGuid);
                            }}
                        >
                            <ListItemText primary={carriers.carrierName} />
                        </ListItem>
                    );
                })
            }
        </List>
    );
};

export default CarrierList;
