import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    Typography,
    CircularProgress,
    FormHelperText,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiCellphone, mdiChevronDown } from '@mdi/js';
import { CountryCode } from 'libphonenumber-js';
import PhoneInput, { parsePhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';

import { useTypedSelector } from '../../redux';
import { updatePageReset, updateMobileTracking } from '../../redux/dialogUpdates';
import { ApiStatus } from '../../helpers/enums';
import CommonDialog from './commonDialog';

import '../../assets/CSS/reactPhoneNumberInput.css';

const classesPrefix = 'assignTrackingDialog';

const classes = {
    arrowIcon: `${classesPrefix}-arrowIcon`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.arrowIcon}`]: {
            opacity: 0.45,
            marginTop: '3px'
        }
    };
});

const PhoneNumberTextField = React.forwardRef((
    props,
    ref
): JSX.Element => {
    return (
        <TextField
            {...props}
            inputRef={ref}
            label='Driver Phone Number'
            margin='none'
            variant='outlined'
            fullWidth
            inputProps={{
                autoComplete: 'off',
                'data-qa': 'driverPhoneNumber-input'
            }}
        />
    );
});
PhoneNumberTextField.displayName = 'PhoneNumberTextField';

const AssignTrackingDialog = ({
    shipmentUniqueName,
    carrierIdentifierType,
    carrierIdentifier,
    initialPhoneNumber,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    carrierIdentifierType: string;
    carrierIdentifier: string;
    initialPhoneNumber: string | null;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [phoneNumberHelperText, setPhoneNumberHelperText] = useState('');
    const [draftPhoneNumber, setDraftPhoneNumber] = useState('');

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    // Update local draft value if prop changes
    useEffect((): void => {
        // default the phoneNumber to the phone number passed in or empty string if nothing was passed
        let phoneNumber = initialPhoneNumber || '';

        if (phoneNumber) {
            // try to get a phone number object if an initial phone number was plugged in
            // if the phone number can't be parsed, undefined is returned
            const draftPhoneObj = parsePhoneNumber(phoneNumber);

            // if we have a phone number object, get the parsed phone number
            if (draftPhoneObj && draftPhoneObj.number) {
                phoneNumber = draftPhoneObj.number as string;
            }
        }

        setDraftPhoneNumber(phoneNumber);
    }, [initialPhoneNumber]);

    // Validate draft tractor/trailer values and determine if form can be submitted
    useEffect((): void => {
        let helperText = '';
        const hasPhoneNumberChanged = draftPhoneNumber !== initialPhoneNumber;

        if (draftPhoneNumber && !isPossiblePhoneNumber(draftPhoneNumber)) {
            helperText = 'Phone Number is invalid';
        }

        setPhoneNumberHelperText(hasPhoneNumberChanged ? helperText : '');
        setCanSubmitForm(draftPhoneNumber?.length > 0 && hasPhoneNumberChanged && helperText === '' && carrierIdentifierType !== '');
    }, [draftPhoneNumber, initialPhoneNumber, carrierIdentifierType]);

    // If the API update is successful, trigger the dialog to reset and close
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            closeDialog();
        }
    }, [status, dispatch, closeDialog]);

    const handlePhoneNumberChange = (newPhoneNumber: string): void => {
        setDraftPhoneNumber(newPhoneNumber);
    };

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        dispatch(updateMobileTracking({
            shipmentUniqueName,
            carrierIdentifier,
            carrierIdentifierType,
            driverPhoneNumber: draftPhoneNumber
        }));
    };

    const determineDefaultCountryCode = (): CountryCode => {
        // if there is an intial phone number, parse it and attempt to use the country code returned by the parser
        if (initialPhoneNumber) {
            const draftPhoneObj = parsePhoneNumber(initialPhoneNumber);
            return draftPhoneObj?.country || 'US';
        }

        // otherwise, attempt to use the browser language country code or just default to US
        return navigator.language.split('-')[1].toUpperCase() as CountryCode || 'US';
    };

    const ArrowComponent = (): JSX.Element => {
        return (
            <Icon className={classes.arrowIcon} path={mdiChevronDown} size={1} />
        );
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <StyledGrid container>
                <Grid item>
                    <PhoneInput
                        placeholder='Driver Mobile Number'
                        defaultCountry={determineDefaultCountryCode()}
                        countrySelectProps={{
                            unicodeFlags: true,
                            arrowComponent: ArrowComponent
                        }}
                        international
                        addInternationalOption
                        countryCallingCodeEditable
                        countryOptionsOrder={['US', 'CA', 'MX', 'AU', '...']}
                        value={draftPhoneNumber}
                        onChange={handlePhoneNumberChange}
                        inputComponent={PhoneNumberTextField}
                    />
                    <FormHelperText error>
                        {phoneNumberHelperText}
                    </FormHelperText>
                </Grid>

                {
                    !carrierIdentifierType &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                    </Grid>
                }

                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>{errorMessage}</Typography>
                    </Grid>
                }
            </StyledGrid>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerIcon={mdiCellphone}
            headerText='Assign Tracking'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canSubmitForm}
                        onClick={handleSave}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default AssignTrackingDialog;
