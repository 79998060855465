import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    CircularProgress,
    FormHelperText,
    Grid,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isValid } from 'date-fns';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { PositionResult } from '../../interfaces/componentInterfaces';
import { addPositionEvent, updatePageReset } from '../../redux/dialogUpdates';
import { formatDateForServer } from '../../helpers/dateUtils';
import CustomDateTimePicker from '../datePickers/customDateTimePicker';
import CommonDialog from './commonDialog';
import PositionSelect from '../selects/positionSelect';

const classesPrefix = 'addPositionDialog';

const classes = {
    selectWrapper: `${classesPrefix}-selectWrapper`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.selectWrapper}`]: {
            minHeight: '300px'
        }
    };
});

const AddPositionDialog = ({
    shipmentUniqueName,
    carrierIdentifierType,
    carrierIdentifier,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    carrierIdentifierType: string;
    carrierIdentifier: string;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [positionDateTime, setPositionDateTime] = useState<Date | null>(new Date());
    const [selectedPosition, setSelectedPosition] = useState<PositionResult | null>(null);

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    // Validate form values and determine if form can be submitted
    useEffect((): void => {
        const isPositionDateTimeValid = isValid(positionDateTime);
        const isSelectedPositionValid = selectedPosition !== null && selectedPosition.coordinates.Lat !== '' && selectedPosition.coordinates.Lon !== '';

        setCanSubmitForm(isPositionDateTimeValid && isSelectedPositionValid && carrierIdentifierType !== '');
    }, [positionDateTime, selectedPosition, carrierIdentifierType]);

    // If the API update is successful, trigger the dialog to reset and close
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            closeDialog();
        }
    }, [status, dispatch, closeDialog]);

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        if (selectedPosition) {
            const positionEventPayload = {
                carrierIdentifierType,
                carrierIdentifier,
                shipmentUniqueName,
                latitude: selectedPosition.coordinates.Lat,
                longitude: selectedPosition.coordinates.Lon,
                positionEventType: 3, // 3 is in transit
                positionReportTime: formatDateForServer(positionDateTime)
            };

            dispatch(addPositionEvent(positionEventPayload));
        }
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerText='Add Position'
            fullWidth={true}
            content={
                <StyledGrid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomDateTimePicker
                            isFetchingData={false}
                            disabled={false}
                            disableFuture={true}
                            label='Reported Date &amp; Time'
                            value={positionDateTime}
                            handleDateChange={(date: Date | null): void => {
                                setPositionDateTime(date);
                            }}
                            dataQa='addPosition-dateTimePicker'
                        />
                        <FormHelperText>PLEASE NOTE: Time should be local to the position</FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.selectWrapper}>
                            <PositionSelect
                                selectedValue={selectedPosition}
                                handleSelectedChange={(selectedValue: PositionResult | null): void => {
                                    setSelectedPosition(selectedValue);
                                }}
                            />
                        </div>
                    </Grid>

                    {
                        !carrierIdentifierType &&
                        <Grid item xs={12}>
                            <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                        </Grid>
                    }

                    {
                        errorMessage &&
                        <Grid item xs={12}>
                            <Typography variant='caption' color='error'>{errorMessage}</Typography>
                        </Grid>
                    }
                </StyledGrid>
            }
            actions={
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canSubmitForm}
                        onClick={handleSave}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Fragment>
            }
        />
    );
};

export default AddPositionDialog;
