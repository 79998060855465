import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    Link,
    Menu,
    MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';

import MsalAuthService from '../../services/msalAuthService';
import { useTypedSelector, emptyReduxStore } from '../../redux';
import TrimbleLogo from '../logos/trimbleLogo';

const classesPrefix = 'appHeader';

const classes = {
    appTitle: `${classesPrefix}-appTitle`,
    appMenu: `${classesPrefix}-appMenu`
};

const StyledAppBar = styled(AppBar)(() => {
    return {
        [`& .${classes.appTitle}`]: {
            flexGrow: 1,
            textAlign: 'center',
            textTransform: 'uppercase'
        },
        [`& .${classes.appMenu}`]: {
            marginLeft: 'auto'
        }
    };
});

const AppHeader = ({
    renderFullHeader
}: {
    renderFullHeader: boolean;
}): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);

    const userEntitlements = useTypedSelector((state) => { return state.userDetails.entitlements; });

    const isValidSupportUser = userEntitlements.some((entitlement: string): boolean => {
        return entitlement.toLowerCase() === 'support';
    });

    return (
        <StyledAppBar position='sticky' data-qa='applicationHeader-container'>
            <Toolbar variant='dense'>
                {renderFullHeader === true ?
                    (
                        <Link
                            component={RouterLink}
                            to='/'
                            aria-label='Back To Carrier Portal Home'
                        >
                            <TrimbleLogo />
                        </Link>
                    ) :
                    (
                        <Button>
                            <TrimbleLogo />
                        </Button>
                    )
                }
                <Typography
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                    variant='h6'
                    color='inherit'
                    className={classes.appTitle}
                >
                    Carrier Portal
                </Typography>
                <Tooltip title='Account'>
                    <IconButton
                        className={classes.appMenu}
                        color='inherit'
                        aria-haspopup='true'
                        onClick={(event): void => {
                            setAnchorEl(event.currentTarget);
                            setAccountMenuOpen(true);
                        }}
                        size='large'
                    >
                        <Icon path={mdiAccount} size={1} />
                    </IconButton>
                </Tooltip>
                <Menu
                    id='menu-appbar-account'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={isAccountMenuOpen}
                    onClose={(): void => {
                        setAnchorEl(null);
                        setAccountMenuOpen(false);
                    }}
                >
                    {
                        isValidSupportUser &&
                        <MenuItem
                            aria-label='Submit search'
                            onClick={(): void => {
                                setAccountMenuOpen(false);
                                history.push('/UserManagerPage');
                            }}
                        >
                            User Management
                        </MenuItem>
                    }
                    <MenuItem
                        onClick={(): void => {
                            setAccountMenuOpen(false);
                            dispatch(emptyReduxStore());
                            MsalAuthService.logout();
                        }}
                    >
                        Log Out
                    </MenuItem>
                </Menu>
            </Toolbar>
        </StyledAppBar>
    );
};

export default AppHeader;
