import React from 'react';
import { styled } from '@mui/material/styles';
import trimbleLogo from '../../assets/images/trimble_white.png';

const classesPrefix = 'trimbleLogo';

const classes = {
    companyLogo: `${classesPrefix}-companyLogo`
};

const StyledImg = styled('img')(({ theme }) => {
    return {
        [`&.${classes.companyLogo}`]: {
            maxHeight: '40px'
        },
        [theme.breakpoints.only('xs')]: {
            [`&.${classes.companyLogo}`]: {
                maxWidth: '100px'
            }
        }
    };
});

const TrimbleLogo = (): JSX.Element => {
    return (
        <StyledImg className={classes.companyLogo} src={trimbleLogo} alt='Trimble Logo' />
    );
};

export default TrimbleLogo;
