import { toast } from 'react-toastify';

import { AppThunk } from '..';
import { ApiStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import { GenericApiResponse } from '../../interfaces/services';
import { ShipmentServiceData, ShipmentData, ShipmentRouteData } from '../../interfaces/services/shipmentDetails';
import { ShipmentDetailsMapWeatherAlert, TrimbleMapsCountyInformation, WeatherAlert } from '../../interfaces/services/shipmentWeather';
import { initialShipmentDetailsState } from './initialState';
import { toQueryString } from '../../helpers/apiUtils';
import {
    UPDATE_TRACTOR_TRAILER_SUCCESS,
    UPDATE_MOBILE_TRACKING_SUCCESS,
    UPLOAD_DOCUMENT_SUCCESS,
    UPDATE_STOP_TIMES_SUCCESS,
    ReceiveTractorTrailerUpdateAction,
    ReceiveMobileTrackingUpdateAction,
    ReceiveUploadDocumentAction,
    ReceiveStopTimesUpdateAction
} from '../dialogUpdates';

const SHIPMENT_DETAILS_REQUEST = 'SHIPMENT_DETAILS_REQUEST';
const SHIPMENT_DETAILS_SUCCESS = 'SHIPMENT_DETAILS_SUCCESS';
const SHIPMENT_DETAILS_FAILURE = 'SHIPMENT_DETAILS_FAILURE';

const SHIPMENT_WEATHER_REQUEST = 'SHIPMENT_WEATHER_REQUEST';
const SHIPMENT_WEATHER_SUCCESS = 'SHIPMENT_WEATHER_SUCCESS';
const SHIPMENT_WEATHER_FAILURE = 'SHIPMENT_WEATHER_FAILURE';

interface ShipmentDetailsData {
    shipmentStatus: ApiStatus;
    shipment: ShipmentData;
    weatherStatus: ApiStatus;
    weatherAlerts: WeatherAlert[];
    weatherPolygons: ShipmentDetailsMapWeatherAlert[];
}

interface RequestShipmentDetailsAction {
    type: typeof SHIPMENT_DETAILS_REQUEST;
}

interface ReceiveShipmentDetailsAction {
    type: typeof SHIPMENT_DETAILS_SUCCESS;
    payload: {
        shipmentDetails: ShipmentServiceData;
        shipmentRoute: ShipmentRouteData;
    };
}

interface RequestShipmentDetailsFailedAction {
    type: typeof SHIPMENT_DETAILS_FAILURE;
}

interface RequestShipmentWeatherAction {
    type: typeof SHIPMENT_WEATHER_REQUEST;
}

interface ReceiveShipmentWeatherAction {
    type: typeof SHIPMENT_WEATHER_SUCCESS;
    payload: {
        weatherAlerts: WeatherAlert[];
        weatherCountyInformation: TrimbleMapsCountyInformation[];
    };
}

interface RequestShipmentWeatherFailedAction {
    type: typeof SHIPMENT_WEATHER_FAILURE;
}

type ShipmentDetailsActionTypes =
    RequestShipmentDetailsAction | ReceiveShipmentDetailsAction | RequestShipmentDetailsFailedAction |
    RequestShipmentWeatherAction | ReceiveShipmentWeatherAction | RequestShipmentWeatherFailedAction |
    ReceiveTractorTrailerUpdateAction | ReceiveMobileTrackingUpdateAction | ReceiveUploadDocumentAction | ReceiveStopTimesUpdateAction;

const requestShipmentDetails = (): RequestShipmentDetailsAction => {
    return {
        type: SHIPMENT_DETAILS_REQUEST
    };
};

const receiveShipmentDetails = (
    shipmentDetails: ShipmentServiceData,
    shipmentRoute: ShipmentRouteData = { breadcrumbPositions: [], plannedRoute: [] }
): ReceiveShipmentDetailsAction => {
    return {
        type: SHIPMENT_DETAILS_SUCCESS,
        payload: {
            shipmentDetails,
            shipmentRoute
        }
    };
};

const requestShipmentDetailsFailed = (): RequestShipmentDetailsFailedAction => {
    return {
        type: SHIPMENT_DETAILS_FAILURE
    };
};

const shipmentDetailsPromise = async (shipmentApiKey: string, shipmentDetailsUri: string): Promise<ShipmentServiceData | null> => {
    try {
        const json = await ApiService.get(shipmentDetailsUri, shipmentApiKey) as GenericApiResponse<ShipmentServiceData>;
        if (json.data.length > 0) {
            return json.data[0];
        }

        throw new Error('Missing Shipment Details');
    } catch (err) {
        toast.error('Error occurred while fetching shipment details.');
        return null;
    }
};

export const fetchShipmentDetails = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentDetails());

        const shipmentDetails = await shipmentDetailsPromise(getState().shipmentListData.apiKey, `${getState().appConfig.shipmentApi}/v1/shipments/${shipmentUniqueName}`);
        // Since the promise can either return the data or null, we will check that it's not null before calling it a success
        if (shipmentDetails !== null) {
            dispatch(receiveShipmentDetails(shipmentDetails));
        } else {
            // If it is null, we will trigger the failure for the shipment details so that the error page will render
            dispatch(requestShipmentDetailsFailed());
        }
    };
};

export const fetchShipmentDetailsAndRoute = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentDetails());

        const shipmentDetailsRoutePromise = async (): Promise<ShipmentRouteData | null> => {
            try {
                const json = await ApiService.get(`${getState().appConfig.shipmentApi}/v1/shipments/${shipmentUniqueName}/route`, getState().shipmentListData.apiKey) as GenericApiResponse<ShipmentRouteData>;
                if (json.data.length > 0) {
                    return json.data[0];
                }

                throw new Error('Missing Shipment Details Route');
            } catch (err) {
                toast.error('Error occurred while fetching shipment details route.');
                return null;
            }
        };

        Promise.all([
            shipmentDetailsPromise(getState().shipmentListData.apiKey, `${getState().appConfig.shipmentApi}/v1/shipments/${shipmentUniqueName}`),
            shipmentDetailsRoutePromise()
        ]).then(([
            shipmentDetails,
            shipmentDetailsRoute
        ]): void => {
            // Since the promise can either return the data or null, we will check that it's not null before calling it a success
            if (shipmentDetails !== null) {
                // Since the route promise can either return the data or null, we will check the existence of the route data and default it before passing it to the reducer
                dispatch(receiveShipmentDetails(shipmentDetails, {
                    plannedRoute: shipmentDetailsRoute?.plannedRoute || [],
                    breadcrumbPositions: shipmentDetailsRoute?.breadcrumbPositions || []
                }));
            } else {
                // If it is null, we will trigger the failure for the shipment details so that the error page will render
                dispatch(requestShipmentDetailsFailed());
            }
        });
    };
};

const requestShipmentWeather = (): RequestShipmentWeatherAction => {
    return {
        type: SHIPMENT_WEATHER_REQUEST
    };
};

const receiveShipmentWeather = (weatherAlerts: WeatherAlert[], weatherCountyInformation: TrimbleMapsCountyInformation[]): ReceiveShipmentWeatherAction => {
    return {
        type: SHIPMENT_WEATHER_SUCCESS,
        payload: {
            weatherAlerts,
            weatherCountyInformation
        }
    };
};

const requestShipmentWeatherFailed = (): RequestShipmentWeatherFailedAction => {
    return {
        type: SHIPMENT_WEATHER_FAILURE
    };
};

export const fetchShipmentWeather = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentWeather());

        try {
            const json = await ApiService.get(`${getState().appConfig.shipmentApi}/v2/shipments/${shipmentUniqueName}/weather`, getState().shipmentListData.apiKey) as GenericApiResponse<WeatherAlert>;
            const { data } = json;

            // create a list of all fips codes across all alerts and then make sure it's a unique list
            const allFipsCodes = data.map((alert: WeatherAlert) => {
                return alert.fipsCodes;
            });
            const uniqueFipsCodes = Array.from(new Set(allFipsCodes.flat()));

            // The Trimble Maps API can only handle 200 fips codes at a time, so we need to chunk up the list to make multiple calls if there are more.
            const size = 200;
            const chunkedArray: string[][] = [];
            let chunkIndex = 0;
            while (chunkIndex < uniqueFipsCodes.length) {
                chunkedArray.push(uniqueFipsCodes.slice(chunkIndex, size + chunkIndex));
                chunkIndex += size;
            }

            const trimbleMapsCountyPromises = chunkedArray.map((codes: string[]): Promise<TrimbleMapsCountyInformation[]> => {
                const options = {
                    method: 'GET',
                    headers: {
                        Authorization: 'A413EAA4767EC44E94A2360AE03B8689',
                        'Content-Type': 'application/json'
                    }
                };

                return fetch(`https://pcmiler.alk.com/apis/REST/v1.0/service.svc/polygons/county?${toQueryString({ codes })}`, options)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        }
                        toast.error('Failed to retrieve polygon information for FIPS codes');
                        console.error(`Failed to retrieve polygon information for FIPS codes: ${codes}.`);
                        return Promise.resolve([]);
                    }, (error): void => {
                        toast.error('Fatal error while retrieving polygon information for FIPS codes');
                        console.error(`Fatal error while retrieving polygon information for FIPS codes: ${codes}. Error: ${error}`);
                    })
                    .then((trimbleMapsJson: TrimbleMapsCountyInformation[]): TrimbleMapsCountyInformation[] => {
                        if (trimbleMapsJson !== undefined) {
                            return trimbleMapsJson;
                        }
                        return [];
                    });
            });

            Promise.all(trimbleMapsCountyPromises).then((countyInformation: TrimbleMapsCountyInformation[][]): void => {
                const flattenedCountyInformation = countyInformation.flat();
                dispatch(receiveShipmentWeather(data, flattenedCountyInformation));
            });
        } catch (error) {
            dispatch(requestShipmentWeatherFailed());
            toast.error('Error occurred while fetching shipment weather.', error);
        }
    };
};

export const shipmentDetailsDataReducer = (shipmentDetailsData: ShipmentDetailsData = {
    shipmentStatus: ApiStatus.Idle,
    shipment: initialShipmentDetailsState,
    weatherStatus: ApiStatus.Idle,
    weatherAlerts: [],
    weatherPolygons: []
}, action: ShipmentDetailsActionTypes): ShipmentDetailsData => {
    switch (action.type) {
        case SHIPMENT_DETAILS_REQUEST: {
            return {
                ...shipmentDetailsData,
                shipmentStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_DETAILS_SUCCESS: {
            return {
                ...shipmentDetailsData,
                shipmentStatus: ApiStatus.Success,
                shipment: {
                    ...action.payload.shipmentDetails,
                    ...action.payload.shipmentRoute
                }
            };
        }
        case SHIPMENT_DETAILS_FAILURE: {
            return {
                ...shipmentDetailsData,
                shipmentStatus: ApiStatus.Failure,
                shipment: initialShipmentDetailsState
            };
        }
        case SHIPMENT_WEATHER_REQUEST: {
            return {
                ...shipmentDetailsData,
                weatherStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_WEATHER_SUCCESS: {
            const { weatherAlerts, weatherCountyInformation } = action.payload;
            const weatherPolygons: ShipmentDetailsMapWeatherAlert[] = [];

            weatherCountyInformation.forEach((county: TrimbleMapsCountyInformation): void => {
                const weatherAlertsForFipsCode = weatherAlerts.filter((alert): boolean => {
                    return alert.fipsCodes.includes(county.Code);
                });

                if (county.Polygon) {
                    // the response from trimble maps can be a polygon or multipolygon,
                    // so we check to see what the string starts with and will do different logic for each
                    if (county.Polygon.startsWith('P')) {
                        weatherPolygons.push({
                            alertList: weatherAlertsForFipsCode,
                            fipsCode: county.Code,
                            countyName: county.Name,
                            polygon: county.Polygon
                        });
                    } else if (county.Polygon.startsWith('M')) {
                        // split the multipolygons up and then get the data points for each polygon
                        const splitMultiPolygons = county.Polygon.split(')), ((');
                        splitMultiPolygons.forEach((singlePolygon) => {
                            weatherPolygons.push({
                                alertList: weatherAlertsForFipsCode,
                                fipsCode: county.Code,
                                countyName: county.Name,
                                polygon: singlePolygon
                            });
                        });
                    }
                }
            });

            return {
                ...shipmentDetailsData,
                weatherStatus: ApiStatus.Success,
                weatherAlerts,
                weatherPolygons
            };
        }
        case SHIPMENT_WEATHER_FAILURE: {
            return {
                ...shipmentDetailsData,
                weatherStatus: ApiStatus.Failure,
                weatherAlerts: [],
                weatherPolygons: []
            };
        }
        case UPDATE_TRACTOR_TRAILER_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        ...action.payload.assets?.tractorReferenceNumber !== undefined && {
                            tractorReferenceNumber: action.payload.assets.tractorReferenceNumber
                        },
                        ...action.payload.assets?.trailerReferenceNumber !== undefined && {
                            trailerReferenceNumber: action.payload.assets.trailerReferenceNumber
                        }
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPDATE_MOBILE_TRACKING_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        mobileTrackingNumber: action.payload.driverPhoneNumber
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPLOAD_DOCUMENT_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        documents: [...shipmentDetailsData.shipment.documents, action.payload.document]
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPDATE_STOP_TIMES_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        stops: shipmentDetailsData.shipment.stops.map((stop) => {
                            if (stop.stopSequence === action.payload.stopSequence) {
                                return {
                                    ...stop,
                                    ...action.payload.appointmentDate && action.payload.appointmentEndDate && {
                                        appointment: {
                                            startTime: action.payload.appointmentDate,
                                            endTime: action.payload.appointmentEndDate
                                        }
                                    },
                                    ...action.payload.estimatedDeliveryDate && {
                                        estimatedDeliveryDateTime: action.payload.estimatedDeliveryDate
                                    },
                                    ...action.payload.positionEventType === 'Arrived' && action.payload.reportedDate && {
                                        actualArrivalDateTime: action.payload.reportedDate
                                    },
                                    ...action.payload.positionEventType === 'Departed' && action.payload.reportedDate && {
                                        actualDepartureDateTime: action.payload.reportedDate
                                    }
                                };
                            }
                            return stop;
                        })
                    }
                };
            }
            return shipmentDetailsData;
        }
        default:
            return shipmentDetailsData;
    }
};
