import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'shippersError';

const classes = {
    root: `${classesPrefix}-root`,
    errorMesssage: `${classesPrefix}-errorMesssage`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.root}`]: {
            margin: '16px'
        },
        [`& .${classes.errorMesssage}`]: {
            paddingTop: '16px',
            paddingBottom: '16px'
        }
    };
});

const ShippersError = (): JSX.Element => {
    return (
        <StyledDiv className={classes.root}>
            <h2>Shipper Error</h2>
            <Typography className={classes.errorMesssage}>Failed to retrieve shippers. Please refresh to try again.</Typography>
        </StyledDiv>
    );
};

export default ShippersError;
