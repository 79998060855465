import React, { useState } from 'react';
import {
    Grid,
    Button,
    Card
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import AssignTrackingDialog from '../dialogs/assignTrackingDialog';
import AddPositionDialog from '../dialogs/addPositionDialog';

const classesPrefix = 'actionBar';

const classes = {
    cardWrapper: `${classesPrefix}-cardWrapper`,
    actionButtonsWrapper: `${classesPrefix}-actionButtonsWrapper`,
    buttonWrapper: `${classesPrefix}-buttonWrapper`
};

const StyledCard = styled(Card)(({ theme }) => {
    return {
        [`&.${classes.cardWrapper}`]: {
            height: '68px'
        },
        [`& .${classes.actionButtonsWrapper}`]: {
            padding: '16px'
        },
        [`& .${classes.buttonWrapper}`]: {
            width: '100%'
        },
        [theme.breakpoints.only('xs')]: {
            [`&.${classes.cardWrapper}`]: {
                height: '120px'
            }
        }
    };
});

const ActionBar = (): JSX.Element => {
    const [isTrackingDialogOpen, setTrackingDialogOpen] = useState(false);
    const [isPositionDialogOpen, setPositionDialogOpen] = useState(false);

    const selectedShipper = useTypedSelector((state) => { return state.shipperListData.selectedShipper; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetailsData.shipment; });

    return (
        <StyledCard className={classes.cardWrapper}>
            <Grid
                className={classes.actionButtonsWrapper}
                container
                spacing={2}
                direction='row'
                justifyContent='center'
            >
                <Grid item xs={12} sm={6}>
                    <Button
                        className={classes.buttonWrapper}
                        variant='outlined'
                        color='primary'
                        onClick={(): void => {
                            setTrackingDialogOpen(true);
                        }}
                    >
                        Assign Tracking
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        className={classes.buttonWrapper}
                        variant='outlined'
                        color='primary'
                        onClick={(): void => {
                            setPositionDialogOpen(true);
                        }}
                    >
                        Add Position
                    </Button>
                </Grid>

                {
                    isTrackingDialogOpen &&
                    <AssignTrackingDialog
                        shipmentUniqueName={shipment.shipmentUniqueName}
                        initialPhoneNumber={shipment.mobileTrackingNumber}
                        carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                        carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                        isOpen={isTrackingDialogOpen}
                        closeDialog={(): void => { setTrackingDialogOpen(false); }}
                    />
                }
                {
                    isPositionDialogOpen &&
                    <AddPositionDialog
                        shipmentUniqueName={shipment.shipmentUniqueName}
                        carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                        carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                        isOpen={isPositionDialogOpen}
                        closeDialog={(): void => { setPositionDialogOpen(false); }}
                    />
                }
            </Grid>
        </StyledCard>
    );
};

export default ActionBar;
