export const publicRoutes = {
    root: '/',
    authorize: '/authorize',
    revoke: '/revoke',
    notAuthorized: '/notAuthorized'
};

export const protectedRoutes = {
    shipmentDetails: '/shipmentDetails/:shipmentUniqueName',
    tripDetails: '/tripDetails/:tripIdentifier'
};

export const privateRoutes = {
    shipmentsOverview: '/shipments/overview',
    shipmentsActive: '/shipments/active',
    shipmentsHistory: '/shipments/history'
};
