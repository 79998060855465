import { combineReducers, Action } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage/session';

/* V8 Redux more correctly defines action dispatch type for redux-thunk, which only allows for Action objects.
*  Unless we switch to React Toolkit and define our typed hooks, we need to import the types needed for
*  our thunks to be correctly typed. Issue Link: https://github.com/reduxjs/redux-thunk/issues/333#issuecomment-1109308664 */
import type {} from 'redux-thunk/extend-redux';

import { appConfigReducer } from './appConfig';
import { authenticationReducer } from './authentication';
import { fetchCarriersReducer } from './carrier';
import { dialogUpdatesReducer } from './dialogUpdates';
import { shipmentDetailsDataReducer } from './shipmentDetails';
import { shipmentListDataReducer } from './shipments';
import { fetchShippersReducer } from './shipper';
import { userDetailsReducer } from './userDetails';

const EMPTY_STORE = 'EMPTY_STORE';

interface EmptyStoreAction {
    type: typeof EMPTY_STORE;
}

const appReducer = combineReducers({
    appConfig: appConfigReducer,
    authentication: authenticationReducer,
    carrierListData: fetchCarriersReducer,
    dialogUpdates: dialogUpdatesReducer,
    shipmentDetailsData: shipmentDetailsDataReducer,
    shipmentListData: shipmentListDataReducer,
    shipperListData: fetchShippersReducer,
    userDetails: userDetailsReducer
});

type AppState = ReturnType<typeof appReducer>;

export const emptyReduxStore = (): EmptyStoreAction => {
    return {
        type: EMPTY_STORE
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: any): AppState => {
    switch (action.type) {
        case EMPTY_STORE: {
            storage.removeItem('persist:carrierportal_ui');
            return appReducer(undefined, action);
        }
        default:
            return appReducer(state, action);
    }
};

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
