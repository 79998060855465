export enum AllowedFilterPropertyName {
    // date filters
    OriginAppointmentStartDateTime = 'originAppointmentStartDateTime',
    OriginAppointmentEndDateTime = 'originAppointmentEndDateTime',
    DestinationAppointmentStartDateTime = 'destinationAppointmentStartDateTime',
    DestinationAppointmentEndDateTime = 'destinationAppointmentEndDateTime',

    // master data
    Origin = 'originDisplay',
    Destination = 'destinationDisplay',

    // hardcoded filters
    ShipmentStatus = 'shipmentStatus'
}

export enum ApiStatus {
    Idle = 'idle',
    Loading = 'loading',
    Success = 'success',
    Failure = 'failure'
}

export enum SortDirection {
    Ascending = 'ascending',
    Descending = 'descending'
}

export enum StopType {
    Origin = 'Origin',
    Intermediate = 'Intermediate',
    Destination = 'Destination'
}

export enum LinkType {
    PublicAPI = 'PublicAPI',
    ShipmentAPI = 'ShipmentAPI',
    MasterDataAPI = 'MasterDataAPI'
}

export enum FreightHaulerIdentifierTypeName {
    MCNumber = 'MCNumber',
    USDotNumber = 'USDotNumber',
    SCACCode = 'SCACCode'
}

export enum DocumentType {
    All = 'All',
    RateConfirmation = 'RateConfirmation',
    POD = 'POD',
    BOL = 'BOL',
    Invoice = 'Invoice',
    FreightDamageDocumentation = 'FreightDamageDocumentation',
    TruckTrailerDamageDocumentation = 'TruckTrailerDamageDocumentation',
    LumperReceipt = 'LumperReceipt',
    DeliveryTicketReceiptOrder = 'DeliveryTicketReceiptOrder',
    Insurance = 'Insurance',
    WeightScaleCertificate = 'WeightScaleCertificate',
    FineTicket = 'FineTicket',
    DOTInspection = 'DOTInspection',
    ODPermit = 'ODPermit',
    Directions = 'Directions',
    PackingSlip = 'PackingSlip',
    Check = 'Check',
    FreightBill = 'FreightBill',
    Log = 'Log',
    FuelReceipt = 'FuelReceipt',
    ScaleReceipt = 'ScaleReceipt',
    TripSheet = 'TripSheet',
    ProofOfPerformance = 'ProofOfPerformance',
    Match = 'Match',
    Other = 'Other',
    CarrierLogoSmall = 'CarrierLogoSmall',
    CarrierLogoLarge = 'CarrierLogoLarge',
    W9 = 'W9',
    LetterOfAuthority = 'LetterOfAuthority',
    FuelSurcharge = 'FuelSurcharge',
    CarrierAgreement = 'CarrierAgreement',
    AccessorialList = 'AccessorialList',
    SignatureCapture = 'SignatureCapture'
}

export enum PublicDocumentType {
    All = 0,
    RateConfirmation = 1,
    POD = 2,
    BOL = 3,
    Invoice = 4,
    FreightDamageDocumentation = 5,
    TruckTrailerDamageDocumentation = 6,
    LumperReceipt = 7,
    DeliveryTicketReceiptOrder = 8,
    Insurance = 9,
    WeightScaleCertificate = 10,
    FineTicket = 11,
    DOTInspection = 12,
    ODPermit = 13,
    Directions = 14,
    PackingSlip = 15,
    Check = 16,
    FreightBill = 17,
    Log = 18,
    FuelReceipt = 19,
    ScaleReceipt = 20,
    TripSheet = 21,
    ProofOfPerformance = 22,
    Match = 23,
    Other = 24,
    CarrierLogoSmall = 25,
    CarrierLogoLarge = 26,
    W9 = 32,
    LetterOfAuthority = 33,
    FuelSurcharge = 34,
    CarrierAgreement = 35,
    AccessorialList = 36,
    SignatureCapture = 37
}

export enum LinearUnit {
    Miles = 'Miles',
    Feet = 'Feet',
    Kilometers = 'Kilometers',
    Meters = 'Meters',
    Inches = 'Inches'
}

export enum PieceUnit {
    Pallet = 'Pallet',
    Box = 'Box',
    Each = 'Each'
}

export enum WeightUnit {
    Pounds = 'Pounds',
    Kilograms = 'Kilograms',
    MetricTons = 'MetricTons',
    ShortTons = 'ShortTons'
}

export enum VolumeUnit {
    Gallons = 'Gallons',
    Liters = 'Liters'
}

export enum TemperatureUnit {
    Fahrenheit = 'Fahrenheit',
    Celsius = 'Celsius'
}

export enum ReferenceNumberType {
    CustomerReferenceNumber = 'CustomerReferenceNumber',
    JobNumber = 'JobNumber',
    PONumber = 'PONumber',
    PRONumber = 'PRONumber',
    BillOfLading = 'BillOfLading',
    SiteReferenceNumber = 'SiteReferenceNumber',
    PickupNumber = 'PickupNumber',
    DeliveryNumber = 'DeliveryNumber',
    AppointmentNumber = 'AppointmentNumber',
    SealNumber = 'SealNumber',
    OtherNumber = 'OtherNumber',
    LoadNumber = 'LoadNumber',
    MMSINumber = 'MMSINumber',
    FedexNumber = 'FedexNumber',
    USPSNumber = 'USPSNumber',
    UPSNumber = 'UPSNumber',
    ContainerNumber = 'ContainerNumber',
    TripNumber = 'TripNumber',
    VesselNumber = 'VesselNumber',
    VoyageNumber = 'VoyageNumber',
    WorkOrderNumber = 'WorkOrderNumber',
    BookingNumber = 'BookingNumber',
    PickupReferenceNumber = 'PickupReferenceNumber',
    DropoffReferenceNumber = 'DropoffReferenceNumber',
    CarrierAssignedReferenceNumber = 'CarrierAssignedReferenceNumber',
    TractorNumber = 'TractorNumber',
    TrailerNumber = 'TrailerNumber',
    FreightProviderReferenceNumber = 'FreightProviderReferenceNumber'
}

/** Contains list of Shipment Statuses for the Shipment. */
export enum ShipmentStatus {
    Accepted = 'Accepted',
    AtConsignee = 'AtConsignee',
    AtShipper = 'AtShipper',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
    Delivered = 'Delivered',
    Dispatched = 'Dispatched',
    EnRoute = 'EnRoute',
    InTransit = 'InTransit',
    OnHold = 'OnHold',
    Pending = 'Pending'
}

/** Contains list of Delivery Statuses for the Shipment. */
export enum DeliveryStatus {
    Early = 'Early',
    OnTime = 'OnTime',
    InJeopardy = 'InJeopardy',
    Late = 'Late',
    TrackingLost = 'TrackingLost',
    TenderAccepted = 'TenderAccepted'
}

/** Contains list of Weather Severity for the Shipment. */
export enum WeatherAlertSeverity {
    Severe = 'Severe',
    Moderate = 'Moderate',
    Minor = 'Minor',
    Unknown = 'Unknown'
}

/** Contains list of Mode types for the Shipment. */
export enum ModeType {
    Unknown = 'Unknown',
    Truckload = 'Truckload',
    Rail = 'Rail',
    LTL = 'LTL',
    Ocean = 'Ocean',
    Parcel = 'Parcel'
}

export enum MilestoneType {
    Pending = 'Pending',
    PickedUp = 'PickedUp',
    InTransit = 'InTransit',
    OutForDelivery = 'OutForDelivery',
    Delivered = 'Delivered'
}
