/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiCalendarRange, mdiClose } from '@mdi/js';
import { addDays, subDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { DateRange } from 'react-date-range';

import { AllowedFilterPropertyName } from '../../helpers/enums';
import { simpleServerDateFormat, simpleInternationalizedDateFormat } from '../../helpers/dateUtils';
import Hidden from '../layouts/hidden';
import '../../assets/CSS/reactDateRange.css';

const classesPrefix = 'filterDateRangePicker';

const classes = {
    formControl: `${classesPrefix}-formControl`,
    dialogDatePicker: `${classesPrefix}-dialogDatePicker`,
    datePickerDialogContent: `${classesPrefix}-datePickerDialogContent`,
    filterSelect: `${classesPrefix}-filterSelect`
};

const StyledTextField = styled(TextField)(() => {
    return {
        [`& .${classes.formControl}`]: {
            marginTop: '8px'
        }
    };
});

const StyledDialog = styled(Dialog)(({ theme }) => {
    return {
        [`& .${classes.dialogDatePicker}`]: {
            position: 'absolute',
            top: '40px',
            right: '288px'
        },
        [`& .${classes.datePickerDialogContent}`]: {
            padding: 0,
            textAlign: 'center',
            '&:first-of-type': {
                padding: 0
            }
        },
        [`& .${classes.formControl}`]: {
            marginTop: '8px'
        },
        [`& .${classes.filterSelect}`]: {
            minWidth: '200px'
        },
        [theme.breakpoints.down('md')]: {
            [`& .${classes.dialogDatePicker}`]: {
                position: 'relative',
                top: 0,
                right: 0
            }
        }
    };
});

enum FilterType {
    Custom = 'Custom',
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    Yesterday = 'Yesterday',
    ThisWeek = 'This Week'
}

const FilterDateRangePicker = ({
    id,
    label,
    currentValue,
    handleChange,
    disabled = false
}: {
    id: AllowedFilterPropertyName;
    label: string;
    currentValue: string[];
    handleChange: (filterId: AllowedFilterPropertyName, filterValues: string[]) => void;
    disabled?: boolean;
}): JSX.Element => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const TODAY = new Date();

    const [draftStartDate, setDraftStartDate] = useState<Date>(TODAY);
    const [draftEndDate, setDraftEndDate] = useState<Date>(TODAY);
    const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(FilterType.Custom);

    const getStartAndEndDatesFromString = useCallback((): { start: Date; end: Date; } => {
        const splitDates = currentValue[0].split(' - ');
        const start = new Date(splitDates[0]);
        const end = new Date(splitDates[1]);

        return { start, end };
    }, [currentValue]);

    const initializeDates = useCallback((): void => {
        if (currentValue.length > 0 && currentValue[0] !== FilterType.Today &&
            currentValue[0] !== FilterType.Tomorrow && currentValue[0] !== FilterType.Yesterday &&
            currentValue[0] !== FilterType.ThisWeek) {
            // if the props contained a string, pull off the individual dates and set to our local draft state
            const dates = getStartAndEndDatesFromString();

            setDraftStartDate(dates.start);
            setDraftEndDate(dates.end);
        } else {
            // otherwise initialize our local draft state to be today's date
            const today = new Date();
            setDraftStartDate(today);
            setDraftEndDate(today);
        }
    }, [getStartAndEndDatesFromString, currentValue]);

    useEffect((): void => {
        initializeDates();
    }, [initializeDates]);

    useEffect((): void => {
        if (currentValue.length > 0 && (currentValue[0] === FilterType.Today ||
            currentValue[0] === FilterType.Tomorrow || currentValue[0] === FilterType.Yesterday ||
            currentValue[0] === FilterType.ThisWeek)) {
            const today = new Date();
            switch (currentValue[0]) {
                case FilterType.Tomorrow: {
                    const tomorrow = addDays(today, 1);
                    setDraftStartDate(tomorrow);
                    setDraftEndDate(tomorrow);
                    setSelectedFilter(FilterType.Tomorrow);
                    break;
                }
                case FilterType.Yesterday: {
                    const yesterday = subDays(today, 1);
                    setDraftStartDate(yesterday);
                    setDraftEndDate(yesterday);
                    setSelectedFilter(FilterType.Yesterday);
                    break;
                }
                case FilterType.ThisWeek: {
                    setDraftStartDate(today);
                    setDraftEndDate(addDays(today, 7));
                    setSelectedFilter(FilterType.ThisWeek);
                    break;
                }
                case FilterType.Today:
                default: {
                    setDraftStartDate(today);
                    setDraftEndDate(today);
                    setSelectedFilter(FilterType.Today);
                    break;
                }
            }
        }

    }, [currentValue]);

    const handleDateClear = (event: React.MouseEvent<HTMLButtonElement>): void => {
        handleChange(id, []);
        setSelectedFilter(FilterType.Custom);
        event.stopPropagation();
    };

    const handleCalendarClick = (): void => {
        if (!disabled) {
            setIsDateRangePickerOpen(!isDateRangePickerOpen);
        }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
    };

    const getDisplayDate = (): string => {
        if (currentValue.length > 0) {
            if (currentValue[0] === FilterType.Today || currentValue[0] === FilterType.Tomorrow ||
                currentValue[0] === FilterType.Yesterday || currentValue[0] === FilterType.ThisWeek) {
                return currentValue[0];
            }
            // if the props contains a date range, we need to i18n format each one for display purposes
            const dates = getStartAndEndDatesFromString();

            const formattedStartDate = format(dates.start, simpleInternationalizedDateFormat);
            const formattedEndDate = format(dates.end, simpleInternationalizedDateFormat);

            return `${formattedStartDate} - ${formattedEndDate}`;
        }
        return '';
    };

    return (
        <Fragment>
            <StyledTextField
                id={id}
                label={label}
                fullWidth
                margin='normal'
                variant='filled'
                disabled={disabled}
                value={getDisplayDate()}
                onClick={handleCalendarClick}
                classes={{
                    root: classes.formControl
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end' disablePointerEvents={disabled}>
                            {
                                currentValue.length > 0 &&
                                <IconButton
                                    aria-label='clear selected dates'
                                    size='small'
                                    onClick={handleDateClear}
                                    data-qa={`${id}-action-clear`}
                                >
                                    <Icon path={mdiClose} size={1} />
                                </IconButton>
                            }
                            <IconButton
                                aria-label='toggle calendar visibility'
                                size='small'
                                onMouseDown={handleMouseDown}
                                data-qa={`${id}-action-toggleCalendar`}
                            >
                                <Icon path={mdiCalendarRange} size={1} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    inputProps: {
                        autoComplete: 'off',
                        'data-qa': `${id}-input`
                    }
                }}
            />
            <StyledDialog
                classes={{
                    paper: classes.dialogDatePicker
                }}
                fullScreen={isMobile}
                open={isDateRangePickerOpen}
                onClose={(): void => {
                    setIsDateRangePickerOpen(false);
                    initializeDates();
                }}
                aria-labelledby='date range picker dialog'
                data-qa='dateRangePicker-dialog'
            >
                <DialogTitle data-qa='dateRangePicker-dialogTitle'>
                    {label}
                </DialogTitle>
                <DialogContent
                    classes={{
                        root: classes.datePickerDialogContent
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} md='auto'>
                            <Hidden breakpoint='md' direction='down'>
                                <List>
                                    <ListItemButton
                                        selected={selectedFilter === FilterType.Custom}
                                        onClick={(): void => {
                                            setDraftStartDate(TODAY);
                                            setDraftEndDate(TODAY);
                                            setSelectedFilter(FilterType.Custom);
                                        }}
                                        data-qa='filterType-item-customDateRange'
                                    >
                                        <ListItemText primary='Custom Date Range' />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedFilter === FilterType.Today}
                                        onClick={(): void => {
                                            handleChange(id, [FilterType.Today]);
                                            setIsDateRangePickerOpen(false);
                                            setSelectedFilter(FilterType.Today);
                                        }}
                                        data-qa='filterType-item-today'
                                    >
                                        <ListItemText primary='Today' />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedFilter === FilterType.Tomorrow}
                                        onClick={(): void => {
                                            handleChange(id, [FilterType.Tomorrow]);
                                            setIsDateRangePickerOpen(false);
                                            setSelectedFilter(FilterType.Tomorrow);
                                        }}
                                        data-qa='filterType-item-tomorrow'
                                    >
                                        <ListItemText primary='Tomorrow' />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedFilter === FilterType.Yesterday}
                                        onClick={(): void => {
                                            handleChange(id, [FilterType.Yesterday]);
                                            setIsDateRangePickerOpen(false);
                                            setSelectedFilter(FilterType.Yesterday);
                                        }}
                                        data-qa='filterType-item-yesterday'
                                    >
                                        <ListItemText primary='Yesterday' />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedFilter === FilterType.ThisWeek}
                                        onClick={(): void => {
                                            handleChange(id, [FilterType.ThisWeek]);
                                            setIsDateRangePickerOpen(false);
                                            setSelectedFilter(FilterType.ThisWeek);
                                        }}
                                        data-qa='filterType-item-thisWeek'
                                    >
                                        <ListItemText primary='This Week' />
                                    </ListItemButton>
                                </List>
                            </Hidden>
                            <Hidden breakpoint='md' direction='up'>
                                <FormControl variant='outlined' className={classes.formControl}>
                                    <InputLabel id='filterType-select-label'>Filter Date Range</InputLabel>
                                    <Select
                                        labelId='filterType-select-label'
                                        id='filterType-select'
                                        size='small'
                                        className={classes.filterSelect}
                                        value={selectedFilter}
                                        onChange={(event): void => {
                                            if (event.target.value === FilterType.Custom) {
                                                setDraftStartDate(TODAY);
                                                setDraftEndDate(TODAY);
                                                setSelectedFilter(FilterType.Custom);
                                            } else if (event.target.value === FilterType.Today || event.target.value === FilterType.Tomorrow ||
                                                event.target.value === FilterType.Yesterday || event.target.value === FilterType.ThisWeek) {
                                                handleChange(id, [event.target.value]);
                                                setSelectedFilter(event.target.value);
                                                setIsDateRangePickerOpen(false);
                                            }
                                        }}
                                        label='Filter Date Range'
                                        autoWidth
                                        inputProps={{
                                            'data-qa': 'filterType-input'
                                        }}
                                    >
                                        <MenuItem
                                            value={FilterType.Custom}
                                            data-qa='filterType-item-customDateRange-mobile'
                                        >
                                            Custom Date Range
                                        </MenuItem>
                                        <MenuItem
                                            value={FilterType.Today}
                                            data-qa='filterType-item-today-mobile'
                                        >
                                            Today
                                        </MenuItem>
                                        <MenuItem
                                            value={FilterType.Tomorrow}
                                            data-qa='filterType-item-tomorrow-mobile'
                                        >
                                            Tomorrow
                                        </MenuItem>
                                        <MenuItem
                                            value={FilterType.Yesterday}
                                            data-qa='filterType-item-yesterday-mobile'
                                        >
                                            Yesterday
                                        </MenuItem>
                                        <MenuItem
                                            value={FilterType.ThisWeek}
                                            data-qa='filterType-item-thisWeek-mobile'
                                        >
                                            This Week
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} md='auto'>
                            <Hidden breakpoint='xs'>
                                <DateRange
                                    minDate={selectedFilter !== FilterType.Custom ? TODAY : undefined}
                                    maxDate={selectedFilter !== FilterType.Custom ? subDays(TODAY, 1) : undefined}
                                    editableDateInputs={selectedFilter === FilterType.Custom}
                                    dateDisplayFormat={simpleInternationalizedDateFormat}
                                    ranges={[
                                        {
                                            startDate: draftStartDate,
                                            endDate: draftEndDate,
                                            key: id
                                        }
                                    ]}
                                    startDatePlaceholder=''
                                    endDatePlaceholder=''
                                    onChange={(newRangeObject: any): void => {
                                        setDraftStartDate(newRangeObject[id].startDate);
                                        setDraftEndDate(newRangeObject[id].endDate);
                                    }}
                                />
                            </Hidden>
                            <Hidden breakpoint='sm' direction='up'>
                                <DateRange
                                    minDate={selectedFilter !== FilterType.Custom ? TODAY : undefined}
                                    maxDate={selectedFilter !== FilterType.Custom ? subDays(TODAY, 1) : undefined}
                                    editableDateInputs={selectedFilter === FilterType.Custom}
                                    dateDisplayFormat={simpleInternationalizedDateFormat}
                                    months={2}
                                    direction='vertical'
                                    scroll={{ enabled: selectedFilter === FilterType.Custom }}
                                    ranges={[
                                        {
                                            startDate: draftStartDate,
                                            endDate: draftEndDate,
                                            key: id
                                        }
                                    ]}
                                    startDatePlaceholder=''
                                    endDatePlaceholder=''
                                    onChange={(newRangeObject: any): void => {
                                        setDraftStartDate(newRangeObject[id].startDate);
                                        setDraftEndDate(newRangeObject[id].endDate);
                                    }}
                                />
                            </Hidden>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(): void => {
                            setIsDateRangePickerOpen(false);
                            initializeDates();
                        }}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={(): void => {
                            if (selectedFilter === FilterType.Custom) {
                                const newStartDate = format(draftStartDate, simpleServerDateFormat);
                                const newEndDate = format(draftEndDate, simpleServerDateFormat);
                                handleChange(id, [`${newStartDate} - ${newEndDate}`]);
                            }
                            setIsDateRangePickerOpen(false);
                        }}
                        data-qa='ok-button'
                    >
                        OK
                    </Button>
                </DialogActions>
            </StyledDialog>
        </Fragment>
    );
};

export default FilterDateRangePicker;
