/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import {
    MapImages,
    MapSources,
    MapLayers,
    MapLayerTypes,
    ShipmentDetailsFeatureTypes
} from '../enums';
import { isVisibleUtil } from '../mapUtils';
import { MapLayer } from './interfaces';

/**
 * Creates a layer to display images on the map for each Point and 'featureType' of 'stop' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsStopsLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsStopsLayer,
        type: MapLayerTypes.Symbol,
        source: mapSource,
        layout: {
            'icon-image': '{image}',
            'icon-size': 0.5,
            'icon-offset': ['get', 'iconOffset'],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-field': '{title}',
            'text-font': ['Roboto Medium'],
            'text-offset': ['get', 'textOffset'],
            'text-anchor': 'top',
            'text-allow-overlap': true
        },
        paint: {
            'text-color': ['get', 'textColor']
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', ShipmentDetailsFeatureTypes.ShipmentStop]
        ]
    };

    return layer;
};

/**
 * Adds the shipment details stops layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addShipmentDetailsStopsLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.shipmentDetailsStopsLayer) === undefined) {
        const shipmentDetailsStopsLayer = getMapShipmentDetailsStopsLayerGeo(mapSource);
        map.addLayer(shipmentDetailsStopsLayer);
    }
};

/**
 * Creates a layer to display a geofence polygon fill on the map for each Polygon and 'featureType' of 'geofence' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsGeofenceFillLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsGeofenceFillLayer,
        type: MapLayerTypes.Fill,
        source: mapSource,
        paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.2
        },
        filter: ['all',
            ['==', '$type', 'Polygon'],
            ['==', 'featureType', ShipmentDetailsFeatureTypes.Geofence]
        ]
    };

    return layer;
};

/**
 * Creates a layer to display a geofence polygon outline on the map for each Polygon and 'featureType' of 'geofence' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsGeofenceOutlineLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsGeofenceOutlineLayer,
        type: MapLayerTypes.Line,
        source: mapSource,
        paint: {
            'line-color': ['get', 'outlineColor'],
            'line-width': 2
        },
        filter: ['all',
            ['==', '$type', 'Polygon'],
            ['==', 'featureType', ShipmentDetailsFeatureTypes.Geofence]
        ]
    };

    return layer;
};

/**
 * Adds the shipment details geofence fill layer to the map if it doesn't already exist.
 * Adds the shipment details geofence outline layer to the map if it doesn't already exist.
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addShipmentDetailsGeofenceLayers = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.shipmentDetailsGeofenceFillLayer) === undefined) {
        const shipmentDetailsGeofenceFillLayer = getMapShipmentDetailsGeofenceFillLayerGeo(mapSource);
        map.addLayer(shipmentDetailsGeofenceFillLayer);
    }
    if (map.getLayer(MapLayers.shipmentDetailsGeofenceOutlineLayer) === undefined) {
        const shipmentDetailsGeofenceOutlineLayer = getMapShipmentDetailsGeofenceOutlineLayerGeo(mapSource);
        map.addLayer(shipmentDetailsGeofenceOutlineLayer);
    }
};

/**
 * Creates a layer to display a line on the map for each LineString and 'featureType' of 'routeLine' in the given map source.
 * @param mapSource Name of the map source
 * @param isVisible Indicator for if the line should be visible
 */
const getMapShipmentDetailsRouteLayerGeo = (mapSource: MapSources, isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsRouteLayer,
        type: MapLayerTypes.Line,
        source: mapSource,
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
            visibility
        },
        paint: {
            'line-color': '#009AD9',
            'line-width': 6,
            'line-opacity': 0.8,
            'line-offset': 0
        },
        filter: ['all',
            ['==', '$type', 'LineString'],
            ['==', 'featureType', ShipmentDetailsFeatureTypes.RouteLine]
        ]
    };

    return layer;
};

/**
 * Adds the shipment details route layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 * @param isRouteVisible Indicator for if the route line should be visible
 */
export const addShipmentDetailsRouteLayer = (map: any, mapSource: MapSources, isRouteVisible: boolean): void => {
    if (map.getLayer(MapLayers.shipmentDetailsRouteLayer) === undefined) {
        const shipmentDetailsRouteLayer = getMapShipmentDetailsRouteLayerGeo(mapSource, isRouteVisible);
        map.addLayer(shipmentDetailsRouteLayer);
    }
};

/**
 * Creates a layer to display circles on the map for each Point and 'featureType' of 'pastPosition' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsPastPositionsLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsPastPositionsLayer,
        type: MapLayerTypes.Circle,
        source: mapSource,
        paint: {
            'circle-color': '#ffffff',
            'circle-opacity': 0.7,
            'circle-radius': 6,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#000000'
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', ShipmentDetailsFeatureTypes.PastPosition]
        ]
    };

    return layer;
};

/**
 * Adds the shipment details past positions layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addShipmentDetailsPastPositionsLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.shipmentDetailsPastPositionsLayer) === undefined) {
        const shipmentDetailsPastPositionsLayer = getMapShipmentDetailsPastPositionsLayerGeo(mapSource);
        map.addLayer(shipmentDetailsPastPositionsLayer);
    }
};

/**
 * Creates a layer to display a circle on the map for each Point and 'featureType' of 'currentPosition' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsCurrentPositionLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsCurrentPositionLayer,
        type: MapLayerTypes.Circle,
        source: mapSource,
        paint: {
            'circle-color': ['get', 'color'],
            'circle-opacity': 1,
            'circle-radius': 8,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#fff'
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', ShipmentDetailsFeatureTypes.CurrentPosition],
            ['==', 'hasPulse', false]
        ]
    };

    return layer;
};

/**
 * Adds the shipment details current position layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addShipmentDetailsCurrentPositionLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.shipmentDetailsCurrentPositionLayer) === undefined) {
        const shipmentDetailsCurrentPositionLayer = getMapShipmentDetailsCurrentPositionLayerGeo(mapSource);
        map.addLayer(shipmentDetailsCurrentPositionLayer);
    }
};

/**
 * Creates a layer to display a pulsing circle on the map for each Point and 'featureType' of 'currentPosition' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsCurrentPositionPulseLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsCurrentPositionPulseLayer,
        type: MapLayerTypes.Symbol,
        source: mapSource,
        layout: {
            'icon-image': MapImages.currentLocationMapMarker,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', ShipmentDetailsFeatureTypes.CurrentPosition],
            ['==', 'hasPulse', true]
        ]
    };

    return layer;
};

/**
 * Adds the shipment details current position pulse layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addShipmentDetailsCurrentPositionPulseLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.shipmentDetailsCurrentPositionPulseLayer) === undefined) {
        const shipmentDetailsCurrentPositionPulseLayer = getMapShipmentDetailsCurrentPositionPulseLayerGeo(mapSource);
        map.addLayer(shipmentDetailsCurrentPositionPulseLayer);
    }
};
