import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Card, CardContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { fetchShipmentDetailsAndRoute, fetchShipmentWeather } from '../../redux/shipmentDetails';
import OriginAndDestinationImage from '../../assets/images/trimbleMaps/markers_origindestination.png';
import IntermediateStopImage from '../../assets/images/trimbleMaps/markers_intermittentstops.png';
import ProgressLoader from '../loaders/progressLoader';
import ActionBar from '../actionBars/actionBar';
import ShipmentDetailsMap from '../maps/shipmentDetailsMap';
import ShipmentSummaryCard from '../cards/shipmentSummaryCard';
import ShipmentAttachmentsCard from '../cards/shipmentAttachmentsCard';
import ShipmentStopCard from '../cards/shipmentStopCard';

const classesPrefix = 'fullDetailsPage';

const classes = {
    pageWrapper: `${classesPrefix}-pageWrapper`,
    fullDetailsPageWrapper: `${classesPrefix}-fullDetailsPageWrapper`,
    returnLinkWrapper: `${classesPrefix}-returnLinkWrapper`,
    returnLink: `${classesPrefix}-returnLink`,
    card: `${classesPrefix}-card`,
    cardContentRoot: `${classesPrefix}-cardContentRoot`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.pageWrapper}`]: {
            backgroundColor: theme.palette.grey[300],
            padding: '20px 32px'
        },
        [`& .${classes.fullDetailsPageWrapper}`]: {
            width: '100%',
            maxWidth: '1400px',
            margin: '0 auto'
        },
        [`& .${classes.returnLinkWrapper}`]: {
            paddingBottom: '20px',
            display: 'flex',
            alignItems: 'center'
        },
        [`& .${classes.returnLink}`]: {
            textTransform: 'uppercase',
            fontSize: '14px',
            letterSpacing: '0.5px'
        },
        [`& .${classes.card}`]: {
            minHeight: '400px'
        },
        [`& .${classes.cardContentRoot}`]: {
            '&:last-child': {
                paddingBottom: '16px'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.pageWrapper}`]: {
                padding: '8px'
            }
        }
    };
});

const FullDetailsPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { shipmentId, freightHaulerConfigurationId } = useParams<{ shipmentId: string; freightHaulerConfigurationId: string; }>();

    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetailsData.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetailsData.shipment; });
    const weatherAlerts = useTypedSelector((state) => { return state.shipmentDetailsData.weatherAlerts; });
    const weatherPolygons = useTypedSelector((state) => { return state.shipmentDetailsData.weatherPolygons; });

    useEffect((): void => {
        dispatch(fetchShipmentDetailsAndRoute(shipmentId));
        dispatch(fetchShipmentWeather(shipmentId));
    }, [dispatch, shipmentId]);

    const isFetchingShipment = shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading;

    if (isFetchingShipment) {
        return (
            <ProgressLoader />
        );
    }

    return (
        <StyledDiv className={classes.pageWrapper}>
            <div className={classes.fullDetailsPageWrapper}>
                <section className={classes.returnLinkWrapper}>
                    <Link component={RouterLink} to={`/ShipmentList/${freightHaulerConfigurationId}`} aria-label='Back To Shipments'>
                        <IconButton color='primary' size='large'>
                            <Icon path={mdiArrowLeft} size={1} />
                        </IconButton>
                    </Link>
                    <Link component={RouterLink} className={classes.returnLink} to={`/ShipmentList/${freightHaulerConfigurationId}`}>Back To Shipments</Link>
                </section>
                <Grid container direction='row' spacing={2}>
                    <Grid item sm={12} md={5}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.card}>
                                    <CardContent
                                        classes={{
                                            root: classes.cardContentRoot
                                        }}
                                    >
                                        <ShipmentDetailsMap
                                            isFetchingData={isFetchingShipment}
                                            isRouteLineVisible={true}
                                            shipment={shipment}
                                            weatherAlerts={weatherAlerts}
                                            weatherPolygons={weatherPolygons}
                                            originAndDestinationImage={OriginAndDestinationImage}
                                            intermediateStopImage={IntermediateStopImage}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <ActionBar />
                            </Grid>
                            <Grid item xs={12}>
                                <ShipmentSummaryCard />
                            </Grid>
                            <Grid item xs={12}>
                                <ShipmentAttachmentsCard />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Grid container direction='column' spacing={2}>
                            {
                                shipment.stops.map((stop): JSX.Element => {
                                    return (
                                        <Grid key={stop.stopSequence} item xs={12}>
                                            <ShipmentStopCard
                                                shipmentStop={stop}
                                                shipmentUniqueName={shipment.shipmentUniqueName}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </StyledDiv>
    );
};

export default FullDetailsPage;
