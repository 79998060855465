/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types */
/**
 * Group an array of objects by a property within the objects, returns a new object with unique keys based on unique property values
 * @param objectArray Original array of objects
 * @param property value within the orignial object used to create keys for the new object
 */
export const groupBy = (objectArray: any[], property: string): any => {
    return objectArray.reduce(((acc, obj): any => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }), {});
};

/**
 * Uses the defaultObj to group an array of objects by a property, returns a new object defined by defaultObj keys
 * Only properties that exist in the defaultObj will be added to the returned object
 * @param objectArray Original array of objects
 * @param property value within the orignial object used to create keys for the new object
 * @param defaultObj the default object to add values
 */
export const groupByWithDefaultObject = (objectArray: any[], property: string, defaultObj: {}): any => {
    return objectArray.reduce((acc, obj): any => {
        const key = obj[property];
        if (acc[key]) {
            acc[key].push(obj);
        }
        return acc;
    }, defaultObj);
};

/**
 * Adds a space before each uppercase character in the string passed in
 * @param value Incoming string to format
 * @returns Formatted string with a space before each uppercase character
 */
export const addSpaceBeforeUppercaseCharacter = (value: string): string => {
    return value.replace(/([A-Z])/g, ' $1').trim();
};
