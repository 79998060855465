import * as Msal from 'msal';

const MsalAuthService: {
    userAgentApplication: Msal.UserAgentApplication | null;
    initialize: (clientID: string, authority: string, tokenReceivedCallback: (errorDesc: string, token: string, error: string, tokenType: string, userState: string) => void) => void;
    isAuthenticated: () => boolean;
    logout: () => void;
} = {
    userAgentApplication: null,
    initialize: (clientID, authority, tokenReceivedCallback): void => {
        const msalOptions = {
            // setting to true gets UnsupportedAuthorityValidation error
            validateAuthority: false,
            // causes infinite loop when set to true
            // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/498#issuecomment-449085405
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}`
        };

        MsalAuthService.userAgentApplication = new Msal.UserAgentApplication(clientID, authority, tokenReceivedCallback, msalOptions);
    },
    isAuthenticated: (): boolean => {
        //if the msal user is not null, the user is authenticated
        if (MsalAuthService?.userAgentApplication?.getUser() !== null) {
            return true;
        }
        // otherwise redirect to the login
        MsalAuthService.userAgentApplication.loginRedirect(['openid', 'offline_access']);
        return false;
    },
    logout(): void {
        if (MsalAuthService.userAgentApplication !== null) {
            // Logout B2C
            MsalAuthService.userAgentApplication.logout();
        }
    }
};

export default MsalAuthService;