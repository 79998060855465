import { AllowedFilterPropertyName } from '../helpers/enums';

export const INITIALIZE_FILTERS = 'INITIALIZE_FILTERS';
export const UPDATE_FILTER = 'UPDATE_FILTER';

// Mapped type with enum values as the key
export type FilterState = {
    [K in AllowedFilterPropertyName]: string[];
}

export interface InitializeFiltersAction {
    type: typeof INITIALIZE_FILTERS;
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER;
    payload: {
        filterId: AllowedFilterPropertyName;
        filterValues: string[];
    };
}

/** Interface used for a filter item. */
export interface FilterItem {
    /** Label to display */
    label: string;
    /** Value of the filter item */
    filterValue: string;
}

export const initialState: FilterState = {
    [AllowedFilterPropertyName.OriginAppointmentStartDateTime]: [],
    [AllowedFilterPropertyName.OriginAppointmentEndDateTime]: [],
    [AllowedFilterPropertyName.DestinationAppointmentStartDateTime]: [],
    [AllowedFilterPropertyName.DestinationAppointmentEndDateTime]: [],
    [AllowedFilterPropertyName.Origin]: [],
    [AllowedFilterPropertyName.Destination]: [],
    [AllowedFilterPropertyName.ShipmentStatus]: []
};