import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'progressLoader';

const classes = {
    progressWrapper: `${classesPrefix}-progressWrapper`
};

const StyledSection = styled('section')(() => {
    return {
        [`&.${classes.progressWrapper}`]: {
            position: 'absolute',
            outline: 'none',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)'
        }
    };
});

const ProgressLoader = (): JSX.Element => {
    return (
        <StyledSection className={classes.progressWrapper}>
            <CircularProgress />
        </StyledSection>
    );
};

export default ProgressLoader;
