import React, { Fragment, useState } from 'react';
import {
    ListItem,
    ListItemText,
    Collapse,
    Fab,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown, mdiChevronUp } from '@mdi/js';

import { CarrierBusinessUnit } from '../../interfaces/services/carrier';
import AddCarrierBusinessUnitUserDialog from '../dialogs/addCarrierBusinessUnitUserDialog';

const classesPrefix = 'carrierBusinessUnitListItem';

const classes = {
    root: `${classesPrefix}-root`
};

const StyledPaper = styled(Paper)(() => {
    return {
        [`&.${classes.root}`]: {
            padding: '10px',
            marginBottom: '10px',
            cursor: 'auto'
        }
    };
});

const CarrierBusinessUnitListItem = ({
    carrierBusinessUnit
}: {
    carrierBusinessUnit: CarrierBusinessUnit;
}): JSX.Element => {
    const [isListOpen, setIsListOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <Fragment>
            <ListItem onClick={(): void => { setIsListOpen(!isListOpen); }}>
                <ListItemText primary={carrierBusinessUnit.businessUnitName} />
                {
                    isListOpen
                        ? <Icon path={mdiChevronUp} size={1} />
                        : <Icon path={mdiChevronDown} size={1} />
                }
            </ListItem>
            <Collapse in={isListOpen} timeout='auto' unmountOnExit>
                <StyledPaper className={classes.root}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>
                                    <Fab
                                        color='primary'
                                        aria-label='Add User'
                                        size='small'
                                        onClick={(): void => { setIsDialogOpen(true); }}
                                    >
                                        <Icon path={mdiPlus} size={1} />
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                carrierBusinessUnit.userDetails.map((user): JSX.Element => {
                                    return (
                                        <TableRow key={user.userDetailGuid}>
                                            <TableCell component='th' scope='row'>{`${user.userFirstName} ${user.userLastName} `}</TableCell>
                                            <TableCell>{user.userEmailAddress}</TableCell>
                                            <TableCell colSpan={2}>{user.userPhoneNumber}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </StyledPaper>
            </Collapse>

            {
                isDialogOpen &&
                <AddCarrierBusinessUnitUserDialog
                    carrierBusinessUnitGuid={carrierBusinessUnit.carrierBusinessUnitGuid}
                    carrierBusinessUnitName={carrierBusinessUnit.businessUnitName}
                    isOpen={isDialogOpen}
                    closeDialog={() => { setIsDialogOpen(false); }}
                />
            }
        </Fragment>
    );

};

export default CarrierBusinessUnitListItem;
