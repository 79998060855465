import React, { useState } from 'react';
import {
    Grid,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { Stop } from '../../interfaces/services/shipmentDetails';
import { displayDateTimeFormat, zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { formatFullAddress } from '../../helpers/addressUtils';
import EditTimesDialog from '../dialogs/editTimesDialog';
import { StopType } from '../../helpers/enums';

const classesPrefix = 'shipmentStopCard';

const classes = {
    detailsWrapper: `${classesPrefix}-detailsWrapper`,
    dateWrapper: `${classesPrefix}-dateWrapper`
};

const StyledCard = styled(Card)(({ theme }) => {
    return {
        [`& .${classes.detailsWrapper}`]: {
            paddingTop: '20px'
        },
        [`& .${classes.dateWrapper}`]: {
            paddingTop: '4px'
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.dateWrapper}`]: {
                paddingTop: '8px'
            }
        }
    };
});

const ShipmentStopCard = ({
    shipmentStop,
    shipmentUniqueName
}: {
    shipmentStop: Stop;
    shipmentUniqueName: string;
}): JSX.Element => {
    const [isEditTimesDialogOpen, setIsEditTimesDialogOpen] = useState(false);

    const selectedShipper = useTypedSelector((state) => { return state.shipperListData.selectedShipper; });

    const getZonedDateTimeToDisplayRange = (startDateTime?: string | null, endDateTime?: string | null) => {
        // If there's not a startDateTime return --
        if (!startDateTime) {
            return '--';
        }

        // If there's not an endDateTime just return the startDateTime
        if (!endDateTime) {
            return zonedDateTimeToDisplay(startDateTime);
        }

        // If there's a startDateTime and endDateTime concatenate into range
        return `${zonedDateTimeToDisplay(startDateTime, displayDateTimeFormat)} - ${zonedDateTimeToDisplay(endDateTime)}`;
    };

    return (
        <StyledCard>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' color='textSecondary'>
                            {
                                (shipmentStop.stopType === StopType[StopType.Origin] || shipmentStop.stopType === StopType[StopType.Destination])
                                    ? shipmentStop.stopType
                                    : `Stop ${shipmentStop.stopSequence - 1}`
                            }
                        </Typography>
                        <Typography variant='h6'>{shipmentStop.name || shipmentStop.description || ''}</Typography>
                        <Typography variant='h6'>
                            {
                                formatFullAddress({
                                    street1: shipmentStop.address.streetAddress1,
                                    street2: shipmentStop.address.streetAddress2,
                                    city: shipmentStop.address.city,
                                    region: shipmentStop.address.state,
                                    postal: shipmentStop.address.postalCode,
                                    country: shipmentStop.address.countryCode
                                })
                            }
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.detailsWrapper}>
                    <Grid item xs={12} sm={6} className={classes.dateWrapper}>
                        <Typography variant='body2' color='textSecondary'>Appointment</Typography>
                        <Typography variant='body1'>
                            {
                                shipmentStop.appointment
                                    ? getZonedDateTimeToDisplayRange(shipmentStop.appointment.startTime, shipmentStop.appointment.endTime)
                                    : '--'
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.dateWrapper}>
                        <Typography variant='body2' color='textSecondary'>Scheduled</Typography>
                        <Typography variant='body1'>{getZonedDateTimeToDisplayRange(shipmentStop.scheduledStartDateTime, shipmentStop.scheduledEndDateTime)}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.dateWrapper}>
                        <Typography variant='body2' color='textSecondary'>Arrived</Typography>
                        <Typography variant='body1'>{getZonedDateTimeToDisplayRange(shipmentStop.actualArrivalDateTime)}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.dateWrapper}>
                        <Typography variant='body2' color='textSecondary'>Departed</Typography>
                        <Typography variant='body1'>{getZonedDateTimeToDisplayRange(shipmentStop.actualDepartureDateTime)}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button variant='outlined' color='primary' onClick={(): void => { setIsEditTimesDialogOpen(true); }}>Edit Times</Button>
                {
                    isEditTimesDialogOpen &&
                    <EditTimesDialog
                        shipmentUniqueName={shipmentUniqueName}
                        initialStopSequence={shipmentStop.stopSequence - 1}
                        carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                        carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                        isOpen={isEditTimesDialogOpen}
                        closeDialog={(): void => { setIsEditTimesDialogOpen(false); }}
                    />
                }
            </CardActions>
        </StyledCard>
    );
};

export default ShipmentStopCard;
