import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Autocomplete,
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiFileDocument } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { updatePageReset, uploadDocument } from '../../redux/dialogUpdates';
import { ApiStatus } from '../../helpers/enums';
import { documentTypeOptions } from '../../helpers/hardcodedOptionLists';
import { AcceptedFile } from '../../interfaces/componentInterfaces';
import CommonDialog from './commonDialog';
import LabelValuePair from '../labels/labelValuePair';
import SingleDocumentUploader from '../fileUploaders/singleDocumentUploader';

const classesPrefix = 'addDocumentDialog';

const classes = {
    fileSelectedContainer: `${classesPrefix}-fileSelectedContainer`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.fileSelectedContainer}`]: {
            margin: '16px 0'
        }
    };
});

const AddDocumentDialog = ({
    shipmentUniqueName,
    carrierIdentifierType,
    carrierIdentifier,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    carrierIdentifierType: string;
    carrierIdentifier: string;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [fileToUpload, setFileToUpload] = useState<AcceptedFile | null>(null);
    const [selectedDocumentType, setSelectedDocumentType] = useState<{ value: string; label: string; } | null>(null);
    const [documentUploadErrors, setDocumentUploadErrors] = useState<string[]>([]);

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    // Validate the document upload values and determine if form can be submitted
    useEffect((): void => {
        setCanSubmitForm(documentUploadErrors.length === 0 && fileToUpload !== null && selectedDocumentType !== null && carrierIdentifierType !== '');
    }, [documentUploadErrors, fileToUpload, selectedDocumentType, carrierIdentifierType]);

    // If the API update is successful, trigger the dialog to reset
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            setFileToUpload(null);
            setSelectedDocumentType(null);
        }
    }, [status, dispatch]);

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleUpload = (): void => {
        if (fileToUpload && selectedDocumentType) {
            const body = {
                shipmentUniqueName,
                carrierIdentifierType,
                carrierIdentifier,
                documentType: selectedDocumentType.value,
                fileName: fileToUpload.name,
                fileContent: fileToUpload.fileContent
            };
            dispatch(uploadDocument(body));
        }
    };

    const handleSelectChange = (selectedOption: { value: string; label: string; } | null): void => {
        setSelectedDocumentType(selectedOption);
    };

    const renderFileSelected = (): JSX.Element => {
        if (!fileToUpload) {
            return <LabelValuePair label='No File Selected' value='' data-qa='noFileSelected' />;
        }
        return <LabelValuePair label='File Selected' value={fileToUpload.name} data-qa='fileSelected' />;
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <StyledGrid container>
                <Grid item xs={12}>
                    <SingleDocumentUploader
                        disabled={status === ApiStatus.Loading}
                        handleDocumentsToUpload={(file: AcceptedFile | null): void => {
                            setFileToUpload(file);
                        }}
                        handleUploaderErrors={(errors: string[]): void => {
                            setDocumentUploadErrors(errors);
                        }}
                    />
                </Grid>
                <Grid item xs={12} className={classes.fileSelectedContainer}>
                    {renderFileSelected()}
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        size='small'
                        disabled={!fileToUpload || status === ApiStatus.Loading}
                        options={documentTypeOptions}
                        getOptionLabel={(option): string => { return option.label; }}
                        onChange={(event, newValue): void => {
                            handleSelectChange(newValue);
                        }}
                        value={selectedDocumentType}
                        renderInput={(params): JSX.Element => {
                            return (
                                <TextField
                                    {...params}
                                    label='Document Type'
                                    placeholder={selectedDocumentType?.value ? '' : 'Select...'}
                                    fullWidth={true}
                                    margin='normal'
                                    variant='outlined'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off',
                                        'data-qa': 'documentType-input'
                                    }}
                                />
                            );
                        }}
                        data-qa='documentType-select'
                    />
                </Grid>

                {
                    !carrierIdentifierType &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                    </Grid>
                }

                {
                    documentUploadErrors.map((error: string): JSX.Element => {
                        return (
                            <Grid item xs={12} key={error}>
                                <Typography variant='caption' color='error'>{error}</Typography>
                            </Grid>
                        );
                    })
                }

                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>{errorMessage}</Typography>
                    </Grid>
                }
            </StyledGrid>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerIcon={mdiFileDocument}
            headerText='Documents'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='close-button'
                    >
                        Close
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canSubmitForm}
                        onClick={handleUpload}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='upload-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Uploading' : 'Upload'
                        }
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default AddDocumentDialog;
