import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import MsalAuthService from '../../services/msalAuthService';
import { emptyReduxStore } from '../../redux';

const classesPrefix = 'authError';

const classes = {
    root: `${classesPrefix}-root`,
    errorMesssage: `${classesPrefix}-errorMesssage`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.root}`]: {
            margin: '16px'
        },
        [`& .${classes.errorMesssage}`]: {
            paddingTop: '16px',
            paddingBottom: '16px'
        }
    };
});

const AuthError = (): JSX.Element => {
    const dispatch = useDispatch();
    return (
        <StyledDiv className={classes.root}>
            <h2>Authentication Error</h2>
            <Typography className={classes.errorMesssage}>Your session may have expired. Please log out and log back in.</Typography>
            <Button
                variant='outlined'
                color='primary'
                onClick={(): void => {
                    dispatch(emptyReduxStore());
                    MsalAuthService.logout();
                }}
            >
                Log Out
            </Button>
        </StyledDiv>
    );
};

export default AuthError;
