export enum MapSources {
    // Custom added sources
    shipmentDetailsSource = 'shipment-details-source',
    shipmentDetailsWeatherSource = 'shipment-details-weather-source'
}

/** An enum containing the list of additional map layers used. */
export enum MapLayers {
    // Trimble Maps built-in layers
    europeTraffic = 'europe_traffic',
    northAmericaTraffic = 'north_america_traffic',

    // Custom added layers
    shipmentDetailsStopsLayer = 'shipment-details-stops-layer',
    shipmentDetailsGeofenceFillLayer = 'shipment-details-geofence-fill-layer',
    shipmentDetailsGeofenceOutlineLayer = 'shipment-details-geofence-outline-layer',
    shipmentDetailsRouteLayer = 'shipment-details-route-layer',
    shipmentDetailsCurrentPositionLayer = 'shipment-details-current-position-layer',
    shipmentDetailsCurrentPositionPulseLayer = 'shipment-details-current-position-pulse-layer',
    shipmentDetailsPastPositionsLayer = 'shipment-details-past-positions-layer',
    shipmentDetailsWeatherLayer = 'shipment-details-weather-layer',
    shipmentDetailsWeatherSelectedLayer = 'shipment-details-weather-selected-layer'
}

/** Contains list of allowed types for a map layer. */
export enum MapLayerTypes {
    Raster = 'raster',
    Circle = 'circle',
    Symbol = 'symbol',
    Line = 'line',
    Fill = 'fill'
}

export enum MapImages {
    originDestinationMapMarker = 'originDestinationMapMarker',
    stopMapMarker = 'stopMapMarker',
    currentLocationMapMarker = 'currentLocationMapMarker'
}

export enum ShipmentDetailsFeatureTypes {
    ShipmentStop = 'stop',
    Geofence = 'geofence',
    PastPosition = 'pastPosition',
    CurrentPosition = 'currentPosition',
    RouteLine = 'routeLine'
}
