import { ShipmentData } from '../../interfaces/services/shipmentDetails';
import {
    LinearUnit,
    ShipmentStatus,
    DeliveryStatus,
    WeatherAlertSeverity,
    ModeType
} from '../../helpers/enums';

export const initialShipmentDetailsState: ShipmentData = {
    // These fields come from the Shipment Details API
    createdDate: '',
    driver: null,
    equipmentType: null,
    equipmentSize: null,
    lastStatusUpdate: null,
    freightHauler: {
        name: '',
        identifiers: []
    },
    haulLength: 0,
    remainingHaulLength: 0,
    haulLinearUnitType: LinearUnit.Miles,
    isHazardous: false,
    shareGuid: null,
    shipmentStatus: ShipmentStatus.Pending,
    shipmentTrackingType: '',
    shipmentUniqueName: '',
    isPriorityShipment: false,
    modeType: ModeType.Unknown,
    minimumTemperature: null,
    maximumTemperature: null,
    deliveryStatus: DeliveryStatus.OnTime,
    mobileTrackingNumber: null,
    isMobileTrackingActiveFlag: false,
    tripId: null,
    weatherSeverity: WeatherAlertSeverity.Unknown,
    hasTemperatureExceededBoundsFlag: false,
    mobileTrackingStatus: '',
    tractorReferenceNumber: null,
    trailerReferenceNumber: null,
    freightProviderReferenceNumber: '',
    currentPosition: null,
    notes: [],
    documents: [],
    deliveryExceptions: [],
    stops: [],
    referenceNumbers: [],
    orders: [],
    shipmentMilestones: [],
    totalShipmentAndOrderExceptions: 0,

    // These fields come from the Shipment Details Route API
    breadcrumbPositions: [],
    plannedRoute: []
};
