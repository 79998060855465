import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiMapMarker } from '@mdi/js';
import { debounce } from 'lodash';

import ApiService from '../../services/apiService';
import { AlkSearchResponse } from '../../interfaces/services/alkSearch';
import { PositionResult } from '../../interfaces/componentInterfaces';

const classesPrefix = 'positionSelect';

const classes = {
    icon: `${classesPrefix}-icon`
};

const StyledLi = styled('li')(({ theme }) => ({
    [`& .${classes.icon}`]: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    }
}));

const PositionSelect = ({
    selectedValue,
    handleSelectedChange
}: {
    selectedValue: PositionResult | null;
    handleSelectedChange: (selectedValue: PositionResult | null) => void;
}): JSX.Element => {
    const [isFetching, setIsFetching] = useState(false);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<PositionResult[]>([]);
    const [inputValue, setInputValue] = useState('');

    const fetchLocations = useMemo(
        () =>
            debounce(async (request: { inputValue: string; }, callback: (results: PositionResult[]) => void): Promise<void> => {
                const data = await ApiService.get(`https://singlesearch.alk.com/WW/api/search?authToken=A413EAA4767EC44E94A2360AE03B8689&Query=${request.inputValue}`, '', false) as AlkSearchResponse;

                const alkGeocodeResults = data.Locations.map((location): PositionResult => {
                    return {
                        coordinates: location.Coords,
                        label: location.ShortString
                    };
                });
                callback(alkGeocodeResults);
            }, 200),
        []
    );

    useEffect(() => {
        let active = true;
        if (inputValue === '') {
            setIsFetching(false);
            setOptions(selectedValue ? [selectedValue] : []);
            return undefined;
        }

        fetchLocations({ inputValue }, (results: PositionResult[]): void => {
            if (active) {
                let newOptions: PositionResult[] = [];

                if (selectedValue) {
                    newOptions = [selectedValue];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [selectedValue, inputValue, fetchLocations]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option: PositionResult, value: PositionResult): boolean => {
                return option.label === value.label;
            }}
            getOptionLabel={(option: PositionResult): string => {
                return option.label;
            }}
            onChange={(event, newValue: PositionResult | null): void => {
                setOptions(newValue ? [newValue, ...options] : options);
                handleSelectedChange(newValue);
            }}
            onInputChange={(event, newInputValue: string): void => {
                setInputValue(newInputValue);
            }}
            options={options}
            loading={isFetching}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    label='Add a location'
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        )
                    }}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                        'data-qa': 'position-input'
                    }}
                />
            )}
            renderOption={(props, option: PositionResult): JSX.Element => {
                return (
                    <StyledLi {...props}>
                        <Grid container alignItems='center'>
                            <Grid item>
                                <Icon className={classes.icon} path={mdiMapMarker} size={1} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant='body1' color='textPrimary'>
                                    {option.label}
                                </Typography>
                            </Grid>
                        </Grid>
                    </StyledLi>
                );
            }}
            filterOptions={(options: PositionResult[]): PositionResult[] => {
                return options;
            }}
            autoComplete
            includeInputInList
            filterSelectedOptions
            data-qa='position-autocomplete'
        />
    );
};

export default PositionSelect;
