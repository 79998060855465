import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyleProps extends TypographyProps {
    fontSize: string;
}

const classesPrefix = 'labelValuePair';

const classes = {
    text: `${classesPrefix}-text`,
    displayData: `${classesPrefix}-displayData`
};

const StyledTypography = styled(Typography,
    {
        // This prevents passing the fontSize prop down to the Typography component
        shouldForwardProp: (prop) => { return prop !== 'fontSize'; }
    }
)<StyleProps>(({ fontSize }) => {
    return {
        [`&.${classes.text}`]: {
            fontWeight: 600,
            fontSize
        },
        [`& .${classes.displayData}`]: {
            fontWeight: 400,
            marginLeft: '4px',
            marginRight: '4px'
        }
    };
});

const LabelValuePair = ({
    label,
    value,
    'data-qa': dataQa,
    fontSize = '14px'
}: {
    /** The display label describing the data. */
    label: string;
    /** The data displayed in the UI. */
    value: string | number;
    /** data attribute for automation testing. */
    'data-qa': string;
    /** The size of the font. */
    fontSize?: string;
}): JSX.Element => {
    return (
        <StyledTypography className={classes.text} fontSize={fontSize} data-qa={`${dataQa}-label`}>
            {label}
            <span className={classes.displayData} data-qa={dataQa}>{value}</span>
        </StyledTypography>
    );
};

export default LabelValuePair;
