import { ShipmentStatus } from './enums';

export const documentTypeOptions = [
    { value: '1', label: 'Rate Confirmation' },
    { value: '2', label: 'Proof of Delivery' },
    { value: '3', label: 'Bill of Lading' },
    { value: '4', label: 'Invoice' },
    { value: '5', label: 'Freight Damage Documentation' },
    { value: '6', label: 'Truck/Trailer Damage Documentation' },
    { value: '7', label: 'Lumper Receipt' },
    { value: '8', label: 'Delivery Ticket / Receipt / Order' },
    { value: '9', label: 'Insurance' },
    { value: '10', label: 'Weight (Scale) Certificate' },
    { value: '11', label: 'Fine / Ticket' },
    { value: '12', label: 'DOT Inspection' },
    { value: '13', label: 'O/D Permit' },
    { value: '14', label: 'Directions' },
    { value: '15', label: 'Packing Slip' },
    { value: '16', label: 'Check' },
    { value: '17', label: 'Freight Bill' },
    { value: '18', label: 'Log' },
    { value: '19', label: 'Fuel Receipt' },
    { value: '20', label: 'Scale Receipt' },
    { value: '21', label: 'Trip Sheet' },
    { value: '22', label: 'Proof of Performance' },
    { value: '23', label: 'Match' },
    { value: '24', label: 'Other' },
    { value: '37', label: 'Signature Capture' }
];

export const shipmentStatusOptions = [
    { filterValue: ShipmentStatus.Pending, label: 'Pending' },
    { filterValue: ShipmentStatus.Dispatched, label: 'Dispatched' },
    { filterValue: ShipmentStatus.EnRoute, label: 'En Route' },
    { filterValue: ShipmentStatus.AtShipper, label: 'At Shipper' },
    { filterValue: ShipmentStatus.InTransit, label: 'In Transit' },
    { filterValue: ShipmentStatus.AtConsignee, label: 'At Consignee' },
    { filterValue: ShipmentStatus.Delivered, label: 'Delivered' },
    { filterValue: ShipmentStatus.Completed, label: 'Completed' },
    { filterValue: ShipmentStatus.Cancelled, label: 'Cancelled' }
];
