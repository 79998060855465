const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
const SET_TOKEN_EXCHANGE_PENDING = 'SET_TOKEN_EXCHANGE_PENDING';
const SET_TOKEN_EXCHANGE_SUCCEEDED = 'SET_TOKEN_EXCHANGE_SUCCEEDED';

interface SetIsAuthenticatedAction {
    type: typeof SET_IS_AUTHENTICATED;
    payload: boolean;
}

interface TokenExchangePendingAction {
    type: typeof SET_TOKEN_EXCHANGE_PENDING;
    payload: boolean;
}

interface TokenExchangeSucceededAction {
    type: typeof SET_TOKEN_EXCHANGE_SUCCEEDED;
    payload: boolean;
}

type AuthenticationActionTypes = SetIsAuthenticatedAction | TokenExchangePendingAction | TokenExchangeSucceededAction;

export const setIsAuthenticated = (isAuthenticated: boolean): SetIsAuthenticatedAction => {
    return {
        type: SET_IS_AUTHENTICATED,
        payload: isAuthenticated
    };
};

export const tokenExchangePending = (isPending: boolean): TokenExchangePendingAction => {
    return {
        type: SET_TOKEN_EXCHANGE_PENDING,
        payload: isPending
    };
};

export const tokenExchangeSucceeded = (hasSucceeded: boolean): TokenExchangeSucceededAction => {
    return {
        type: SET_TOKEN_EXCHANGE_SUCCEEDED,
        payload: hasSucceeded
    };
};

interface Authentication {
    isAuthenticated: boolean;
    isPending: boolean;
    hasSucceeded: boolean;
}

export const authenticationReducer = (authenticationState: Authentication = {
    isAuthenticated: false,
    isPending: true,
    hasSucceeded: false
}, action: AuthenticationActionTypes): Authentication => {
    switch (action.type) {
        case SET_IS_AUTHENTICATED: {
            return {
                ...authenticationState,
                isAuthenticated: action.payload
            };
        }
        case SET_TOKEN_EXCHANGE_PENDING: {
            return {
                ...authenticationState,
                isPending: action.payload
            };
        }
        case SET_TOKEN_EXCHANGE_SUCCEEDED: {
            return {
                ...authenticationState,
                hasSucceeded: action.payload
            };
        }
        default:
            return authenticationState;
    }
};
