import axios from 'axios';

const isUrlValid = (url: string): boolean => {
    if (url?.startsWith('http')) {
        return true;
    }

    console.error('Endpoint not found in service locator', url);
    return false;
};

const ApiService = {
    /**
     * Makes a GET call through axios
     * @param url URL of the API to call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async get(url: string, bearerToken: string, useBearerToken = true): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        const response = await axios({
            url,
            method: 'GET',
            metadata: {
                bearerToken,
                useBearerToken
            }
        });

        return response.data;
    },

    /**
     * Makes a PATCH call through axios
     * @param url URL of the API to call
     * @param patchBody JSON body of the PATCH API call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async patch(url: string, patchBody: unknown, bearerToken: string, useBearerToken = true): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        const response = await axios({
            url,
            method: 'PATCH',
            data: patchBody,
            metadata: {
                bearerToken,
                useBearerToken
            }
        });

        return response.data;
    },

    /**
     * Makes a POST call through axios
     * @param url URL of the API to call
     * @param postBody JSON body of the POST API call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async post(url: string, postBody: unknown, bearerToken: string, useBearerToken = true): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        const response = await axios({
            url,
            method: 'POST',
            data: postBody,
            metadata: {
                bearerToken,
                useBearerToken
            }
        });

        return response.data;
    }
};

export default ApiService;
