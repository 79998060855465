import { createTransform } from 'redux-persist';
import { ApiStatus } from '../helpers/enums';
import { RootState } from '.';

const TransformState = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: RootState) => {
        // return state as is
        return inboundState;
    },
    (outboundState: RootState, key) => {
        // all API statuses should be reset to initial idle status on re-hydrate
        switch (key) {
            case 'appConfig': {
                return {
                    ...outboundState,
                    status: ApiStatus.Idle
                };
            }
            case 'carrierListData': {
                return {
                    ...outboundState,
                    carrierListStatus: ApiStatus.Idle
                };
            }
            case 'dialogUpdates': {
                return {
                    ...outboundState,
                    // reset the error message as well just to make sure it isn't shown again
                    errorMessage: '',
                    status: ApiStatus.Idle
                };
            }
            case 'shipmentDetailsData': {
                return {
                    ...outboundState,
                    shipmentStatus: ApiStatus.Idle,
                    weatherStatus: ApiStatus.Idle
                };
            }
            case 'shipmentListData': {
                return {
                    ...outboundState,
                    shipmentListStatus: ApiStatus.Idle,
                    shipmentKeyStatus: ApiStatus.Idle
                };
            }
            case 'shipperListData': {
                return {
                    ...outboundState,
                    shipperListStatus: ApiStatus.Idle
                };
            }
            default:
                return outboundState;
        }
    },
    // define which reducers this transform gets called for.
    {
        whitelist: [
            'appConfig',
            // 'authentication',
            'carrierListData',
            'dialogUpdates',
            'shipmentDetailsData',
            'shipmentListData',
            'shipperListData'
            // 'userDetails'
        ]
    }
);

export default TransformState;
