import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';

const classesPrefix = 'commonDialog';

const classes = {
    iconContainer: `${classesPrefix}-iconContainer`,
    svgIcon: `${classesPrefix}-svgIcon`
};

const StyledDialog = styled(Dialog)(() => {
    return {
        [`& .${classes.iconContainer}`]: {
            marginRight: '8px'
        },
        [`& .${classes.svgIcon}`]: {
            verticalAlign: 'middle'
        }
    };
});

const CommonDialog = ({
    headerText,
    open,
    onClose,
    fullWidth,
    maxWidth,
    headerIcon,
    content,
    actions
}: {
    /** The text to show in the dialog header. */
    headerText: string;
    /** Indicator for if the dialog is open. */
    open: boolean;
    /** Function to handle the close of the dialog. */
    onClose: () => void;
    /** Indicator for the dialog to stretch to the maxWidth. Defaults to false. */
    fullWidth?: boolean;
    /** Determine the max-width of the dialog. Set to false to disable maxWidth. Defaults to 'sm'. */
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    /** Optional mdiIcon for the header. */
    headerIcon?: string;
    /** The elements to show in the Dialog Content */
    content?: React.ReactNode;
    /** The elements to show in the Dialog Actions  */
    actions?: React.ReactNode;
}): JSX.Element => {
    return (
        <StyledDialog
            open={open}
            onClose={(): void => { onClose(); }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            aria-labelledby={headerText}
            data-qa='dialog-container'
        >
            <DialogTitle>
                <Grid container alignItems='center'>
                    {
                        headerIcon &&
                        <Grid item xs='auto' className={classes.iconContainer}>
                            <Icon
                                className={classes.svgIcon}
                                path={headerIcon}
                                size={1}
                            />
                        </Grid>
                    }
                    <Grid item xs='auto' data-qa='dialog-header'>
                        {headerText}
                    </Grid>
                </Grid>
            </DialogTitle>
            {
                content &&
                <DialogContent dividers data-qa='dialog-content'>
                    {content}
                </DialogContent>
            }
            {
                actions &&
                <DialogActions>
                    {actions}
                </DialogActions>
            }
        </StyledDialog>
    );
};

export default CommonDialog;
