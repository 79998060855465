import React, { Fragment } from 'react';
import { List, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CarrierBusinessUnit } from '../../interfaces/services/carrier';
import CarrierBusinessUnitListItem from './carrierBusinessUnitListItem';

const classesPrefix = 'carrierBusinessUnitList';

const classes = {
    paperRoot: `${classesPrefix}-paperRoot`,
    mousePointer: `${classesPrefix}-mousePointer`
};

const StyledList = styled(List)(({ theme }) => {
    return {
        [`& .${classes.paperRoot}`]: {
            padding: '10px',
            margin: '0 10px 10px'
        },
        [`& .${classes.mousePointer}`]: {
            cursor: 'pointer'
        }
    };
});

const CarrierBusinessUnitList = ({
    carrierBusinessUnitList
}: {
    carrierBusinessUnitList: CarrierBusinessUnit[];
}): JSX.Element => {
    if (carrierBusinessUnitList) {
        const nestedlist = carrierBusinessUnitList.map((carrierBusinessUnit): JSX.Element => {
            return (
                <Paper key={carrierBusinessUnit.carrierBusinessUnitGuid} className={classes.paperRoot}>
                    <div className={classes.mousePointer}>
                        <CarrierBusinessUnitListItem carrierBusinessUnit={carrierBusinessUnit} />
                    </div>
                </Paper>
            );
        });

        return (
            <StyledList>
                {nestedlist}
            </StyledList>
        );
    }

    return <Fragment />;
};

export default CarrierBusinessUnitList;
