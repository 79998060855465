import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { AppThunk } from '..';
import { ApiStatus } from '../../helpers/enums';
import { ServiceLocatorEndpoints } from '../../interfaces/services/serviceLocator';

const SERVICE_LOCATOR_REQUEST = 'SERVICE_LOCATOR_REQUEST';
const SERVICE_LOCATOR_SUCCESS = 'SERVICE_LOCATOR_SUCCESS';
const SERVICE_LOCATOR_FAILURE = 'SERVICE_LOCATOR_FAILURE';

const SET_PUBLIC_API_URI = 'SET_PUBLIC_API_URI';
const SET_SHIPMENT_API_URI = 'SET_SHIPMENT_API_URI';
const SET_MASTER_DATA_API_URI = 'SET_MASTER_DATA_API_URI';

const initialEndpointsState = {
    authDirectory: '',
    dssUri: '',
    cpUri: '',
    publicApi: '',
    shipmentApi: '',
    masterDataApi: ''
};

interface AppConfig {
    status: ApiStatus;
    authDirectory: string;
    dssUri: string;
    cpUri: string;
    publicApi: string;
    shipmentApi: string;
    masterDataApi: string;
}

interface RequestServiceLocatorEndpointsAction {
    type: typeof SERVICE_LOCATOR_REQUEST;
}

interface ReceiveServiceLocatorEndpointsAction {
    type: typeof SERVICE_LOCATOR_SUCCESS;
    payload: ServiceLocatorEndpoints;
}

interface RequestServiceLocatorEndpointsFailedAction {
    type: typeof SERVICE_LOCATOR_FAILURE;
}

interface SetPublicApiUrlAction {
    type: typeof SET_PUBLIC_API_URI;
    payload: string;
}

interface SetShipmentApiUrlAction {
    type: typeof SET_SHIPMENT_API_URI;
    payload: string;
}

interface SetMasterDataApiUrlAction {
    type: typeof SET_MASTER_DATA_API_URI;
    payload: string;
}

type AppConfigActionTypes = RequestServiceLocatorEndpointsAction | ReceiveServiceLocatorEndpointsAction | RequestServiceLocatorEndpointsFailedAction |
    SetPublicApiUrlAction | SetShipmentApiUrlAction | SetMasterDataApiUrlAction;

const requestServiceLocatorEndpoints = (): RequestServiceLocatorEndpointsAction => {
    return {
        type: SERVICE_LOCATOR_REQUEST
    };
};

const receiveServiceLocatorEndpoints = (json: ServiceLocatorEndpoints): ReceiveServiceLocatorEndpointsAction => {
    return {
        type: SERVICE_LOCATOR_SUCCESS,
        payload: json
    };
};

const requestServiceLocatorEndpointsFailed = (): RequestServiceLocatorEndpointsFailedAction => {
    return {
        type: SERVICE_LOCATOR_FAILURE
    };
};

export const setPublicApiUri = (uri: string): SetPublicApiUrlAction => {
    return {
        type: SET_PUBLIC_API_URI,
        payload: uri
    };
};

export const setShipmentApiUri = (uri: string): SetShipmentApiUrlAction => {
    return {
        type: SET_SHIPMENT_API_URI,
        payload: uri
    };
};

export const setMasterDataApiUri = (uri: string): SetMasterDataApiUrlAction => {
    return {
        type: SET_MASTER_DATA_API_URI,
        payload: uri
    };
};

export const fetchServiceLocatorEndpoints = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(requestServiceLocatorEndpoints());

        try {
            const json = await axios.get('https://services.10-4.com/ResourceUrls') as AxiosResponse<ServiceLocatorEndpoints>;

            // Make sure we get something back from service locator and that AuthService is one of those since the app won't work without it
            if (Object.keys(json.data).length > 0 && json.data.CarrierPortalAuthDirectory) {
                dispatch(receiveServiceLocatorEndpoints(json.data));
            } else {
                throw new Error('Missing Service Locator Config');
            }
        } catch (err) {
            dispatch(requestServiceLocatorEndpointsFailed());
            toast.error('Error occurred while fetching Service Locator endpoints.');
        }
    };
};

export const appConfigReducer = (appConfig: AppConfig = {
    status: ApiStatus.Idle,
    ...initialEndpointsState
}, action: AppConfigActionTypes): AppConfig => {
    switch (action.type) {
        case SERVICE_LOCATOR_REQUEST: {
            return {
                ...appConfig,
                status: ApiStatus.Loading
            };
        }
        case SERVICE_LOCATOR_SUCCESS: {
            return {
                ...appConfig,
                status: ApiStatus.Success,
                cpUri: action.payload.CarrierPortalApi,
                dssUri: action.payload.DataSharingApi,
                authDirectory: action.payload.CarrierPortalAuthDirectory
            };
        }
        case SERVICE_LOCATOR_FAILURE: {
            return {
                status: ApiStatus.Failure,
                ...initialEndpointsState
            };
        }
        case SET_PUBLIC_API_URI: {
            return {
                ...appConfig,
                publicApi: action.payload
            };
        }
        case SET_SHIPMENT_API_URI: {
            return {
                ...appConfig,
                shipmentApi: action.payload
            };
        }
        case SET_MASTER_DATA_API_URI: {
            return {
                ...appConfig,
                masterDataApi: action.payload
            };
        }
        default:
            return appConfig;
    }
};
