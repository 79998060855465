import React from 'react';
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Tooltip,
    AccordionProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiWeatherLightningRainy, mdiChevronDown } from '@mdi/js';

import { WeatherAlert } from '../../interfaces/services/shipmentWeather';
import { WeatherAlertSeverity } from '../../helpers/enums';
import { getWeatherSeverityColor } from '../../helpers/styleHelpers';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import LabelValuePair from '../labels/labelValuePair';

interface StyleProps extends AccordionProps {
    severity: WeatherAlertSeverity;
}

const classesPrefix = 'weatherAlertCard';

const classes = {
    accordionWrapper: `${classesPrefix}-accordionWrapper`,
    statusIcon: `${classesPrefix}-statusIcon`,
    weatherSeverity: `${classesPrefix}-weatherSeverity`,
    alertIcon: `${classesPrefix}-alertIcon`,
    alertTitle: `${classesPrefix}-alertTitle`,
    dateWrapper: `${classesPrefix}-dateWrapper`,
    alertDetails: `${classesPrefix}-alertDetails`,
    alertSummaryTitle: `${classesPrefix}-alertSummaryTitle`,
    alertSummary: `${classesPrefix}-alertSummary`,
    alertUrlText: `${classesPrefix}-alertUrlText`
};

const StyledAccordion = styled(Accordion,
    {
        // This prevents passing the severity prop down to the Accordion component
        shouldForwardProp: (prop) => { return prop !== 'severity'; }
    }
)<StyleProps>(({ severity, theme }) => {
    return {
        [`&.${classes.accordionWrapper}`]: {
            '&:hover': {
                background: theme.palette.action.hover
            }
        },
        [`& .${classes.statusIcon}`]: {
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            marginRight: '4px'
        },
        [`& .${classes.weatherSeverity}`]: {
            backgroundColor: getWeatherSeverityColor(severity),
            color: theme.palette.common.white
        },
        [`& .${classes.alertIcon}`]: {
            padding: '4px'
        },
        [`& .${classes.alertTitle}`]: {
            fontSize: '14px'
        },
        [`& .${classes.dateWrapper}`]: {
            marginTop: '4px'
        },
        [`& .${classes.alertDetails}`]: {
            display: 'inline-block',
            width: 'calc(100% - 32px)'
        },
        [`& .${classes.alertSummaryTitle}`]: {
            fontSize: '12px',
            fontWeight: 600
        },
        [`& .${classes.alertSummary}`]: {
            marginLeft: '4px',
            fontWeight: 400,
            wordWrap: 'break-word'
        },
        [`& .${classes.alertUrlText}`]: {
            fontSize: '12px'
        }
    };
});

const WeatherAlertCard = ({
    alert,
    isExpanded,
    handleExpansionChange
}: {
    alert: WeatherAlert;
    isExpanded: boolean;
    handleExpansionChange?: (alert: WeatherAlert, isExpanded: boolean) => void;
}): JSX.Element => {
    return (
        <StyledAccordion
            className={classes.accordionWrapper}
            TransitionProps={{ unmountOnExit: true }}
            expanded={isExpanded}
            onChange={(event, expanded: boolean): void => {
                if (handleExpansionChange) {
                    handleExpansionChange(alert, expanded);
                }
            }}
            severity={alert.severity}
            data-qa={`${alert.recordId}-container`}
        >
            <AccordionSummary
                expandIcon={<Icon path={mdiChevronDown} size={1} />}
                aria-controls={`${alert.recordId}-content`}
                id={`${alert.recordId}-header`}
                data-qa={`${alert.recordId}-header`}
            >
                <Grid container alignItems='center'>
                    <Grid item xs='auto' className={`${classes.statusIcon} ${classes.weatherSeverity}`} data-qa='weatherSeverity-container'>
                        <Tooltip title={`${alert.severity} Weather Alert`}>
                            <Icon
                                className={classes.alertIcon}
                                path={mdiWeatherLightningRainy}
                                size='24px'
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className={classes.alertTitle} noWrap data-qa='alertEvent'>{alert.alertEvent}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.dateWrapper}>
                        <LabelValuePair label='Effective' value={zonedDateTimeToDisplay(alert.dispEffective)} fontSize='12px' data-qa='alertEffective' />
                        <LabelValuePair label='Expiration' value={zonedDateTimeToDisplay(alert.dispExpires)} fontSize='12px' data-qa='alertExpiration' />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.alertDetails}>
                <LabelValuePair label='Urgency' value={alert.urgency} fontSize='12px' data-qa='alertUrgency' />
                <LabelValuePair label='Certainty' value={alert.certainty} fontSize='12px' data-qa='alertCertainty' />
                <Typography className={classes.alertSummaryTitle}>
                    Summary
                    <span className={classes.alertSummary} data-qa='alertSummary'>{alert.summary}</span>
                </Typography>
                <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.alertUrlText}
                    href={alert.alertUrl}
                    data-qa='alertUrl'
                >
                    View Full Alert Details
                </Link>
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default WeatherAlertCard;
