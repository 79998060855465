import React, { Fragment, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    Grid,
    Tooltip,
    IconButton,
    Typography,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { WeatherAlert } from '../../interfaces/services/shipmentWeather';
import LabelValuePairLoading from '../loaders/labels/labelValuePairLoading';
import WeatherAlertCard from '../cards/weatherAlertCard';

const classesPrefix = 'weatherAlertsList';

const classes = {
    loadingIcon: `${classesPrefix}-loadingIcon`
};

const StyledAccordion = styled(Accordion)(() => {
    return {
        [`& .${classes.loadingIcon}`]: {
            marginRight: '4px'
        }
    };
});

const WeatherAlertsList = ({
    showAllAlerts,
    allWeatherAlerts,
    selectedCountyName,
    selectedWeatherAlerts,
    handleShowAllAlertsClick,
    handleAlertExpansionChange
}: {
    showAllAlerts: boolean;
    allWeatherAlerts: WeatherAlert[];
    selectedCountyName: string;
    selectedWeatherAlerts: WeatherAlert[];
    handleShowAllAlertsClick: () => void;
    handleAlertExpansionChange?: (alert: WeatherAlert, isExpanded: boolean) => void;
}): JSX.Element => {
    const [expandedAlertId, setExpandedAlertId] = useState(0);

    const weatherStatus = useTypedSelector((state) => { return state.shipmentDetailsData.weatherStatus; });

    if (weatherStatus === ApiStatus.Idle || weatherStatus === ApiStatus.Loading) {
        return (
            <Fragment>
                {
                    Array.from(new Array(3)).map((item, index): JSX.Element => {
                        return (
                            <StyledAccordion key={index}>
                                <AccordionSummary expandIcon={<Skeleton variant='circular' width={24} height={24} />}>
                                    <Grid container alignItems='center'>
                                        <Skeleton className={classes.loadingIcon} variant='circular' width='24px' height='24px' />
                                        <Skeleton variant='text' width='150px' />
                                        <LabelValuePairLoading label='Effective' fontSize='12px' width='130px' />
                                        <LabelValuePairLoading label='Expiration' fontSize='12px' width='130px' />
                                    </Grid>
                                </AccordionSummary>
                            </StyledAccordion>
                        );
                    })
                }
            </Fragment>
        );
    }

    return (
        <Fragment>
            {
                showAllAlerts ?
                    (
                        <Fragment>
                            {
                                allWeatherAlerts.map((alert) => {
                                    return (
                                        <WeatherAlertCard
                                            key={alert.recordId}
                                            alert={alert}
                                            isExpanded={alert.recordId === expandedAlertId}
                                            handleExpansionChange={(selectedAlert: WeatherAlert, isExpanded: boolean): void => {
                                                if (handleAlertExpansionChange) {
                                                    handleAlertExpansionChange(selectedAlert, isExpanded);
                                                }
                                                if (isExpanded) {
                                                    setExpandedAlertId(selectedAlert.recordId);
                                                } else {
                                                    setExpandedAlertId(0);
                                                }
                                            }}
                                        />
                                    );
                                })
                            }
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <Tooltip title='Back to all alerts'>
                                        <IconButton onClick={handleShowAllAlertsClick} data-qa='showAllAlerts-action'>
                                            <Icon path={mdiArrowLeft} size={1} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Typography>{selectedCountyName}</Typography>
                                </Grid>
                            </Grid>
                            {
                                selectedWeatherAlerts.map((alert) => {
                                    return (
                                        <WeatherAlertCard
                                            key={alert.recordId}
                                            alert={alert}
                                            isExpanded={alert.recordId === expandedAlertId}
                                            handleExpansionChange={(selectedAlert: WeatherAlert, isExpanded: boolean): void => {
                                                if (isExpanded) {
                                                    setExpandedAlertId(selectedAlert.recordId);
                                                } else {
                                                    setExpandedAlertId(0);
                                                }
                                            }}
                                        />
                                    );
                                })
                            }
                        </Fragment>
                    )
            }
        </Fragment>
    );
};

export default WeatherAlertsList;
