const SET_AUTH_DETAILS = 'SET_AUTH_DETAILS';

export interface AuthenticatedUserDetails {
    token: string;
    fullName: string;
    orgId: string[];
    businessUnit: string[];
    entitlements: string[];
}

interface SetAuthDetailsAction {
    type: typeof SET_AUTH_DETAILS;
    payload: AuthenticatedUserDetails;
}

type UserDetailsActionTypes = SetAuthDetailsAction;

export const setUserDetails = (data: AuthenticatedUserDetails): SetAuthDetailsAction => {
    return {
        type: SET_AUTH_DETAILS,
        payload: data
    };
};

export const userDetailsReducer = (userDetails: AuthenticatedUserDetails = {
    token: '',
    fullName: '',
    orgId: [],
    businessUnit: [],
    entitlements: []
}, action: UserDetailsActionTypes): AuthenticatedUserDetails => {
    switch (action.type) {
        case SET_AUTH_DETAILS: {
            return action.payload;
        }
        default:
            return userDetails;
    }
};
