import React, { useReducer, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { updateFilters } from '../../redux/shipments';
import { Filter } from '../../interfaces/services/shipment';
import {
    INITIALIZE_FILTERS,
    UPDATE_FILTER,
    FilterState,
    InitializeFiltersAction,
    UpdateFilterAction,
    initialState
} from '../../interfaces/filterInterfaces';
import { AllowedFilterPropertyName } from '../../helpers/enums';
import { shipmentStatusOptions } from '../../helpers/hardcodedOptionLists';
import FilterDateRangePicker from '../datePickers/filterDateRangePicker';
import AsyncCreatable from '../selects/asyncCreatable';
import MultiSelect from '../selects/multiSelect';

const classesPrefix = 'filters';

const classes = {
    filtersContainer: `${classesPrefix}-filtersContainer`,
    actionsContainer: `${classesPrefix}-actionsContainer`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.filtersContainer}`]: {
            flexGrow: 1,
            overflowY: 'auto',
            padding: '0 8px 8px'
        }
    };
});

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.actionsContainer}`]: {
            padding: '8px 16px',
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper
        }
    };
});

const Filters = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const filters = useTypedSelector((state) => { return state.shipmentListData.filters; });

    const reducer = (state: FilterState, action: InitializeFiltersAction | UpdateFilterAction): FilterState => {
        switch (action.type) {
            case UPDATE_FILTER: {
                return {
                    ...state,
                    [action.payload.filterId]: action.payload.filterValues
                };
            }
            case INITIALIZE_FILTERS: {
                return initialState;
            }
            default: {
                return state;
            }
        }
    };

    const [filterState, localDispatch] = useReducer(reducer, initialState);

    const updateFilterState = (filterId: AllowedFilterPropertyName, filterValues: string[]): void => {
        localDispatch({
            type: UPDATE_FILTER,
            payload: {
                filterId,
                filterValues
            }
        });
    };

    useEffect((): void => {
        // if the redux filters are empty, empty the local filter state
        if (filters.length === 0) {
            localDispatch({
                type: INITIALIZE_FILTERS
            });
        } else {
            filters.forEach((filter): void => {
                updateFilterState(filter.propertyName, filter.propertyValues);
            });
        }
    }, [filters]);

    const createFilterList = (): Filter[] => {
        const filterList = Object.entries(filterState).reduce((accumulator: Filter[], [filterName, filterValues]): Filter[] => {
            if (filterValues.length > 0) {
                accumulator.push({
                    propertyName: filterName as AllowedFilterPropertyName,
                    propertyValues: filterValues
                });
            }
            return accumulator;
        }, []);

        return filterList;
    };

    const handleResetClick = (): void => {
        localDispatch({
            type: INITIALIZE_FILTERS
        });
    };

    const handleApplyClick = (): void => {
        const newFilters = createFilterList();
        dispatch(updateFilters(newFilters));
    };

    return (
        <Fragment>
            <StyledDiv className={classes.filtersContainer} data-qa='filters-container'>
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.OriginAppointmentStartDateTime}
                    label={isMobile ? 'Origin Appt. Start' : 'Origin Appointment Start'}
                    currentValue={filterState[AllowedFilterPropertyName.OriginAppointmentStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.OriginAppointmentEndDateTime}
                    label={isMobile ? 'Origin Appt. End' : 'Origin Appointment End'}
                    currentValue={filterState[AllowedFilterPropertyName.OriginAppointmentEndDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DestinationAppointmentStartDateTime}
                    label={isMobile ? 'Destination Appt. Start' : 'Destination Appointment Start'}
                    currentValue={filterState[AllowedFilterPropertyName.DestinationAppointmentStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DestinationAppointmentEndDateTime}
                    label={isMobile ? 'Destination Appt. End' : 'Destination Appointment End'}
                    currentValue={filterState[AllowedFilterPropertyName.DestinationAppointmentEndDateTime]}
                    handleChange={updateFilterState}
                />

                <AsyncCreatable
                    id={AllowedFilterPropertyName.Origin}
                    label='Origin'
                    filterName='OriginDisplayfreightprovider'
                    values={filterState[AllowedFilterPropertyName.Origin]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Destination}
                    label='Destination'
                    filterName='DestinationDisplayfreightprovider'
                    values={filterState[AllowedFilterPropertyName.Destination]}
                    handleChange={updateFilterState}
                />

                <MultiSelect
                    id={AllowedFilterPropertyName.ShipmentStatus}
                    label='Shipment Status'
                    values={filterState[AllowedFilterPropertyName.ShipmentStatus]}
                    availableOptions={shipmentStatusOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
            </StyledDiv>

            <StyledGrid
                container
                justifyContent='space-between'
                className={classes.actionsContainer}
            >
                <Grid item>
                    <Button size='small' onClick={handleResetClick} data-qa='resetFilters-button'>Reset</Button>
                </Grid>
                <Grid item>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        onClick={handleApplyClick}
                        data-qa='applyFilters-button'
                    >
                        Apply
                    </Button>
                </Grid>
            </StyledGrid>
        </Fragment>
    );
};

export default Filters;
