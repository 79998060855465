import React, { useState } from 'react';
import {
    Grid,
    Button,
    Card,
    CardContent,
    CardActions,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { LinearUnit, ModeType } from '../../helpers/enums';
import { formattedPhoneNumber } from '../../helpers/phoneUtils';
import AddReferenceNumbersDialog from '../dialogs/addReferenceNumbersDialog';

const classesPrefix = 'shipmentSummaryCard';

const classes = {
    detailsWrapper: `${classesPrefix}-detailsWrapper`
};

const StyledCard = styled(Card)(() => {
    return {
        [`& .${classes.detailsWrapper}`]: {
            paddingTop: '24px'
        }
    };
});

const ShipmentSummaryCard = (): JSX.Element => {
    const [isAddReferenceDialogOpen, setIsAddReferenceDialogOpen] = useState(false);

    const shipment = useTypedSelector((state) => { return state.shipmentDetailsData.shipment; });
    const selectedShipper = useTypedSelector((state) => { return state.shipperListData.selectedShipper; });

    const getHaulLengthDisplay = (): string => {
        let haulLengthDisplay = '--';

        if (shipment.haulLength) {
            haulLengthDisplay = `${shipment.haulLength} ${shipment.haulLinearUnitType === LinearUnit.Kilometers ? 'km.' : 'mi.'}`;
        }

        return haulLengthDisplay;
    };

    return (
        <StyledCard>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' color='textSecondary'>Shipper REF #</Typography>
                        <Typography variant='h6'>
                            {shipment.freightProviderReferenceNumber}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.detailsWrapper}>
                    <Grid item xs={4}>
                        <Typography variant='body2' color='textSecondary'>System ID</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body1'>{shipment.shipmentUniqueName}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='body2' color='textSecondary'>Mode</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body1'>{ModeType[shipment.modeType]}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='body2' color='textSecondary'>Equipment</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body1'>{shipment.equipmentType}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='body2' color='textSecondary'>Length of Haul</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body1'>{getHaulLengthDisplay()}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='body2' color='textSecondary'>Driver Mobile</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body1'>{formattedPhoneNumber(shipment.mobileTrackingNumber)}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='body2' color='textSecondary'>Tractor #</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body1'>{shipment.tractorReferenceNumber}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='body2' color='textSecondary'>Trailer #</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body1'>{shipment.trailerReferenceNumber}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button variant='outlined' color='primary' onClick={(): void => { setIsAddReferenceDialogOpen(true); }}>Add Ref #</Button>
                {
                    isAddReferenceDialogOpen &&
                    <AddReferenceNumbersDialog
                        shipmentUniqueName={shipment.shipmentUniqueName}
                        carrierIdentifierType={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierType || ''}
                        carrierIdentifier={selectedShipper?.carrierIdentifiers[0]?.carrierIdentifierValue || ''}
                        initialTractorId={shipment.tractorReferenceNumber}
                        initialTrailerId={shipment.trailerReferenceNumber}
                        isOpen={isAddReferenceDialogOpen}
                        closeDialog={(): void => { setIsAddReferenceDialogOpen(false); }}
                    />
                }
            </CardActions>
        </StyledCard>
    );
};

export default ShipmentSummaryCard;
