import React from 'react';
import { Typography, TypographyProps, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyleProps extends TypographyProps {
    fontSize: string;
}

const classesPrefix = 'labelValuePairLoading';

const classes = {
    text: `${classesPrefix}-text`,
    inlineSkeleton: `${classesPrefix}-inlineSkeleton`
};

const StyledTypography = styled(Typography,
    {
        // This prevents passing the fontSize prop down to the Typography component
        shouldForwardProp: (prop) => { return prop !== 'fontSize'; }
    }
)<StyleProps>(({ fontSize }) => {
    return {
        [`&.${classes.text}`]: {
            fontWeight: 600,
            fontSize
        },
        [`& .${classes.inlineSkeleton}`]: {
            display: 'inline-block',
            marginLeft: '4px',
            marginRight: '4px'
        }
    };
});

const LabelValuePairLoading = ({
    label,
    width,
    fontSize = '14px'
}: {
    /** The display label describing the data. */
    label: string;
    /** Passed to the skeleton loader to change the length. */
    width: string;
    /** The size of the font. */
    fontSize?: string;
}): JSX.Element => {
    return (
        <StyledTypography className={classes.text} fontSize={fontSize}>
            {label}
            <Skeleton className={classes.inlineSkeleton} variant='text' width={width} />
        </StyledTypography>
    );
};

export default LabelValuePairLoading;
