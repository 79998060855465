import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { updateSelectedShipper } from '../../redux/shipper';
import forklift from '../../assets/images/forklift.jpg';
import ShipperSelect from '../selects/shipperSelect';

const classesPrefix = 'landingPage';

const classes = {
    sectionWrapper: `${classesPrefix}-sectionWrapper`,
    root: `${classesPrefix}-root`,
    selectShipperDropdownWrapper: `${classesPrefix}-selectShipperDropdownWrapper`,
    selectShipperLogo: `${classesPrefix}-selectShipperLogo`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.sectionWrapper}`]: {
            textAlign: 'center',
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto'
        },
        [`& .${classes.root}`]: {
            paddingTop: '32px',
            paddingBottom: '16px'
        },
        [`& .${classes.selectShipperDropdownWrapper}`]: {
            margin: '16px 100px'
        },
        [`& .${classes.selectShipperLogo}`]: {
            width: '95%',
            maxWidth: '600px',
            marginTop: '16px'
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.selectShipperDropdownWrapper}`]: {
                margin: '16px 10px'
            }
        }
    };
});

const LandingPage = (): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateSelectedShipper(null));
    }, [dispatch]);

    return (
        <StyledDiv className={classes.sectionWrapper}>
            <section>
                <Typography
                    variant='subtitle1'
                    classes={{
                        root: classes.root
                    }}
                >
                    Please select a Shipper to view &amp; manage your shipments
                </Typography>
            </section>
            <section className={classes.selectShipperDropdownWrapper}>
                <ShipperSelect />
            </section>
            <section>
                <img className={classes.selectShipperLogo} src={forklift} alt='Forklift loading pallets' />
            </section>
        </StyledDiv>
    );

};

export default LandingPage;
