import { toast } from 'react-toastify';

import { AppThunk } from '..';
import { ApiStatus, PublicDocumentType, DocumentType } from '../../helpers/enums';
import { determineCarrierIdentifier } from '../../helpers/apiUtils';
import { simpleJavascriptDateTimeFormat } from '../../helpers/dateUtils';
import ApiService from '../../services/apiService';
import { GenericApiResponse } from '../../interfaces/services';
import {
    MobileTrackingUpdateResponse,
    UploadDocumentServiceResponse,
    UploadDocumentResponse,
    UpdateStopTimesRequest,
    ShipmentMovementServiceResponse,
    ShipmentMovementResponse
} from '../../interfaces/services/shipmentUpdates';

const UPDATE_PAGE_RESET = 'UPDATE_PAGE_RESET';
const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
const UPDATE_PAGE_FAILURE = 'UPDATE_PAGE_FAILURE';
export const UPDATE_TRACTOR_TRAILER_SUCCESS = 'UPDATE_TRACTOR_TRAILER_SUCCESS';
export const UPDATE_MOBILE_TRACKING_SUCCESS = 'UPDATE_MOBILE_TRACKING_SUCCESS';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPDATE_STOP_TIMES_SUCCESS = 'UPDATE_STOP_TIMES_SUCCESS';

const initialState = {
    status: ApiStatus.Idle,
    errorMessage: ''
};

interface ResetPageUpdateAction {
    type: typeof UPDATE_PAGE_RESET;
}

interface RequestPageUpdateAction {
    type: typeof UPDATE_PAGE_REQUEST;
}

interface ReceivePageUpdateAction {
    type: typeof UPDATE_PAGE_SUCCESS;
}

interface RequestPageUpdateFailedAction {
    type: typeof UPDATE_PAGE_FAILURE;
    payload: string;
}

export interface ReceiveTractorTrailerUpdateAction {
    type: typeof UPDATE_TRACTOR_TRAILER_SUCCESS;
    payload: ShipmentMovementResponse;
}

export interface ReceiveMobileTrackingUpdateAction {
    type: typeof UPDATE_MOBILE_TRACKING_SUCCESS;
    payload: MobileTrackingUpdateResponse;
}

export interface ReceiveUploadDocumentAction {
    type: typeof UPLOAD_DOCUMENT_SUCCESS;
    payload: UploadDocumentResponse;
}

export interface ReceiveStopTimesUpdateAction {
    type: typeof UPDATE_STOP_TIMES_SUCCESS;
    payload: ShipmentMovementResponse;
}

type DialogUpdatesActionTypes = ResetPageUpdateAction | RequestPageUpdateAction | ReceivePageUpdateAction | RequestPageUpdateFailedAction;

interface DialogUpdates {
    status: ApiStatus;
    errorMessage: string;
}

export const updatePageReset = (): ResetPageUpdateAction => {
    return {
        type: UPDATE_PAGE_RESET
    };
};

export const updatePageRequest = (): RequestPageUpdateAction => {
    return {
        type: UPDATE_PAGE_REQUEST
    };
};

export const updatePageSuccess = (): ReceivePageUpdateAction => {
    return {
        type: UPDATE_PAGE_SUCCESS
    };
};

export const updatePageFailed = (error: string): RequestPageUpdateFailedAction => {
    return {
        type: UPDATE_PAGE_FAILURE,
        payload: error
    };
};

const updateTractorTrailerSuccess = (json: ShipmentMovementResponse): ReceiveTractorTrailerUpdateAction => {
    return {
        type: UPDATE_TRACTOR_TRAILER_SUCCESS,
        payload: json
    };
};

const updateMobileTrackingSuccess = (json: MobileTrackingUpdateResponse): ReceiveMobileTrackingUpdateAction => {
    return {
        type: UPDATE_MOBILE_TRACKING_SUCCESS,
        payload: json
    };
};

const uploadDocumentSuccess = (json: UploadDocumentResponse): ReceiveUploadDocumentAction => {
    return {
        type: UPLOAD_DOCUMENT_SUCCESS,
        payload: json
    };
};

const updateStopTimesSuccess = (json: ShipmentMovementResponse): ReceiveStopTimesUpdateAction => {
    return {
        type: UPDATE_STOP_TIMES_SUCCESS,
        payload: json
    };
};

export const updateTractorTrailer = ({
    shipmentUniqueName,
    carrierIdentifierType,
    carrierIdentifier,
    tractorId,
    trailerId
}: {
    shipmentUniqueName: string;
    carrierIdentifierType: string;
    carrierIdentifier: string;
    tractorId: string | null;
    trailerId: string | null;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const carrierIdentifierKey = determineCarrierIdentifier(carrierIdentifierType);

        const body = {
            tenFourLicensePlate: shipmentUniqueName,
            [carrierIdentifierKey]: carrierIdentifier,
            assets: {
                tractorReferenceNumber: tractorId,
                trailerReferenceNumber: trailerId
            }
        };

        try {
            const json = await ApiService.post(`${getState().appConfig.shipmentApi}/v1/shipmentMovement`, body, '', false) as GenericApiResponse<ShipmentMovementResponse>;
            dispatch(updateTractorTrailerSuccess(json.data[0]));
            dispatch(updatePageSuccess());
            toast.success('Tractor and Trailer ID\'s updated successfully.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating Tractor and Trailer ID\'s.'));
            }
        }
    };
};

export const updateMobileTracking = ({
    shipmentUniqueName,
    carrierIdentifierType,
    carrierIdentifier,
    driverPhoneNumber
}: {
    shipmentUniqueName: string;
    carrierIdentifierType: string;
    carrierIdentifier: string;
    driverPhoneNumber: string;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const carrierIdentifierKey = determineCarrierIdentifier(carrierIdentifierType);

        const body = {
            [carrierIdentifierKey]: carrierIdentifier,
            TenFourLicensePlate: shipmentUniqueName,
            DriverPhoneNumber: driverPhoneNumber,
            ShipmentTrackingType: 4
        };

        try {
            const json = await ApiService.post(`${getState().appConfig.publicApi}/shipment/track/`, body, '', false);
            if (json === true) {
                dispatch(updateMobileTrackingSuccess({
                    shipmentUniqueName,
                    driverPhoneNumber
                }));
                dispatch(updatePageSuccess());
                toast.success('Mobile tracking number updated successfully.');
            } else {
                throw new Error('Phone Number did not update');
            }
        } catch (error) {
            if (error?.response?.data?.Errors?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.Errors.forEach(({ ErrorMessage }: { ErrorMessage: string; }): void => {
                    dispatch(updatePageFailed(ErrorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating mobile tracking.'));
            }
        }
    };
};

export const uploadDocument = ({
    shipmentUniqueName,
    carrierIdentifierType,
    carrierIdentifier,
    documentType,
    fileName,
    fileContent
}: {
    shipmentUniqueName: string;
    carrierIdentifierType: string;
    carrierIdentifier: string;
    documentType: string;
    fileName: string;
    fileContent: string;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const carrierIdentifierKey = determineCarrierIdentifier(carrierIdentifierType);

        const body = {
            [carrierIdentifierKey]: carrierIdentifier,
            TenFourLicensePlate: shipmentUniqueName,
            DocumentType: documentType,
            FileName: fileName,
            FileContent: fileContent
        };

        try {
            const json = await ApiService.post(`${getState().appConfig.publicApi}/shipment/document/upload/`, body, '', false) as UploadDocumentServiceResponse;
            dispatch(uploadDocumentSuccess({
                shipmentUniqueName,
                document: {
                    documentID: json.DocumentId,
                    documentLink: json.DocumentRetrievalToken,
                    documentTypeName: json.DocumentTypeName,
                    documentType: PublicDocumentType[json.DocumentTypeId] as DocumentType,
                    uploadDate: json.UploadDate,
                    userName: json.UploadUserName
                }
            }));
            dispatch(updatePageSuccess());
            toast.success('Document successfully uploaded.');
        } catch (error) {
            if (error?.response?.data?.Errors?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.Errors.forEach(({ ErrorMessage }: { ErrorMessage: string; }): void => {
                    console.error('Document upload error: ', ErrorMessage);
                });
            }
            dispatch(updatePageFailed('Document failed to upload.'));
        }

    };
};

export const updateStopTimes = (
    body: UpdateStopTimesRequest,
    carrierIdentifierType: string,
    carrierIdentifier: string
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const carrierIdentifierKey = determineCarrierIdentifier(carrierIdentifierType);

        const apiBody = {
            [carrierIdentifierKey]: carrierIdentifier,
            ...body
        };

        try {
            // TODO: remove once movement api can handle arrival and departure stop times correctly.
            // if it is a departure or arrival it must have a reportTime and a positionEventTypeId and will call the public api
            if (body.reportTime && body.positionEventTypeId) {
                await ApiService.patch(`${getState().appConfig.publicApi}/shipment/stopstatus`, apiBody, '', false);
                dispatch(updateStopTimesSuccess({
                    tenFourLicensePlate: body.tenFourLicensePlate,
                    reportedDate: simpleJavascriptDateTimeFormat(body.reportTime),
                    positionEventType: body.positionEventTypeId === 1 ? 'Arrived' : 'Departed',
                    stopSequence: body.stopSequence
                }));
            } else {
                const json = await ApiService.post(`${getState().appConfig.shipmentApi}/v1/shipmentMovement`, apiBody, '', false) as GenericApiResponse<ShipmentMovementServiceResponse>;
                dispatch(updateStopTimesSuccess({
                    stopSequence: body.stopSequence,
                    ...json.data[0]
                }));
            }
            dispatch(updatePageSuccess());
            toast.success('Stop times updated successfully.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating stop times.'));
            }
        }
    };
};

export const addPositionEvent = ({
    carrierIdentifierType,
    carrierIdentifier,
    shipmentUniqueName,
    latitude,
    longitude,
    positionEventType,
    positionReportTime
}: {
    carrierIdentifierType: string;
    carrierIdentifier: string;
    shipmentUniqueName: string;
    latitude: string;
    longitude: string;
    positionEventType: number;
    positionReportTime: string | null;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const carrierIdentifierKey = determineCarrierIdentifier(carrierIdentifierType);

        const body = {
            [carrierIdentifierKey]: carrierIdentifier,
            TenFourLicensePlate: shipmentUniqueName,
            Latitude: latitude,
            Longitude: longitude,
            PositionEventType: positionEventType, // 3 is in transit
            PositionReportTime: positionReportTime
        };

        try {
            const json = await ApiService.post(`${getState().appConfig.publicApi}/shipment/position`, body, '', false) as boolean;
            if (json === true) {
                dispatch(updatePageSuccess());
                toast.success('Position event was successfully added.');
            } else {
                console.error('Position Event API did not return true:', json);
                dispatch(updatePageFailed('There was an error adding the position event.'));
            }
        } catch (error) {
            if (error?.response?.data?.Errors?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.Errors.forEach(({ ErrorMessage }: { ErrorMessage: string; }): void => {
                    console.error('Failed to add position event: ', ErrorMessage);
                });
            }
            dispatch(updatePageFailed('Position event failed to process.'));
        }
    };
};

export const dialogUpdatesReducer = (state: DialogUpdates = initialState, action: DialogUpdatesActionTypes): DialogUpdates => {
    switch (action.type) {
        case UPDATE_PAGE_RESET: {
            return {
                ...initialState
            };
        }
        case UPDATE_PAGE_REQUEST: {
            return {
                status: ApiStatus.Loading,
                errorMessage: ''
            };
        }
        case UPDATE_PAGE_FAILURE: {
            return {
                status: ApiStatus.Failure,
                errorMessage: action.payload
            };
        }
        case UPDATE_PAGE_SUCCESS: {
            return {
                status: ApiStatus.Success,
                errorMessage: ''
            };
        }
        default:
            return state;
    }
};
