import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import jwtDecode from 'jwt-decode';

import './assets/CSS/persistGateLoader.css';
import App from './App';
import createAppStore from './redux/store';
import { ApiStatus } from './helpers/enums';
import { tokenExchangePending, tokenExchangeSucceeded, setIsAuthenticated } from './redux/authentication';
import { setUserDetails, AuthenticatedUserDetails } from './redux/userDetails';
import MsalAuthService from './services/msalAuthService';

interface DecodedAuthToken {
    'CP/BusinessUnits': string[];
    'CP/OrgIds': string[];
    entitlements: string[];
    family_name: string;
    given_name: string;
    oid: string;
}

const { persistor, store } = createAppStore();

// Initialize the AuthService, setup history and interceptors before the gate lifts
const onBeforeLift = async (): Promise<void> => {
    await new Promise(async (resolve): Promise<void> => {
        /* eslint-disable consistent-return */
        const unsubscribe = store.subscribe(async (): Promise<void> => {
            const { authDirectory, status } = store.getState().appConfig;
            // check if the service locator call has completed
            if (status !== ApiStatus.Idle && status !== ApiStatus.Loading) {
                // Once we have fetched the endpoints from SL, we no longer want to subscribe to the change listener
                unsubscribe();
                // callback function for redirect flow
                const tokenReceivedCallback = async (errorDesc: string, b2cToken: string): Promise<void> => {
                    if (b2cToken) {
                        const { cpUri } = store.getState().appConfig;
                        const options = {
                            method: 'GET',
                            headers: {
                                Authorization: `Bearer ${b2cToken}`
                            }
                        };

                        // exchange b2c token for bearer token we can use to hit apis
                        await fetch(`${cpUri}/v1/auth/token`, options)
                            .then((response) => { // success
                                if (response.status === 200) {
                                    return response.json();
                                } else if (response.status === 401) {
                                    // 401 means authentication failed on server
                                    console.error('Failed to authorize user while attempting the token exchange.');
                                    store.dispatch(setIsAuthenticated(false));
                                    store.dispatch(tokenExchangePending(false));
                                } else {
                                    // dispatch an action to set token exchange pending
                                    console.error('Token exchange failed');
                                    store.dispatch(tokenExchangePending(false));
                                }
                                // After dispatching any errors from above, the promise should be resolved, so that it's not left hanging.
                                return Promise.resolve();
                            }, (error): void => { // error
                                // dispatch an action to set token exchange pending
                                console.error('Azure sign in failed', error);
                                store.dispatch(tokenExchangePending(false));
                            })
                            .then((bearerToken): void => {
                                if (bearerToken !== undefined && bearerToken !== null && bearerToken.data.length > 0) {
                                    const decodedToken: DecodedAuthToken = jwtDecode(bearerToken.data[0].token);

                                    const userDetails: AuthenticatedUserDetails = {
                                        token: bearerToken.data[0].token,
                                        fullName: `${decodedToken.given_name} ${decodedToken.family_name}`,
                                        orgId: decodedToken['CP/OrgIds'],
                                        businessUnit: decodedToken['CP/BusinessUnits'],
                                        entitlements: decodedToken['entitlements']
                                    };
                                    // set user details
                                    store.dispatch(setUserDetails(userDetails));
                                    // set isAuthenticated to true
                                    store.dispatch(setIsAuthenticated(true));
                                    // tokenExchange has completed
                                    store.dispatch(tokenExchangePending(false));
                                    // the exchange was successful
                                    store.dispatch(tokenExchangeSucceeded(true));
                                } else {
                                    // dispatch an action to set token exchange pending
                                    store.dispatch(tokenExchangePending(false));
                                    console.error('Bearer Token not available');
                                }
                            });
                    }
                };

                // we are using '@' as a delimiter to seperate clientID and tenant name
                const b2cDataArray = authDirectory.split('@');

                // initialize msal auth login
                MsalAuthService.initialize(b2cDataArray[0], `https://${b2cDataArray[1]}.b2clogin.com/tfp/${b2cDataArray[1]}.onmicrosoft.com/B2C_1_SignIn/`, tokenReceivedCallback);
                // check if we have a user, if we dont the redirect is automagically triggered
                if (MsalAuthService.isAuthenticated() === true) {
                    // lift the gate
                    return resolve(true);
                }
            }
        });
        /* eslint-enable consistent-return */
    });
};

const container = document.getElementById('carrierportal-root');
// Using non-null assertion is the explicitly defined way to use createRoot with TS
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <PersistGate
            loading={<div className='loader' />}
            persistor={persistor}
            onBeforeLift={onBeforeLift}
        >
            <App />
        </PersistGate>
    </Provider>
);
