import { createTheme, ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: '"Open Sans", Roboto, Helvetica, Arial, sans-serif'
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#1976D2'
        },
        secondary: {
            main: '#AEAEB6'
        }
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#00437b'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: '#00437b',
                    color: '#fff'
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit'
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 600
                }
            },
            defaultProps: {
                underline: 'hover'
            }
        },
        // this override prevents weird blue outline on time picker in Safari browser
        MuiPaper: {
            defaultProps: {
                sx: {
                    '& *:focus': {
                        outline: 'none'
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '16px'
                },
                body: {
                    fontSize: '13px'
                }
            }
        }
    }
};

const muiTheme = createTheme(themeOptions);

export default muiTheme;
