import { toast } from 'react-toastify';

import { AppThunk } from '..';
import { ApiStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import { GenericApiResponse } from '../../interfaces/services';
import {
    updatePageRequest,
    updatePageFailed,
    updatePageSuccess
} from '../dialogUpdates';
import { Carrier, CarrierBusinessUnit, UserDetails } from '../../interfaces/services/carrier';

const CARRIER_LIST_REQUEST = 'CARRIER_LIST_REQUEST';
const CARRIER_LIST_SUCCESS = 'CARRIER_LIST_SUCCESS';
const CARRIER_LIST_FAILURE = 'CARRIER_LIST_FAILURE';
const CARRIER_LIST_ADD_USER_SUCCESS = 'CARRIER_LIST_ADD_USER_SUCCESS';

interface CarrierListData {
    carrierListStatus: ApiStatus;
    carrierList: Carrier[];
}

interface RequestCarriersAction {
    type: typeof CARRIER_LIST_REQUEST;
}

interface ReceiveCarriersAction {
    type: typeof CARRIER_LIST_SUCCESS;
    payload: Carrier[];
}

interface RequestCarriersFailedAction {
    type: typeof CARRIER_LIST_FAILURE;
}

interface AddUsersToCbuAction {
    type: typeof CARRIER_LIST_ADD_USER_SUCCESS;
    payload: CarrierBusinessUnit[];
}

type CarrierActionTypes = RequestCarriersAction | ReceiveCarriersAction | RequestCarriersFailedAction |
    AddUsersToCbuAction;

const requestCarriers = (): RequestCarriersAction => {
    return {
        type: CARRIER_LIST_REQUEST
    };
};

const receiveCarriers = (json: Carrier[]): ReceiveCarriersAction => {
    return {
        type: CARRIER_LIST_SUCCESS,
        payload: json
    };
};

const requestCarriersFailed = (): RequestCarriersFailedAction => {
    return {
        type: CARRIER_LIST_FAILURE
    };
};

export const fetchCarriers = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestCarriers());
        const { userDetails, appConfig } = getState();

        try {
            const json = await ApiService.get(`${appConfig.cpUri}/v1/Carriers`, userDetails.token) as GenericApiResponse<Carrier[]>;
            dispatch(receiveCarriers(json.data[0]));
        } catch (err) {
            dispatch(requestCarriersFailed());
            toast.error('Unable to get carrier list.');
            dispatch(updatePageFailed('Unable to get carrier list.'));
        }
    };
};

const addUsersToCbuSuccess = (json: CarrierBusinessUnit[]): AddUsersToCbuAction => {
    return {
        type: CARRIER_LIST_ADD_USER_SUCCESS,
        payload: json
    };
};

// This method adds a user to the supplied business unit.
export const addUserToCarrierBusinessUnit = (carrierBusinessUnit: string, usertoSave: UserDetails): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());
        const { userDetails, appConfig } = getState();

        const userDetailsToSave = {
            userDetails: [usertoSave]
        };

        try {
            const json = await ApiService.post(`${appConfig.cpUri}/v1/Carriers/CarrierBusinessUnits/${carrierBusinessUnit}/Users`, userDetailsToSave, userDetails.token) as GenericApiResponse<CarrierBusinessUnit>;
            dispatch(addUsersToCbuSuccess(json.data));
            dispatch(updatePageSuccess());
            toast.success('User Added!');
        } catch (error) {
            if (error?.messages?.length > 0) {
                dispatch(updatePageFailed(error.messages[0]));
            } else if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                dispatch(updatePageFailed(error.response.data.messages[0]));
            } else {
                dispatch(updatePageFailed('There was an issue adding the user.'));
            }
        }
    };
};

export const fetchCarriersReducer = (carrierListData: CarrierListData = {
    carrierListStatus: ApiStatus.Idle,
    carrierList: []
}, action: CarrierActionTypes): CarrierListData => {
    switch (action.type) {
        case CARRIER_LIST_REQUEST: {
            return {
                ...carrierListData,
                carrierListStatus: ApiStatus.Loading
            };
        }
        case CARRIER_LIST_SUCCESS: {
            return {
                ...carrierListData,
                carrierListStatus: ApiStatus.Success,
                carrierList: action.payload
            };
        }
        case CARRIER_LIST_FAILURE: {
            return {
                ...carrierListData,
                carrierListStatus: ApiStatus.Failure,
                carrierList: []
            };
        }
        case CARRIER_LIST_ADD_USER_SUCCESS: {
            const payload = action.payload[0];

            return {
                ...carrierListData,
                carrierList: carrierListData.carrierList.map((carrier): Carrier => {
                    const updatedCarrierBusinessUnit = carrier.carrierBusinessUnit.map((businessUnit): CarrierBusinessUnit => {
                        if (businessUnit.carrierBusinessUnitGuid === payload.carrierBusinessUnitGuid) {
                            return payload;
                        }
                        return businessUnit;
                    });

                    return {
                        ...carrier,
                        carrierBusinessUnit: updatedCarrierBusinessUnit
                    };
                })
            };
        }
        default:
            return carrierListData;
    }
};
