import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import httpService from './services/httpService';
import appTheme from './assets/theme';
import { useTypedSelector } from './redux';
import { fetchShippers } from './redux/shipper';
import { ApiStatus } from './helpers/enums';
import ErrorBoundary from './components/errors/errorBoundary';
import ProgressLoader from './components/loaders/progressLoader';
import AppHeader from './components/headers/appHeader';
import AuthError from './components/errors/authError';
import ShippersError from './components/errors/shippersError';
import LandingPage from './components/pages/landingPage';
import ShipmentList from './components/pages/shipmentList';
import UserManagerPage from './components/pages/userManagerPage';
import FullDetailsPage from './components/pages/fullDetailsPage';

import './assets/CSS/reactToastify.css';

// README: Important! React-router uses history v4.10.1 under the hood. Updating the history package will break things.
const history = createBrowserHistory();

const App = (): JSX.Element => {
    const dispatch = useDispatch();
    httpService.setupInterceptors(history);

    const isTokenExchangePending = useTypedSelector((state) => { return state.authentication.isPending; });
    const hasTokenExchangeSucceeded = useTypedSelector((state) => { return state.authentication.hasSucceeded; });
    const isAuthenticated = useTypedSelector((state) => { return state.authentication.isAuthenticated; });
    const shipperListStatus = useTypedSelector((state) => { return state.shipperListData.shipperListStatus; });
    const shipperList = useTypedSelector((state) => { return state.shipperListData.shipperList; });

    useEffect((): void => {
        if (isAuthenticated && hasTokenExchangeSucceeded) {
            dispatch(fetchShippers());
        }
    }, [dispatch, isAuthenticated, hasTokenExchangeSucceeded]);

    const renderBody = (): JSX.Element => {
        // if the token exchange is pending, show loader
        if (isTokenExchangePending === true || shipperListStatus === ApiStatus.Idle || shipperListStatus === ApiStatus.Loading) {
            return (
                <Fragment>
                    <AppHeader
                        renderFullHeader={false}
                    />
                    <ProgressLoader />
                </Fragment>
            );
            // if the token exchange failed or if the user is not authenticated
        } else if (hasTokenExchangeSucceeded === false || isAuthenticated === false) {
            return (
                <Fragment>
                    <AppHeader
                        renderFullHeader={false}
                    />
                    <AuthError />
                </Fragment>
            );
        } else if (shipperListStatus === ApiStatus.Failure) {
            return (
                <Fragment>
                    <AppHeader
                        renderFullHeader={false}
                    />
                    <ShippersError />
                </Fragment>
            );
        }
        return (
            <Router history={history}>
                <AppHeader
                    renderFullHeader={true}
                />
                <Switch>
                    {
                        shipperList.length === 1 &&
                        <Redirect
                            exact
                            from='/'
                            to={`/ShipmentList/${shipperList[0].freightHaulerConfigurationID}`}
                        />
                    }
                    <Route exact path='/'>
                        <ErrorBoundary>
                            <LandingPage />
                        </ErrorBoundary>
                    </Route>
                    <Route exact path='/ShipmentList/:freightHaulerConfigurationId'>
                        <ErrorBoundary>
                            <ShipmentList />
                        </ErrorBoundary>
                    </Route>
                    <Route exact path='/FullDetailsPage/:freightHaulerConfigurationId/:shipmentId'>
                        <ErrorBoundary>
                            <FullDetailsPage />
                        </ErrorBoundary>
                    </Route>
                    <Route exact path='/UserManagerPage'>
                        <ErrorBoundary>
                            <UserManagerPage />
                        </ErrorBoundary>
                    </Route>
                    <Route exact path='/notAuthorized'>
                        <ErrorBoundary>
                            <AuthError />
                        </ErrorBoundary>
                    </Route>
                </Switch>
            </Router>
        );
    };

    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <ToastContainer position='bottom-center' theme='colored' />
            {renderBody()}
        </ThemeProvider>
    );
};

export default App;
