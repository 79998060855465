/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Chip, TextField, Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import { useTypedSelector } from '../../redux';
import ApiService from '../../services/apiService';
import { FilterDataResponse } from '../../interfaces/services/masterData';
import { AllowedFilterPropertyName } from '../../helpers/enums';

const classesPrefix = 'asyncCreatable';

const classes = {
    autocomplete: `${classesPrefix}-autocomplete`,
    formControl: `${classesPrefix}-formControl`,
    formLabelShrink: `${classesPrefix}-formLabelShrink`
};

const StyledAutocomplete = styled(Autocomplete)(() => {
    return {
        [`&.${classes.autocomplete}`]: {
            marginTop: '16px',
            marginBottom: '8px',
            '& + .MuiAutocomplete-popperDisablePortal': {
                position: 'static',
                '& .MuiAutocomplete-listbox': {
                    maxHeight: 'none'
                }
            }
        },
        [`& .${classes.formControl}`]: {
            margin: 0
        },
        [`& .${classes.formLabelShrink}`]: {
            top: '-4px'
        }
    };
}) as typeof Autocomplete;

const AsyncCreatable = ({
    id,
    label,
    filterName,
    values,
    handleChange,
    disabled
}: {
    id: AllowedFilterPropertyName;
    label: string;
    filterName: string;
    values: string[];
    handleChange: (id: AllowedFilterPropertyName, selectedOptions: string[]) => void;
    disabled?: boolean;
}): JSX.Element => {
    const MAX_ROW_COUNT = 5;
    const filter = createFilterOptions();

    const [open, setOpen] = useState(false);
    const [allOptions, setAllOptions] = useState<string[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const masterDataApi = useTypedSelector((state) => { return state.appConfig.masterDataApi; });
    const apiKey = useTypedSelector((state) => { return state.shipmentListData.apiKey; });

    const valueAlreadyExists = (valueToCompare: string): boolean => {
        return values.map((value): string => {
            return value.toLowerCase();
        }).includes(valueToCompare.toLowerCase());
    };

    const fetchShipmentFilterData = debounce((inputValue: string): void => {
        // Don't fetch if the inputValue is empty or the value already exist
        if (inputValue.trim() === '' || valueAlreadyExists(inputValue) === true) {
            setIsFetching(false);
            setAllOptions(values);
        } else {
            const queryString = new URLSearchParams({
                rows: MAX_ROW_COUNT.toString(),
                searchTerm: inputValue
            });

            ApiService.get(`${masterDataApi}/api/v1/filters/${filterName}?${queryString.toString()}`, apiKey)
                .then((response): void => {
                    const { filterData, status } = response as FilterDataResponse;
                    if (status === 202) {
                        toast.warn('Filter data is being compiled, type ahead filters will not work at the moment - please try again shortly.');
                    } else {
                        const newFilterList = filterData.map((filterItem): string => {
                            return filterItem.filterValue;
                        });
                        // If we already have selected values add them with the filters because
                        // MUI requires you to track all options that are being used and your potential options or you get errors
                        // https://github.com/mui-org/material-ui/issues/18514#issuecomment-607271896
                        const opts = values.length > 0 ? [...values, ...newFilterList] : newFilterList;
                        setAllOptions(opts);
                    }
                }).catch((): void => {
                    toast.error(`An error occurred retrieving ${label} options.`);
                }).finally((): void => {
                    setIsFetching(false);
                });
        }
    }, 200);

    return (
        <StyledAutocomplete
            id={id}
            className={classes.autocomplete}
            options={allOptions}
            value={values}
            loading={isFetching}
            open={open}
            getOptionLabel={(option): string => {
                return option;
            }}
            isOptionEqualToValue={(option, testValue): boolean => {
                // test if the option is selected
                return testValue === option;
            }}
            onChange={(e, newValues: any[], reason): void => {
                setIsFetching(false);

                const selectedOptions = newValues.map((newValue): string => {
                    // Most options other than createables come through as string, so just return the string as is
                    // However, when creating an option and hitting enter, it will also come through as a string
                    if (typeof newValue === 'string') {
                        return newValue;
                    }

                    // This should only be hit when it's a "creatable" option (by not hitting enter and selecting it from the option list) where we pushed an object
                    return newValue.filterValue;
                });

                // select-option is only triggered with an selection from allOptions, populated from fetchShipmentFilterData function
                if (reason === 'selectOption') {
                    // sets the selected values displayed in the autocomplete
                    handleChange(id, selectedOptions);
                } else if (reason === 'createOption' || reason === 'removeOption' || reason === 'clear') {
                    // sets the selected values displayed in the autocomplete
                    handleChange(id, selectedOptions);
                    // MUI requires you to track all options that are being used or you get errors
                    // https://github.com/mui-org/material-ui/issues/18514#issuecomment-607271896
                    setAllOptions(selectedOptions);
                }
            }}
            onInputChange={(event, newInputValue): void => {
                // set is fetching outside of fetchShipmentFilterData to avoid lag from being debounced
                setIsFetching(true);
                fetchShipmentFilterData(newInputValue);
            }}
            renderOption={(props, option: any): JSX.Element => {
                return (
                    <li {...props}>
                        {/* Show the create option text */}
                        {option.createOption ? option.createOption : option}
                    </li>
                );
            }}
            renderTags={(tags, getTagProps): JSX.Element[] => {
                return tags.map((option, index): JSX.Element => {
                    const alphaNumericOption = option.replace(/[^A-Za-z0-9]/g, '').toLowerCase();
                    return (
                        <Chip
                            {...getTagProps({ index })}
                            key={alphaNumericOption}
                            variant='outlined'
                            label={option}
                            data-qa={`${alphaNumericOption}-chip`}
                        />
                    );
                });
            }}
            renderInput={(params): JSX.Element => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={values.length > 0 ? '' : 'Type to Search...'}
                        fullWidth
                        margin='normal'
                        variant='filled'
                        classes={{
                            root: classes.formControl
                        }}
                        InputLabelProps={{
                            classes: {
                                shrink: classes.formLabelShrink
                            }
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            'data-qa': `${id}-input`
                        }}
                    />
                );
            }}
            filterOptions={(options, params: any): any[] => {
                const filtered = filter(options, params);
                if (isFetching) {
                    return filtered;
                }

                // Suggest the creation of a new value
                if (params.inputValue.trim() !== '' && valueAlreadyExists(params.inputValue) === false) {
                    filtered.push({
                        filterValue: params.inputValue,
                        createOption: `Create "${params.inputValue}"`
                    });
                }
                return filtered;
            }}
            onOpen={(): void => {
                setOpen(true);
                setAllOptions(values);
            }}
            onClose={(): void => {
                setOpen(false);
            }}
            disabled={disabled}
            fullWidth
            multiple
            freeSolo
            clearOnBlur
            filterSelectedOptions
            openOnFocus
            disablePortal
            data-qa={`${id}-autocomplete`}
        />
    );
};

export default AsyncCreatable;
