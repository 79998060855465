// NOTE: Do not replace with FreightHaulerIdentifierTypeName enum, the datashare api is inconsistent so we use an obj lookup.
export const determineCarrierIdentifier = (carrierIdentifierType: string): string => {
    //create lookup obj to change from what is in the shipperListData to what the api is going to expect
    const carrierIdentifierTypes: {
        [carrierIdentifier: string]: string;
    } = {
        scaccode: 'CarrierSCAC',
        usdotnumber: 'CarrierDOT',
        mcnumber: 'CarrierMc'
    };

    //look up the key the api is expecting from the carrierIdentifierTypes lookup obj
    if (carrierIdentifierTypes[carrierIdentifierType.toLowerCase()]) {
        return carrierIdentifierTypes[carrierIdentifierType.toLowerCase()];
    }

    // if it's not one of the above values, intentionally return a bad value to fail the API
    console.error('Unable to determine carrier identifier');
    return 'CarrierInvalid';
};

export const toQueryString = (paramsObject: Record<string, unknown>): string => {
    return Object.keys(paramsObject).map((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return `${encodeURIComponent(key)}=${encodeURIComponent((paramsObject as any)[key])}`;
    }).join('&');
};
