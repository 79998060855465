import axios, { AxiosRequestConfig } from 'axios';
import { History } from 'history';
import { publicRoutes } from '../routes/appRouteList';

const notLoggedInError = 'User is not logged in';

const httpService = {
    // we pass the history in order to navigate to the not authorized page
    // if the user is not authenticated or a 401 is returned from a service
    setupInterceptors: (history: History<unknown>): void => {
        axios.interceptors.request.use(async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
            // If we don't have metadata then we don't need the Interceptor, so we return the request as is
            if (!request.metadata) {
                return request;
            }

            let headers: {
                Accept: string;
                'Content-Type': string;
                Authorization?: string;
            } = {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            };

            headers = {
                ...headers,
                ...request.metadata.useBearerToken && { Authorization: `Bearer ${request.metadata.bearerToken}` }
            };
            request.headers = headers;
            return request;
        }, (error): Promise<never> => {
            // if any errors occur as part of the request interceptor, we will just go to the not authorized page
            history.push(publicRoutes.notAuthorized);
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            // simply return the response if there is no error
            return response;
        }, (error): Promise<never> => {
            // if the error contains the string from the request interceptor above, then redirect to the login page to re-authenticate
            if (error.toString().includes(notLoggedInError) === true) {
                history.push(publicRoutes.root);
            }

            // first check to make sure we should go through the error handling
            if (error?.config?.metadata) {
                // if any of the service calls returns a 401, redirect to the not authorized page
                if (error?.response?.status === 401) {
                    // send the user to the not authorized page since the user/token is not valid
                    history.push(publicRoutes.notAuthorized);
                }
            }

            // just let the calling action handle the error instead if the handler is not enabled
            return Promise.reject(error);
        });
    }
};

export default httpService;
