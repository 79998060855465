import { DeliveryStatus, WeatherAlertSeverity } from './enums';

export const getDeliveryStatusColor = (status: DeliveryStatus): string => {
    switch (status) {
        case DeliveryStatus.TenderAccepted:
            return '#bdbdbd';
        case DeliveryStatus.Early:
            return '#0091ea';
        case DeliveryStatus.OnTime:
            return '#00c853';
        case DeliveryStatus.InJeopardy:
            return '#ffab00';
        case DeliveryStatus.Late:
            return '#d50000';
        case DeliveryStatus.TrackingLost:
        default:
            return '#000';
    }
};

export const getWeatherSeverityColor = (weatherSeverity: WeatherAlertSeverity | null): string => {
    switch (weatherSeverity) {
        case WeatherAlertSeverity.Severe:
            return '#CC060C';
        case WeatherAlertSeverity.Moderate:
            return '#F6821F';
        case WeatherAlertSeverity.Minor:
            return '#FEB913';
        case WeatherAlertSeverity.Unknown:
        default:
            return '#bdbdbd';
    }
};
