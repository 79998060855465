import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress
} from '@mui/material';
import { mdiNumeric } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { updatePageReset, updateTractorTrailer } from '../../redux/dialogUpdates';
import CommonDialog from './commonDialog';

const AddReferenceNumbersDialog = ({
    shipmentUniqueName,
    carrierIdentifierType,
    carrierIdentifier,
    initialTractorId,
    initialTrailerId,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    carrierIdentifierType: string;
    carrierIdentifier: string;
    initialTractorId: string | null;
    initialTrailerId: string | null;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [draftTractorId, setDraftTractorId] = useState<string | null>(null);
    const [draftTrailerId, setDraftTrailerId] = useState<string | null>(null);

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    // Update local draft value if prop changes
    useEffect((): void => {
        setDraftTractorId(initialTractorId || null);
    }, [initialTractorId]);

    // Update local draft value if prop changes
    useEffect((): void => {
        setDraftTrailerId(initialTrailerId || null);
    }, [initialTrailerId]);

    // Validate draft tractor/trailer values and determine if form can be submitted
    useEffect((): void => {
        const hasTractorIdChanged = draftTractorId !== initialTractorId;
        const hasTrailerIdChanged = draftTrailerId !== initialTrailerId;
        setCanSubmitForm((hasTractorIdChanged || hasTrailerIdChanged) && carrierIdentifierType !== '');
    }, [draftTractorId, initialTractorId, draftTrailerId, initialTrailerId, carrierIdentifierType]);

    // If the API update is successful, trigger the dialog to reset and close
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            closeDialog();
        }
    }, [status, dispatch, closeDialog]);

    const handleTractorIdChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setDraftTractorId(e.currentTarget.value !== '' ? e.currentTarget.value : null);
    };

    const handleTrailerIdChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setDraftTrailerId(e.currentTarget.value !== '' ? e.currentTarget.value : null);
    };

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        dispatch(updateTractorTrailer({
            shipmentUniqueName,
            carrierIdentifier,
            carrierIdentifierType,
            tractorId: draftTractorId,
            trailerId: draftTrailerId
        }));
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label='Tractor #'
                        margin='none'
                        type='text'
                        variant='outlined'
                        value={draftTractorId || ''}
                        onChange={handleTractorIdChange}
                        fullWidth
                        inputProps={{
                            maxLength: 50,
                            autoComplete: 'off',
                            'data-qa': 'tractorId-input'
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label='Trailer #'
                        margin='none'
                        type='text'
                        variant='outlined'
                        value={draftTrailerId || ''}
                        onChange={handleTrailerIdChange}
                        fullWidth
                        inputProps={{
                            maxLength: 50,
                            autoComplete: 'off',
                            'data-qa': 'trailerId-input'
                        }}
                    />
                </Grid>

                {
                    !carrierIdentifierType &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                    </Grid>
                }

                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>{errorMessage}</Typography>
                    </Grid>
                }
            </Grid>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerIcon={mdiNumeric}
            headerText='Add Reference Numbers'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canSubmitForm}
                        onClick={handleSave}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default AddReferenceNumbersDialog;
