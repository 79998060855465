import React from 'react';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import RoadSurfaceLegend from './roadSurfaceLegend';

const classesPrefix = 'shipmentsMapLegendWrapper';

const classes = {
    legendsWrapper: `${classesPrefix}-legendsWrapper`,
    legendPaperWrapper: `${classesPrefix}-legendPaperWrapper`,
    roadSurfaceLegendWrapper: `${classesPrefix}-roadSurfaceLegendWrapper`,
    deliveryStatusWrapper: `${classesPrefix}-deliveryStatusWrapper`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.legendsWrapper}`]: {
            position: 'absolute',
            right: '52px',
            top: '8px',
            zIndex: 40
        },
        [`& .${classes.legendPaperWrapper}`]: {
            padding: '8px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
        },
        [`& .${classes.roadSurfaceLegendWrapper}`]: {
            width: '150px'
        },
        [`& .${classes.deliveryStatusWrapper}`]: {
            width: '260px'
        }
    };
});

const ShipmentsMapLegendWrapper = ({
    isWeatherRoadSurfaceActive
}: {
    /** Indicator for if the weather road surface layer is active. */
    isWeatherRoadSurfaceActive: boolean;
}): JSX.Element => {
    return (
        <StyledDiv className={classes.legendsWrapper} data-qa='mapLegends-container'>
            <Grid container spacing={1}>
                {
                    isWeatherRoadSurfaceActive &&
                    <Grid item data-qa='roadSurfaceLegend-container'>
                        <Paper className={`${classes.legendPaperWrapper} ${classes.roadSurfaceLegendWrapper}`}>
                            <RoadSurfaceLegend />
                        </Paper>
                    </Grid>
                }
            </Grid>
        </StyledDiv>
    );
};

export default ShipmentsMapLegendWrapper;
